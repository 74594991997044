/* eslint-disable import/default */
import 'raf/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import 'mdn-polyfills/Element.prototype.closest';

import configureStore, { history } from '~/store/configureStore';
import Root from '~/components/Root';
import '~/styles/styles.scss';
import '~/fonts/fonts.scss';
import '~/scripts/index.js';

const store = configureStore() || {};

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
