import _ from 'lodash';
import json2csv from 'json2csv';
import FileSaver from 'file-saver';
import { reportCsvMap } from '~/constants/ModelConstants';
import ReportResponseHelper from '~/utils/ReportResponseHelper';
import IssueHelper from '~/utils/IssueHelper';
import Helpers from '~/utils/Helpers';

const withScanData = data => data.map(d => ({ ...d, ...IssueHelper.getScanData(d) }));

const CsvHelper = function () {
  const startCSVDownloadForReport = (
    arrayOfReportRes,
    reportType,
    isPoliciesEnabled = false,
    isPolicyRiskEnabled
  ) => {
    const sortedRes = _.sortBy(arrayOfReportRes, [
      function (o) {
        return o.page.number;
      },
    ]);
    let embededObjsArrays = sortedRes.map(res =>
      ReportResponseHelper.getEmbeddedObject(res, reportType)
    );
    let data = withScanData(_.flatten(embededObjsArrays));

    switch (reportType) {
      case 'ISSUES':
        data = ReportResponseHelper.formatIssues(data, isPoliciesEnabled, isPolicyRiskEnabled);
        break;
      case 'VULNERABILITIES':
        data = ReportResponseHelper.formatSecurity(data);
        break;
      case 'REPOSITORIES':
        data = ReportResponseHelper.formatProjects(data);
        break;
      case 'LIBRARIES':
        data = ReportResponseHelper.formatLibraries(data, isPolicyRiskEnabled);
        break;
      case 'LICENSES':
        data = !isPolicyRiskEnabled ? data : ReportResponseHelper.formatLicenses(data);
        break;
      case 'WORKSPACES':
        data = ReportResponseHelper.formatWorkspaces(data);
        break;
      case 'UNMATCHED_LIBRARIES':
        data = ReportResponseHelper.formatWorkspaces(data);
        break;
    }

    // This is a temporary variable. This will remove when we remove the feature switch for license risk
    const reportCsvMapLicenseRiskFS = {
      ISSUES: {
        fileName: 'issues',
        fields: [
          { value: 'id', label: 'Issue ID' },
          { value: 'type', label: 'Issue type' },
          { value: 'suppressed', label: 'Ignored' },
          { value: 'status', label: 'Status' },
          { value: 'repoId', label: 'Project ID' },
          { value: 'libraryName', label: 'Library' },
          { value: 'libraryVersion', label: 'Version in use' },
          { value: 'libraryReleaseDate', label: 'Library release date' },
          { value: 'coordinateType', label: 'Package manager' },
          { value: 'coord1', label: 'Coordinate 1' },
          { value: 'coord2', label: 'Coordinate 2' },
          { value: 'latestVersion', label: 'Latest version' },
          { value: 'latestReleaseDate', label: 'Latest release date' },
          { value: 'repoName', label: 'Project' },
          { value: 'branch', label: 'Branch' },
          { value: 'tag', label: 'Tag' },
          { value: 'openedScanId', label: 'Issue opened: Scan ID' },
          { value: 'openedScanDate', label: 'Issue opened: Scan date' },
          { value: 'fixedScanId', label: 'Issue fixed: Scan ID' },
          { value: 'fixedScanDate', label: 'Issue fixed: Scan date' },
          { value: 'dependencyMode', label: 'Dependency (Transitive or Direct)' },
          { value: 'scanId', label: 'Scan' },
          { value: 'scanDate', label: 'Scan date' },
          { value: 'artifactId', label: 'Vulnerability ID' },
          { value: 'title', label: 'Title' },
          { value: 'cvss', label: 'CVSS score' },
          { value: 'severity', label: 'Severity' },
          { value: 'cve', label: 'CVE' },
          { value: 'cveStatus', label: 'Public or Veracode Customer Access' },
          { value: 'disclosureDate', label: 'Disclosure date' },
          { value: 'hasVulnMethods', label: 'Has vulnerable methods' },
          { value: 'vulnMethods', label: 'Number of vulnerable methods' },
          { value: 'updatedReleaseDate', label: 'Updated release date' },
          { value: 'releaseDate', label: 'Release date' },
          { value: 'updatedVersion', label: 'Updated Version' },
          { value: 'license', label: 'License' },
          { value: 'risk', label: 'License Risk' },
        ],
      },
      LIBRARIES: {
        fileName: 'library',
        fields: [
          { label: 'Library', value: 'name' },
          { label: 'Version', value: 'version' },
          { label: 'Latest version', value: 'latestVersion' },
          { label: 'Out of date', value: 'outOfDate' },
          { label: 'Language', value: 'languageType' },
          { label: 'Package Manager', value: 'coordinateType' },
          { label: 'Coordinate 1', value: 'coord1' },
          { label: 'Coordinate 2', value: 'coord2' },
          { label: 'Total vulnerabilities', value: 'totalArtifactCount' },
          { label: 'High risk vulnerability', value: 'highArtifactCount' },
          { label: 'Medium risk vulnerability', value: 'mediumArtifactCount' },
          { label: 'Low risk vulnerability', value: 'lowArtifactCount' },
          { label: 'Dependency (Transitive or Direct)', value: 'dependencyMode' },
          { label: 'Projects count', value: 'repos' },
          { label: 'Licenses', value: 'licenses' },
          { label: 'License Risk', value: 'risk' },
        ],
      },
      VULNERABILITIES: {
        fileName: 'security',
        fields: [
          { label: 'Severity', value: 'severity' },
          { label: 'CVSS Score', value: 'cvss' },
          { label: 'Vulnerability', value: 'title' },
          { label: 'Library', value: 'libraryName' },
          { label: 'Coordinate 1', value: 'coord1' },
          { label: 'Coordinate 2', value: 'coord2' },
          { label: 'Version', value: 'libraryVersion' },
          { label: 'Vulnerable Methods', value: 'vulnMethods' },
          { label: 'Projects', value: 'repos' },
          { label: 'Disclosure date', value: 'disclosureDate' },
          { label: 'CVE', value: 'cve' },
        ],
      },
      LICENSES: {
        fileName: 'license',
        fields: [
          { label: 'License', value: 'name' },
          { label: 'License Risk', value: 'risk' },
          { label: 'Libraries', value: 'libraries' },
          { label: 'Projects', value: 'repos' },
        ],
      },
      REPOSITORIES: {
        fileName: 'projects',
        fields: [
          { label: 'Project', value: 'name' },
          { label: 'Language/OS', value: 'language' },
          { label: 'Vulnerability Issues', value: 'vulnIssues' },
          { label: 'Outdated Library Issues', value: 'libraryIssues' },
          { label: 'License Issues', value: 'licenseIssues' },
          { label: 'Last scan', value: 'lastScanDate' },
        ],
      },
      WORKSPACES: {
        fileName: 'workspaces',
        fields: [
          { label: 'Workspace', value: 'name' },
          { label: 'Total Projects', value: 'repoCount' },
          { label: 'Vulnerability Issues', value: 'vulnIssues' },
          { label: 'Library Issues', value: 'libraryIssues' },
          { label: 'License Issues', value: 'licenseIssues' },
          { label: 'Users', value: 'numUsers' },
          { label: 'Teams', value: 'numGroups' },
          { label: 'Last Scan', value: 'lastScanDate' },
        ],
      },
      UNMATCHED_LIBRARIES: {
        fileName: 'unmatched_library',
        fields: [
          { label: 'Coordinate 1', value: 'coord1' },
          { label: 'Coordinate 2', value: 'coord2' },
          { label: 'Version', value: 'libraryVersion' },
          { label: 'File Name', value: 'filename' },
          { label: 'File Path', value: 'filePath' },
        ],
      },
    };

    const csvReportTypeData = !Helpers.hasPolicyRiskEnabled()
      ? reportCsvMap[reportType]
      : reportCsvMapLicenseRiskFS[reportType];
    const fileName = (csvReportTypeData && csvReportTypeData.fileName) || 'reports';
    const fields = (csvReportTypeData && csvReportTypeData.fields) || null;
    startCSVDownload(fileName, fields, data);
  };

  const startCSVDownload = (fileName, fields, data) => {
    const csvData = fields ? json2csv({ data, fields }) : json2csv({ data });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, `${fileName}.csv`);
  };

  return {
    startCSVDownloadForReport,
    startCSVDownload,
  };
};

export default new CsvHelper();
