import { useQuery } from 'react-query';
import config from '~/config';
import ApiService from '~/utils/ApiService';
import { veracodeApplicationsService } from '~/constants/ModelConstants';

export function useGetPolicyAppList(policyId: string) {
  return useQuery('policyAppList', () =>
    (ApiService as any).get(
      `${config.VERACODE_UIGATEWAY_HOST}${veracodeApplicationsService}?policy_guid=${policyId}`
    )
  );
}
