import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SOURCECLEAR_COMPONENTS, veracodeStartAScanPage } from '~/constants/ModelConstants';
import veracodePlatformLogo from '~/images/veracodePlatformLogo.png';
import config from '~/config';
import RegistryLink from '~/components/RegistryLink';
import VCNav from '~/views/containers/veracode/VCNav';
import { Button } from 'fluorine-ui';

interface VCHeaderProps {
  vcStartScanHeaderTitle: string;
  vcStartScanPageIsOpen: boolean;
  startScanButtonIsVisible: boolean;
  platformBaseUrl: string;
}

function VCHeader(props: VCHeaderProps): JSX.Element {
  const { vcStartScanHeaderTitle, vcStartScanPageIsOpen, startScanButtonIsVisible } = props;
  const startScanPageHeaderCls = classNames(
    'vcSectionTitle flex flex--justify-content--space-between',
    {
      vcStartScanPageHeader: vcStartScanPageIsOpen,
    }
  );

  const startScanPageUrl = `${veracodeStartAScanPage}`;

  return (
    <header className="vcHeader">
      <VCNav />

      <div className={startScanPageHeaderCls}>
        <div>
          <h1>{vcStartScanHeaderTitle}</h1>
        </div>
        <div>
          <img src={veracodePlatformLogo} alt="Veracode" />
        </div>
      </div>
      {!vcStartScanPageIsOpen && (
        <div className="vcActions flex flex--justify-content--end mb--">
          <div
            className="external-link flex flex--align-items--center"
            data-automation-id="VCHeader-VulnerabilityDatabaseLink"
          >
            <RegistryLink
              href={`${config.VC_REGISTRY_URL}/${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.slug}/search`}
            >
              {SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
              <i className="glyphicon glyphicon-new-window" />
            </RegistryLink>
          </div>

          {startScanButtonIsVisible && (
            <div className="btn-group">
              <Link to={startScanPageUrl}>
                <Button variant="primary">Start a Scan</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </header>
  );
}

export default VCHeader;
