import React from 'react';
import IssueSeverityFlag from '~/components/ReportComponents/IssueSeverityFlag';
import Helpers from '~/utils/Helpers';
import { PROJECT_TYPES } from '~/constants/ModelConstants';

interface ScanHistoryRowProps {
  id: number;
  date: string;
  commit?: string;
  branch?: string;
  tag?: string;
  type?: string;
  name?: string;
  issuesCount: number;
  onClick: (...args: any[]) => any;
  isSelected: boolean;
  maxSeverity: number;
}

const ScanHistoryRow: React.FunctionComponent<ScanHistoryRowProps> = props => {
  const {
    id,
    date,
    commit,
    branch,
    tag,
    issuesCount,
    isSelected,
    maxSeverity,
    type = '',
    name,
  } = props;

  const data = {
    id,
    scanDate: date,
    commit,
    branch,
    tag,
    issuesCount,
    maxSeverity,
    name,
    type,
  };

  function handleClick() {
    props.onClick(data);
  }

  const formattedCommitHash = Helpers.shortedCommitHash(commit);

  return (
    <div
      onClick={handleClick}
      className={`grid m0 position--relative bg-color--transition-200ms-ease-out cursor--pointer ${
        isSelected ? 'bg-color--white-light border-color--primary' : 'border-color--white-medium'
      } bg-color--hover-white-dark bo-l--5 border-color--hover-gray-dark width--400 pv position--relative`}
      data-automation-id="ScanHistoryRow"
    >
      <div className="grid__item col-4-5 color--black lh+">
        <div className="grid">
          <div className="grid__item col-1-4">Scan ID </div>
          <div className="grid__item col-3-4">{id}</div>
          <div className="grid__item col-1-4">Date </div>
          <div className="grid__item col-3-4">{date}</div>

          <div className="grid__item col-1-1">
            <div className="grid">
              {formattedCommitHash && (
                <div className="grid__item col-1-1">
                  <div className="grid">
                    <div className="grid__item col-1-4">Revision </div>
                    <div className="grid__item col-3-4">{formattedCommitHash}</div>
                  </div>
                </div>
              )}
              {tag && (
                <div className="grid__item col-1-1">
                  <div className="grid" data-automation-id="TagRow">
                    <div className="grid__item col-1-4">Tag </div>
                    <div className="grid__item col-3-4">
                      <i className="fas fa-tag font--11 top--3 mr---" />
                      {tag}
                    </div>
                  </div>
                </div>
              )}
              {branch && (
                <div className="grid__item col-1-1">
                  <div className="grid" data-automation-id="BranchRow">
                    <div className="grid__item col-1-4">Branch </div>
                    <div className="grid__item col-3-4">
                      <i className="fas fa-code-fork mr--" />
                      {branch}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {type.toUpperCase() === PROJECT_TYPES.CONTAINER && (
            <div className="grid__item col-1-1">
              <div className="grid">
                <div className="grid__item col-1-4">Image </div>
                <div className="grid__item col-3-4">{name}</div>
              </div>
            </div>
          )}

          <div className="grid__item col-1-4">Issues </div>
          <div className="grid__item col-3-4">{issuesCount}</div>
        </div>
      </div>
      <div className="grid__item col-1-5">
        {issuesCount === 0 && (
          <span>
            <i
              className={`text--center font--h4 sci pv-- color--success-dark sci__circle--check`}
            />
          </span>
        )}
        {issuesCount > 0 && (
          <IssueSeverityFlag severity={maxSeverity || 0} hideText={true} hideValue={true} />
        )}
      </div>
      <div className="ml position--absolute bottom--0 width--40 bo-b--2 border-color--muted-light" />
    </div>
  );
};

export default ScanHistoryRow;
