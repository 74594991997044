import React from 'react';
import Tooltip from '~/components/Tooltip';
import _ from 'lodash';

interface PDFDownloadButtonProps {
  hasError: boolean;
  isDownloading: boolean;
  teamId: string;
  repoName: string;
  repoCount: number;
  repoDefaultBranches: {
    repoId?: number;
    repoDefaultBranch?: string;
  };
  onPDFDownload: (...args: any[]) => any;
}
const PDFDownloadButton: React.FunctionComponent<PDFDownloadButtonProps> = props => {
  const {
    hasError = false,
    isDownloading = false,
    teamId = '',
    repoName = '',
    repoCount = 0,
    repoDefaultBranches = {},
    onPDFDownload = () => {},
  } = props;

  const downloadPDF = options => {
    onPDFDownload(options);
  };
  const defaultBranchisNotSet: boolean = _.isEmpty(repoDefaultBranches);

  if (hasError) {
    return (
      <Tooltip
        place="left"
        content={`Error downloading PDF. Please click to retry`}
        id={`export-pdf-error-${teamId}`}
      >
        {' '}
        <i
          className="sci sci__alerts color--danger cursor--pointer ph- font--16"
          onClick={() =>
            downloadPDF({
              teamId,
              repoName,
              repoCount,
              repoDefaultBranches,
            })
          }
        />
      </Tooltip>
    );
  }

  if (isDownloading) {
    return (
      <div>
        <i className="fa sci--sm fa-spin fa-spinner mh- color--primary font--16 ph0" />
      </div>
    );
  }

  if (defaultBranchisNotSet) {
    return (
      <Tooltip
        id={`export-pdf-${teamId}`}
        maxWidthClass="max-width--300"
        place="left"
        content="To generate a report, first set the default branch for each project in this workspace. To set the default branch, navigate to a project and click on Settings."
      >
        <i className={`sci sci__data--download ph- disabled color--muted font--16`} />
      </Tooltip>
    );
  }

  return (
    <Tooltip id={`export-pdf-${teamId}`} place="left" content="Export report to PDF">
      <i
        className={`sci sci__data--download cursor--pointer ph- color--muted color-hover--primary font--16`}
        onClick={() =>
          downloadPDF({
            teamId,
            repoName,
            repoCount,
            repoDefaultBranches,
          })
        }
      />
    </Tooltip>
  );
};

export default PDFDownloadButton;
