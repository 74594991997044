import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import ReportRowWrapper from '~/components/ReportComponents/ReportRowWrapper';
import IssueSeverityFlag from '~/components/ReportComponents/IssueSeverityFlag';
import SeverityFlag from '~/components/ReportComponents/SeverityFlag';
import ReportRepoBranchTagDisplay from '~/components/ReportComponents/ReportRepoBranchTagDisplay';
import LoaderWrapper from '~/components/LoaderWrapper';
import Helpers from '~/utils/Helpers';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import IssueHelper from '~/utils/IssueHelper';
import ArtifactHelper from '~/utils/ArtifactHelper';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import Tooltip from '~/components/Tooltip';
import ProIcon from '~/components/ProIcon';
import {
  issueTypeMap,
  ISSUE_STATUS,
  ISSUE_EVENT_SOURCE,
  FEATURE_SLUG_MAP,
  licenseRiskSeverityOptions,
} from '~/constants/ModelConstants';

import { IssueRowProps } from '~/containers/IssuesPage.types';
import LicenseRiskIcon, { Rating } from '~/components/Icons/LicenseRiskIcon';

//This feature check can be removed once the License Risk feature integration is complete.
const isLicenseRiskFeatureEnabled: boolean = Helpers.hasPolicyRiskEnabled();

const IssueRow: FunctionComponent<IssueRowProps> = props => {
  const {
    issue,
    columnWidths,
    isSelected,
    teamId,
    isProjectDetailsPage,
    isContainerProject,
    isPaidOrTrialing,
    org,
    activeIssueType,
    showActionsButtonSelectColumn = true,
  } = props;
  const {
    id,
    type,
    issueSeverity,
    severity,
    cve,
    title,
    cveStatus,
    libraryName,
    repoName,
    scanId,
    repoId,
    status,
    vulnMethods,
    branch,
    tag,
    dependencyMode,
    libraryVersion,
    updatedVersion,
    license,
    licenseCount,
    risk = 'UNRECOGNIZED',
    createdEvent,
    resolvedEvent,
  } = issue;

  let riskFormatted;
  if (risk) {
    riskFormatted = licenseRiskSeverityOptions.find(s => s.value === risk).value;
  } else if (risk === null && licenseCount === 0) {
    riskFormatted = 'UNRECOGNIZED';
  }

  const issueTypeMapContent = {
    VULN: {
      className: 'sci sci__shield--cross',
      text: 'Vulnerability Issue',
    },
    OUT_OF_DATE: {
      className: 'fas fa-book',
      text: 'Library Issue',
    },
    LICENSE: {
      className: 'fas fa-certificate',
      text: 'License Issue',
    },
  };

  let cveText = cve;

  const showPremiumVulnPaywall =
    ArtifactHelper.isArtifactPremiumByStatus(cveStatus) &&
    !isPaidOrTrialing &&
    type === issueTypeMap.VULNERABILITY;

  const isPoliciesEnabled =
    FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.POLICIES, org) ||
    OrgPaidStatus.isOrgEnterpriseOrTrial(org);
  const isShowingForVulnType = activeIssueType === issueTypeMap.VULNERABILITY;
  const isShowingForOutOfDateType = activeIssueType === issueTypeMap.OUTDATED_LIBRARY;
  const isShowingForLicenseType = activeIssueType === issueTypeMap.LICENSE;
  const isGpl = Helpers.isGplString(license);
  const maybeResolved = status === ISSUE_STATUS.FIXED ? resolvedEvent : null;
  const event = status === ISSUE_STATUS.OPEN ? createdEvent : maybeResolved;
  const isPassivelyCreated = event && event.source === ISSUE_EVENT_SOURCE.SOURCECLEAR;
  const passiveEventMessage = isPassivelyCreated ? IssueHelper.buildIssueEventMessage(event) : null;

  if (cveText && cveText.indexOf('CVE') === -1) {
    cveText = `CVE-${cveText}`;
  }

  function handleToggleCtaPremiumVulnModalOpen(location) {
    props.onToggleCtaPremiumVulnModalOpen(location);
  }

  function handleChange() {
    props.onRowSelectChange(issue);
  }

  function createIssueDetailsLink() {
    const typeUrlMap = {
      VULN: 'vulnerabilities',
      OUT_OF_DATE: 'libraries',
      LICENSE: 'licenses',
    };
    return `/workspaces/${teamId}/issues/${typeUrlMap[type]}/${id}`;
  }

  const formattedStatus = status === ISSUE_STATUS.FIXED ? 'Resolved' : Helpers.capFirst(status);
  const repoNameText = Helpers.formatProjectName(repoName);

  /* eslint-disable react/no-danger */
  const projNameTooltipContent = Helpers.isProjectNameCut(repoNameText) ? (
    <div dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoName)} />
  ) : (
    ''
  );

  const repoNameHtml = <p dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoNameText)} />;
  /* eslint-disable react/no-danger */

  const directOrTransitiveTooltipMap = {
    TRANSITIVE: 'Transitive dependency',
    DIRECT: 'Direct dependency',
    BOTH: 'Direct and transitive dependencies',
  };

  const noCveText = type === 'VULN' ? 'No CVE' : 'n/a';
  const getLicenseText = () => {
    if (isLicenseRiskFeatureEnabled && licenseCount === 1) {
      return license;
    }

    //this condition wil be removed after license-Risk feature integration
    if (!isLicenseRiskFeatureEnabled && isGpl) {
      return 'Library uses GPL licenses';
    }

    if (licenseCount > 1) {
      return 'Library uses multiple licenses';
    }

    if (licenseCount === 0) {
      return isLicenseRiskFeatureEnabled ? 'Unrecognized' : 'Library has no license';
    }

    return '';
  };

  const renderSeverity = () => {
    if (isPoliciesEnabled) {
      return <IssueSeverityFlag severity={issueSeverity || 0} hideText={true} />;
    } else if (severity) {
      return (
        <Tooltip id={`${id}-${type}-${title}`} content={Helpers.capFirst(severity)}>
          <SeverityFlag severity={severity} hideText={true} />
        </Tooltip>
      );
    } else {
      return 'n/a';
    }
  };

  const renderTitle = () => {
    if (isShowingForVulnType) {
      return (
        //Show this for VULN type issues
        <span>
          {title}
          {!!vulnMethods && isPaidOrTrialing && (
            <span className="pl-- inline-block">
              <Tooltip
                place="top"
                width="width-200"
                content="Call graph analysis shows a vulnerable method may be invoked"
                id={`${id}-${scanId}-vulnMethodCol`}
              >
                <i className="sci sci__shield--urgent color--danger" />
              </Tooltip>
            </span>
          )}
        </span>
      );
    } else if (isShowingForOutOfDateType) {
      return (
        //Show this for OUT_OF_DATE issues
        <span>
          Version in use: {libraryVersion}, Latest at scan: {updatedVersion}
        </span>
      );
    } else if (isShowingForLicenseType) {
      return (
        //Show this for LICENSE type issues
        <span>{getLicenseText()}</span>
      );
    } else {
      return '';
    }
  };

  return (
    <ReportRowWrapper>
      <div className={`grid__item ${columnWidths.id}`} data-automation-id="RowCell">
        {showPremiumVulnPaywall ? (
          <span
            onClick={() => handleToggleCtaPremiumVulnModalOpen('ISSUE_LIST_ISSUE_ID_COLUMN')}
            className="link--obvious"
          >
            <strong>{id}</strong>
          </span>
        ) : (
          <Link to={createIssueDetailsLink()} className="link--obvious">
            <strong>{id}</strong>
          </Link>
        )}
      </div>
      <div
        className={`grid__item color--muted-dark ${columnWidths.severity} flex flex--justify-content--center pr--`}
        data-automation-id="RowCell"
      >
        <LoaderWrapper loaderType="SPINNER" size="font--14">
          {renderSeverity()}
        </LoaderWrapper>
      </div>
      {!isLicenseRiskFeatureEnabled && (
        <div
          className={`grid__item color--muted-dark ${columnWidths.type} text--center inline-block`}
          data-automation-id="RowCell"
        >
          <Tooltip
            id={`${id}-${issueTypeMapContent[type].text}`}
            content={issueTypeMapContent[type].text}
          >
            <i
              className={`${issueTypeMapContent[type].className} color--muted font--20 color--muted-dark`}
              title={formattedStatus}
            />
          </Tooltip>
        </div>
      )}
      {isShowingForVulnType && (
        <div
          className={`grid__item color--muted-dark ${columnWidths.cve} break-word`}
          data-automation-id="RowCell"
        >
          {cveText || noCveText}
        </div>
      )}
      <div
        className={`grid__item color--muted-dark ${columnWidths.title} break-word`}
        data-automation-id="RowCell"
      >
        {renderTitle()}
      </div>
      {isShowingForLicenseType && isLicenseRiskFeatureEnabled && (
        <div
          className={`grid__item color--muted-dark ${columnWidths.licenseRisk} break-word`}
          data-automation-id="RowCell"
        >
          {riskFormatted && <LicenseRiskIcon rating={Rating[riskFormatted]} id={id} />}
        </div>
      )}
      {!isProjectDetailsPage && (
        <div className={`grid__item ${columnWidths.repoName}`} data-automation-id="RowCell">
          {(repoName && (
            <Tooltip
              content={
                <div>
                  <div className="mb--">{projNameTooltipContent}</div>
                  <ReportRepoBranchTagDisplay branch={branch} tag={tag} />
                </div>
              }
              id={`${id}-${scanId}-tooltip`}
            >
              <Link
                to={`/workspaces/${teamId}/projects/${repoId}/issues`}
                className="link--obvious"
              >
                {repoNameHtml}
              </Link>
            </Tooltip>
          )) ||
            '--'}
        </div>
      )}
      {showPremiumVulnPaywall ? (
        <div
          className={`grid__item color--muted-dark ${columnWidths.libraryName} overflow--hidden break-word`}
          data-automation-id="RowCell"
        >
          <div
            className="cursor--pointer"
            onClick={() => handleToggleCtaPremiumVulnModalOpen('ISSUE_LIST_LIBRARY_COLUMN')}
          >
            <ProIcon />
          </div>
        </div>
      ) : (
        <div
          className={`grid__item color--muted-dark ${columnWidths.libraryName} overflow--hidden break-word`}
          data-automation-id="RowCell"
        >
          <div
            className="inline-block position--relative"
            style={{ height: '12px', width: '12px', top: '2px' }}
          >
            <Tooltip
              id={`${id}-${libraryName}`}
              content={directOrTransitiveTooltipMap[dependencyMode]}
            >
              {dependencyMode === 'TRANSITIVE' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  className="fill--muted-light"
                >
                  <path d="M32 1H1l16 31L1 63h31l16-31" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  className="fill--muted-light"
                >
                  <path d="M32 1H1v62h31l16-31" />
                </svg>
              )}
            </Tooltip>
          </div>
          <span className="pl---">{libraryName}</span>
        </div>
      )}
      {isProjectDetailsPage && !isContainerProject && (
        <div className={`grid__item ${columnWidths.branchOrTag}`} data-automation-id="RowCell">
          <ReportRepoBranchTagDisplay
            rowId={id}
            branch={branch}
            tag={tag}
            className="inline-block"
            iconClassName="color--muted-light"
            contentClassName="color--muted-dark"
          />
        </div>
      )}
      {isPaidOrTrialing && (
        <div
          className={`grid__item flex flex--justify-content--center text--center color--muted-dark position--relative ${columnWidths.status}`}
          data-automation-id="RowCell"
        >
          <div className="inline-block">
            <Tooltip id={`${id}-${status}`} content={formattedStatus}>
              <i
                className={`fas fa-circle ${
                  status === ISSUE_STATUS.FIXED ? 'color--success' : 'color--danger'
                }`}
                title={formattedStatus}
              />
            </Tooltip>
          </div>
          {isPassivelyCreated && (
            <div className="position--absolute right--0">
              <Tooltip
                place="top"
                id={`issue-${id}`}
                content={passiveEventMessage}
                className="width--300 break-word"
              >
                <i className="sci__alerts sci color--warning font--16 pl---" />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {showActionsButtonSelectColumn && (
        <div className={`grid__item ${columnWidths.select}`} data-automation-id="RowCell">
          <div className="flex flex--align-items--center flex--justify-content--center">
            <label className="label--checkbox flex mb0">
              <div className="flex">
                <input
                  type="checkbox"
                  name={`issue-vuln-${id}`}
                  id={id}
                  onChange={() => handleChange()}
                  checked={isSelected}
                />
                <span className={`react--checkbox mr0`} />
              </div>
            </label>
          </div>
        </div>
      )}
    </ReportRowWrapper>
  );
};

export default IssueRow;
