import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import _ from 'lodash';

import ReportDetailsPageTabItem from '~/components/ReportComponents/ReportDetailsPageTabItem';
import ReportDetailsPageTabWrapper from '~/containers/ReportDetailsPageTabWrapper';
import ReportDetailsPageTabContent from '~/components/ReportComponents/ReportDetailsPageTabContent';
import ReportDetailsPageUpdateAdvisorAlert from '~/components/ReportComponents/ReportDetailsPageUpdateAdvisorAlert';
import ReportDetailsPageUpdateAdvisorWrapper from '~/containers/ReportDetailsPageUpdateAdvisorWrapper';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import SourceClearLoader from '~/components/SourceClearLoader';
import IssueProjectDetails from '~/components/ReportComponents/IssueProjectDetails';
import IssueDetailCreateIssueBtn from '~/containers/IssueDetailCreateIssueBtn';
import CommentIssueModal from '~/containers/CommentIssueModal';
import ProIcon from '~/components/ProIcon';

import Helpers from '~/utils/Helpers';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import JiraHelper from '~/utils/JiraHelper';
import IssueHelper from '~/utils/IssueHelper';
import Spinner from '~/components/Spinner';
import RegistryLink from '~/components/RegistryLink';
import IssueSeverityFlag from '~/components/ReportComponents/IssueSeverityFlag';
import IssueDetailComment from '~/components/IssueDetailComment';
import SidebarWrapper from '~/containers/SidebarWrapper';
import IssueHistory from '~/containers/IssueHistory';

import * as reportDetailsPageActions from '~/actions/reportDetailsPage';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as upgradeModalActions from '~/actions/upgradeModal';
import * as teamActions from '~/actions/team';
import * as modalActions from '~/actions/modal';
import * as sidebarActions from '~/actions/sidebar';

import * as MODEL from '~/constants/ModelConstants';

import {
  DetailLinks,
  DetailLink,
  IssuesDetailsPageProps,
} from '~/containers/IssuesDetailsPage.types';
import { RootState } from '~/reducers';

const REPORT_TYPE = 'ISSUE_LIBRARIES';
const SIDEBAR_ID = 'ISSUE_HISTORY';

class IssuesLibrariesDetailsPage extends Component<
  IssuesDetailsPageProps & ReturnType<typeof mapDispatchToProps>
> {
  constructor(props, context) {
    super(props, context);

    (this as any).ignoreIssueCheckboxRef = React.createRef();
    this.handleIgnoreIssue = this.handleIgnoreIssue.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { issueId, teamId } = params;

    this.props.teamActions.fetchIssueData(teamId);
    this.props.reportDetailsPageActions.resetReport(REPORT_TYPE);
    const {
      lastScan: { id: scanId },
    } = await this.props.reportDetailsPageActions.fetchDetailsPage(REPORT_TYPE, issueId);

    this.props.reportIssuesActions.getIntegrationIssuesCreated(issueId, scanId);
  }

  fetchIssueEventsAndDetails = () => {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { issueId } = params;
    this.props.reportDetailsPageActions.fetchIssuesEvents(REPORT_TYPE, issueId);
    this.props.reportDetailsPageActions.fetchIssuesDetails(REPORT_TYPE, issueId);
  };

  updateAdvisorComponentDidMount = link => {
    if (link) {
      this.props.reportDetailsPageActions.fetchUpdateAdvisorData(REPORT_TYPE, link);
    }
  };

  getLinks = (_links = {} as DetailLinks) => {
    const { graph = {} as DetailLink, updates = {} as DetailLink } = _links;
    return {
      graphLink: graph.href,
      updatesLink: updates.href,
    };
  };

  handleTabClick = field => {
    this.props.reportDetailsPageActions.updateDetailsPageActiveTab(REPORT_TYPE, field);
  };

  getCoordAndVersionString = component => {
    return `${component.coord1}${component.coord2 ? ` ${component.coord2}` : ``} ${
      component.libraryVersion
    }`;
  };

  onPathSelected = path => {
    this.props.reportDetailsPageActions.updateActiveTabStateByReportField(
      REPORT_TYPE,
      'dependenciesSunburstSelectedPath',
      path
    );
  };

  toggleCtaCreateIssueModalOpen = () => {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_CREATE_ISSUE, teamId);
    this.props.reportIssuesActions.createIssueButtonClickedSnowplow(teamId, MODEL.NON_PAID_TEAM);
  };

  toggleCtaVulnModalOpen = () => {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_VULN_METHODS, teamId);
  };

  toggleSidebar = () => {
    const { sidebarState = {} } = this.props;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];

    if (isSidebarOpen) {
      this.props.sidebarActions.closeSidebar(SIDEBAR_ID);
    } else {
      this.props.sidebarActions.openSidebar(SIDEBAR_ID);
    }
  };

  getCurrentIssue = () => {
    const { reportDetailsPageState } = this.props;
    const { [REPORT_TYPE]: detailsState } = reportDetailsPageState;
    const { details } = detailsState;
    const { content = {} } = details;
    const { issue } = content;

    return issue;
  };

  ignoreIssue = (comment?: string) => {
    const issue = this.getCurrentIssue();

    const suppress = (this as any).ignoreIssueCheckboxRef.checked;
    this.props.reportIssuesActions
      .maybeSuppressIssue(issue, REPORT_TYPE, suppress, comment)
      .then(() => {
        this.fetchIssueEventsAndDetails();
        this.props.modalActions.closeModal(MODEL.IGNORE_COMMENT_MODAL);
      });
  };

  isIssueCommentEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam?.permissions?.issueComments || false;
  };

  isIgnoreIssueChangesEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam?.permissions?.ignoreIssueChanges || false;
  };

  commentIssueOnConfirm = (comment: string) => {
    const { modalState = {} } = this.props;
    const { openedModal = {} } = modalState;

    if (openedModal[MODEL.IGNORE_COMMENT_MODAL]) {
      this.ignoreIssue(comment);
    } else if (openedModal[MODEL.COMMENT_ONLY_MODAL]) {
      this.commentIssue(comment);
    }
  };

  handleIgnoreIssue = () => {
    this.isIssueCommentEnabled()
      ? this.props.modalActions.openModal(MODEL.IGNORE_COMMENT_MODAL)
      : this.ignoreIssue();
  };

  commentIssue = (comment: string) => {
    this.props.modalActions.closeModal(MODEL.COMMENT_ONLY_MODAL);
    const issue = this.getCurrentIssue();
    this.props.reportIssuesActions.commentIssue(issue, comment).then(() => {
      this.fetchIssueEventsAndDetails();
    });
  };

  commentIssueOnCancel = () => {
    const issue = this.getCurrentIssue();
    const { suppressed } = issue;

    (this as any).ignoreIssueCheckboxRef.checked = suppressed;
  };

  render() {
    const {
      reportDetailsPageState,
      match,
      orgState,
      reportIssuesState,
      teamState,
      sidebarState,
    } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { org } = orgState;
    const { [REPORT_TYPE]: detailsState = {} } = reportDetailsPageState;
    const { details = {}, tabSelected, isFetching, updateAdvisorData } = detailsState;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];
    const { content = {}, _links: vulDetailLink = {} } = details;
    const { issue = {}, library = {}, vulns = {} } = content;
    const { updatesLink = '' } = this.getLinks(vulDetailLink);
    const { description = '' } = library;
    const { currentLibraryVersionHasVulns, updatedLibraryVersionHasVulns } = vulns;

    const {
      id,
      hasComments,
      projectType = '',
      libraryName,
      coord1,
      coord2,
      coordinateType,
      dependencyMode,
      libraryVersion,
      libraryReleaseDate,
      updatedVersion,
      updatedReleaseDate,
      _links,
      issueSeverity = 0,
      suppressed,
      status,
    } = issue;
    const {
      isSuppressingIssue,
      isSuppressingIssueSuccess,
      isSuppressingIssueFailure,
      integrations,
    } = reportIssuesState;

    const scanData = IssueHelper.getScanData(issue);
    const { scanId, fixedScanId, fixedScanDate } = scanData;

    const formattedLibraryReleaseDate = Helpers.formatDate(libraryReleaseDate);
    const formattedUpdatedReleaseDate = Helpers.formatDate(updatedReleaseDate);

    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    const thirdPartyIssue = Helpers.hasThirdPartyIssuePermission({
      teams,
      teamId,
    });
    const isPoliciesEnabled =
      FeatureFlagHelper.isFeatureEnabledForOrgAndTeam(
        MODEL.FEATURE_SLUG_MAP.POLICIES,
        org,
        activeTeam
      ) || OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    const isContainerProject = projectType.toUpperCase() === MODEL.PROJECT_TYPES.CONTAINER;

    const sidebarTitleHtml = (
      <div className="flex align-items--center font--h6 text--bold">
        <i className="fas fa-clock mr- font--h4 color--primary" />
        Issue Event History
      </div>
    );

    // Issue severity
    const issueSeverityVal = issueSeverity || 0; //handling null case

    // Linked issue related
    const { [issue.id]: issueIntegrations = {} } = integrations;
    const { data: linkedTickets = [] } = issueIntegrations;
    const linkedJiraTicket = linkedTickets.filter(ticket => ticket.issueType === 'JIRA');
    const jiraHref =
      linkedJiraTicket.length === 0
        ? null
        : JiraHelper.getJiraLink(
            linkedJiraTicket[0].integrationServiceUrl,
            linkedJiraTicket[0].jiraKey
          );

    const registryLibHref = (_links && _links.library && _links.library.href) || '';

    const titleText = libraryName ? libraryName : 'Library name not available';
    const dependencyModeText =
      dependencyMode && dependencyMode.toUpperCase() === 'BOTH'
        ? 'Direct & Transitive'
        : dependencyMode;

    let currentVersionHasVulnsText = '--';
    let updatedVersionHasVulnsText = '--';
    //Checks if of boolean type to prevent false-negatives when detecting other falsey
    if (typeof currentLibraryVersionHasVulns === 'boolean') {
      currentVersionHasVulnsText = currentLibraryVersionHasVulns ? 'Yes' : 'No';
    }
    if (typeof updatedLibraryVersionHasVulns === 'boolean') {
      updatedVersionHasVulnsText = updatedLibraryVersionHasVulns ? 'Yes' : 'No';
    }

    let coord1Text = coord1;
    let coordContent = '';

    if (coordinateType === 'GEM') {
      coord1Text = coord1Text && `gem '${coord1Text}'`;
    }

    if (coord1Text) {
      coordContent = `${coord1Text}${coord2 ? ' ' + coord2 : ''}`.trim();
    }

    /* eslint-disable react/no-danger */
    const titleHighlightHtml = (
      <span dangerouslySetInnerHTML={Helpers.highlightItems(titleText, [], 100)} />
    );

    const versionInUseHtml = (
      <RegistryLink
        className="link--obvious"
        href={`${registryLibHref.split('=')[0]}=${libraryVersion}`}
        dangerouslySetInnerHTML={Helpers.formatBreakOnDot(libraryVersion)}
      />
    );

    const updatedVersionHtml = (
      <RegistryLink
        className="link--obvious"
        href={`${registryLibHref.split('=')[0]}=${updatedVersion}`}
        dangerouslySetInnerHTML={Helpers.formatBreakOnDot(updatedVersion)}
      />
    );
    /* eslint-disable react/no-danger */

    if (isFetching) {
      return (
        <div>
          <SourceClearLoader />
        </div>
      );
    }

    //Check Team paid/free status
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);

    const formattedStatus = status === 'FIXED' ? 'Resolved' : Helpers.capFirst(status);
    const isFixed = status === 'FIXED' && fixedScanId && fixedScanDate;

    return (
      <div className="col-1-1 mt-">
        <Helmet>
          <title>Outdated Library Details</title>
        </Helmet>
        <div className="">
          {/*Library detail section*/}
          <div className="grid mb-">
            <div className="grid__item pl">
              <div className="flex flex--align-items--center" data-automation-id="IssueLibrariesDetailsPage-Title">
                <span className="font--h3"> Issue </span>
                <span className="font--h7 color--black-dark pl- pt-">Outdated Library</span>
              </div>
            </div>
            <div className="flex grid__item flex--justify-content--end align-item--center col-2-5 pt--">
              {this.isIssueCommentEnabled() && (
                <div 
                  className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center mr"
                  data-automation-id="IssueLibrariesDetailsPage-IssueComment" 
                  >
                  <i className="sci sci__chat" />{' '}
                  <span
                    className="pl--"
                    onClick={() => this.props.modalActions.openModal(MODEL.COMMENT_ONLY_MODAL)}
                  >
                    {' '}
                    Comment{' '}
                  </span>
                  <IssueDetailComment
                    toggleSidebar={() => this.toggleSidebar()}
                    hasComments={hasComments}
                    id={`${id}-${scanId}`}
                  />
                </div>
              )}

              <div
                className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center"
                onClick={this.toggleSidebar}
              >
                {isPaidOrTrialing ? <i className="fas fa-clock" /> : <ProIcon />}{' '}
                <span className="pl--"> Show History </span>
              </div>
            </div>
          </div>
          <div className="grid grid--narrower bo--b-1 bg-color--black-light mb+ color--white">
            <div className="grid__item col-3-4 p">
              <h4>{titleHighlightHtml}</h4>
              <p className="bg-color--white-light mb-- ph-- pv--- bo-rad--2 text--bold font--11 inline-block color--black">
                {coordContent}
              </p>
              <p>{description}</p>
            </div>
            <div className="grid__item col-1-4 p">
              <div className="grid">
                <div className="grid__item col-1-1">
                  <div className="pb-">
                    {isPoliciesEnabled && <IssueSeverityFlag severity={issueSeverityVal} />}
                  </div>
                  <div>
                    <span className="text--bold">Issue ID:</span> {id}
                  </div>
                  {isPaidOrTrialing && (
                    <div className="pt--">
                      <span className="text--bold">Linked Issue:&nbsp;</span>
                      {jiraHref ? (
                        <a href={jiraHref} className="link--obvious" target="_blank">
                          {linkedJiraTicket[0].jiraKey}
                        </a>
                      ) : (
                        <span>(None) </span>
                      )}
                    </div>
                  )}
                  {isPaidOrTrialing && (
                    <div className="pt--">
                      <span className="text--bold">Status: </span>
                      {formattedStatus}
                    </div>
                  )}
                  {this.isIgnoreIssueChangesEnabled() && (
                    <div className="pt--" data-automation-id="IssueLibrariesDetailsPage-ignoreIssue">
                      <span className="text--bold">Ignore Issue: &nbsp;</span>
                      <label className="position--relative">
                        <input
                          ref={input => ((this as any).ignoreIssueCheckboxRef = input)}
                          type="checkbox"
                          name={`ignore-issue`}
                          value={`ignore`}
                          defaultChecked={suppressed}
                          onChange={this.handleIgnoreIssue}
                          disabled={isSuppressingIssue}
                        />
                        <span className="control--checkbox" />
                      </label>
                      <div>
                        {isSuppressingIssue && (
                          <span>
                            <Spinner
                              size={12}
                              strokeWidth={4}
                              backgroundColor="bg-color--black-light"
                              polygonClassName="fill--black-light stroke--white"
                            />{' '}
                            Loading...
                          </span>
                        )}
                        {isSuppressingIssueSuccess && <span> Updated Successfully</span>}
                        {isSuppressingIssueFailure && <span> {isSuppressingIssueFailure}</span>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*End of Vulnerability detail and data source details section*/}

          {/*Repo and Library details section*/}

          <div className="grid grid--narrower pb+">
            <div className="grid__item col-1-2 pl0 pr-">
              <IssueProjectDetails
                reportType={REPORT_TYPE}
                teamId={teamId}
                issue={issue}
                isPaidOrTrialing={isPaidOrTrialing}
                isContainerProject={isContainerProject}
              />
            </div>

            <div className="grid__item col-1-2 pl-">
              <div className="grid">
                <div className="grid__item col-1-1">
                  <div className="font--18 bo--b-1 border-color--muted-light mb-">
                    LIBRARY DETAILS
                  </div>
                </div>
                <div className="grid__item col-1-2">
                  <div>
                    <span className="text--bold">Version In Use:</span> {versionInUseHtml}
                  </div>
                  <div className="pt---">
                    <span className="text--bold">Released On:</span> {formattedLibraryReleaseDate}
                  </div>
                  <div className="pt---">
                    <span className="text--bold">Version Has Vulnerabilities:</span>{' '}
                    {currentVersionHasVulnsText}
                  </div>
                  <div className="pt---">
                    <span className="text--bold">Type:</span> {Helpers.capFirst(dependencyModeText)}{' '}
                    dependency
                  </div>
                </div>

                <div className="grid__item col-1-2">
                  <div className="pl--">
                    <div>
                      <span className="text--bold">Latest at Scan:</span> {updatedVersionHtml}
                    </div>
                    <div className="pt---">
                      <span className="text--bold">Released On:</span> {formattedUpdatedReleaseDate}
                    </div>
                    <div className="pt---">
                      <span className="text--bold">Latest Has Vulnerabilities:</span>{' '}
                      {updatedVersionHasVulnsText}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*End of Repo and Library details section*/}

          {/*The Fix, Vuln Methods and Dependency Graph section*/}
          {!isFixed && (
            <div className="pb- bo--b-1 border-color--white-dark mb">
              <ReportDetailsPageTabWrapper defaultTab={'theFix'} reportType={REPORT_TYPE}>
                <ReportDetailsPageTabItem
                  label="The Fix"
                  field={`theFix`}
                  isActive={tabSelected === 'theFix'}
                  onClick={this.handleTabClick}
                />
                <ReportDetailsPageTabContent>
                  {tabSelected === 'theFix' && (
                    <div className="grid grid--narrower">
                      <div className="grid__item col-2-3 flex bo--1 border-color--white-dark p bg-color--white-light">
                        <p className="font--16">
                          <RegistryLink href={registryLibHref} className="link--obvious text--bold">
                            Visit the {MODEL.SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
                          </RegistryLink>{' '}
                          to learn about updating this library.
                        </p>
                      </div>
                      {thirdPartyIssue && (
                        <div className="grid__item col-1-3 text--left">
                          <IssueDetailCreateIssueBtn
                            isPaidOrTrialing={isPaidOrTrialing}
                            issue={issue}
                            reportType={REPORT_TYPE}
                            teamId={teamId}
                            linkedIssueCreated={!_.isEmpty(jiraHref)}
                          />
                        </div>
                      )}
                      <div className="grid__item col-1-3 pr+ pl">
                        <div className="grid">
                          <div className="grid__item col-3-5 pt">
                            <ReportDetailsPageUpdateAdvisorWrapper
                              link={updatesLink}
                              onComponentDidMount={this.updateAdvisorComponentDidMount}
                            >
                              <ReportDetailsPageUpdateAdvisorAlert data={updateAdvisorData} />
                            </ReportDetailsPageUpdateAdvisorWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ReportDetailsPageTabContent>
              </ReportDetailsPageTabWrapper>
            </div>
          )}

          {/*End of Vuln Methods and Dependency Graph section*/}
        </div>

        <SidebarWrapper
          id={SIDEBAR_ID}
          title={sidebarTitleHtml}
          pullRight={true}
          sidebarClassName={`bg-color--white-medium width--400`}
          shadow={true}
        >
          {isSidebarOpen && <IssueHistory reportType={REPORT_TYPE} />}
        </SidebarWrapper>
        {this.isIssueCommentEnabled() && (
          <CommentIssueModal
            onConfirm={(comment: string) => this.commentIssueOnConfirm(comment)}
            onCancel={() => this.commentIssueOnCancel()}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    modalState: state.modalState,
    reportDetailsPageState: state.reportDetailsPageState,
    reportIssuesState: state.reportIssuesState,
    teamState: state.teamState,
    orgState: state.orgState,
    sidebarState: state.sidebarState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    modalActions: bindActionCreators(modalActions as any, dispatch),
    reportDetailsPageActions: bindActionCreators(reportDetailsPageActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
    sidebarActions: bindActionCreators(sidebarActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IssuesLibrariesDetailsPage));
