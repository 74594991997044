import React, { Component, Fragment } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu';
import Helmet from 'react-helmet';

import * as MODEL from '~/constants/ModelConstants';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import Helpers from '~/utils/Helpers';

import ZeroIssuesPanel from '~/components/ZeroIssuesPanel';
import ReportHeader from '~/components/ReportComponents/ReportHeader';
import IssueRow from '~/components/IssueRow';
import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import ReportOptionsFilter from '~/components/ReportComponents/ReportOptionsFilter';
import ReportBooleanFilter from '~/components/ReportComponents/ReportBooleanFilter';
import CSVReportDownloader from '~/containers/CSVReportDownloader';
import ProIcon from '~/components/ProIcon';
import InsightsWrapper from '~/components/InsightsWrapper';
import InsightNumber from '~/components/InsightNumber';
import Tooltip from '~/components/Tooltip';
import IgnoreIssueModal from '~/containers/IgnoreIssueModal';
import CreateIssueModal from '~/containers/CreateIssueModal';
import LicenseRiskOptionsFilter from '~/containers/LicenseRiskOptionsFilter';
import LoaderWrapper from '~/components/LoaderWrapper';

import * as insightsActions from '~/actions/insights';
import * as navigationActions from '~/actions/navigation';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as reportFilterActions from '~/actions/reportFilters';
import * as reportActions from '~/actions/reports';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import * as upgradeModalActions from '~/actions/upgradeModal';
import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as modalActions from '~/actions/modal';
import { IssuesPageProps, IssuesPageMatchParams } from '~/containers/IssuesPage.types';
import { RootState } from '~/reducers';
import IssuesInsightsBar from '~/components/IssuesInsightsBar';

// TODO: Remove this feature check once the License Risk feature integration is complete.
const isLicenseRiskFeatureEnabled: boolean = Helpers.hasPolicyRiskEnabled();

const { JIRA_CLOUD, JIRA_ONPREM, JIRA_LEGACY, GITHUB } = MODEL.INTEGRATION_NAME_MAP;

const REPORT_TYPE = 'ISSUES';
const SUPPRESSED = 'SUPPRESSED';

class IssuesPage extends Component<IssuesPageProps & ReturnType<typeof mapDispatchToProps>> {
  componentDidMount() {
    const { match, teamState, history } = this.props;

    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId, projectId } = params;
    const { teams } = teamState;
    const activeTeam = teams.find(team => team.id === teamId);
    const { permissions, repoCount } = activeTeam;
    const { reports } = permissions;

    if (!reports) {
      history.replace(`/workspaces/${teamId}/agents${repoCount ? '' : '/new'}`);
    }

    this.refreshReportData();

    if (projectId) {
      this.props.reportFilterActions.initProjectFilterDefaults(projectId);
      this.props.sortByReportTypeActions.initProjectSortDefaults(projectId);
    } else {
      this.props.insightsActions.fetchIssueInsights(teamId);
    }

    this.props.navigationActions.updateActiveReportType(REPORT_TYPE);

    // TODO: Handle scroll-restoration across app https://reacttraining.com/react-router/web/guides/scroll-restoration
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { teamId: prevTeamId },
      },
    } = prevProps;

    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId } = params;

    // if switching workspaces from one issues page to the other (via workspace switcher), this code is necessary
    if (teamId && prevTeamId && teamId !== prevTeamId) {
      this.refreshReportData(teamId);
      this.props.insightsActions.fetchIssueInsights(teamId);
    }
  }

  componentWillUnmount() {
    this.props.reportSelectedRowsActions.clearSelectedRows();
    this.props.reportActions.toggleActiveOrSuppressed('ACTIVE');
    this.closeDropdownMenu();

    this.props.navigationActions.updateActiveReportType();
  }

  delayedRefreshReportData = _.debounce(this.refreshReportData, 300);

  delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);

  refreshReportData(nextTeamId?: string) {
    // default teamId is whatever is currently in props.
    // nextTeamId is optional, sent by CWRP 👆👆  when transitioning between teams
    const { match, fetchAllReports } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId, projectId: projectIdFromParams } = params;

    this.props.reportActions.fetchReport(nextTeamId || teamId, REPORT_TYPE, {
      projectIdFromParams,
      fetchAllReports,
    });
  }

  updateSortField = field => {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { projectId } = params;

    this.props.sortByReportTypeActions.updateSortField(REPORT_TYPE, field, projectId);
    this.refreshReportData();
  };

  clearSelectedRows() {
    this.props.reportSelectedRowsActions.clearSelectedRows();
  }

  onRowSelectChange(issue) {
    this.props.reportSelectedRowsActions.updateSelectedRows(issue);
  }

  updateOptionsFilter = (field, value) => {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { projectId } = params;

    //Sets licenseSingleOnly field to true when issue type is license
    const isIssueTypeLicense =
      (field === 'types' && value === MODEL.issueTypeMap.LICENSE) || field === 'licenseRisks';
    this.props.reportFilterActions.updateFilterValue(
      REPORT_TYPE,
      'licenseSingleOnly',
      isIssueTypeLicense,
      projectId
    );

    this.props.reportFilterActions.updateFilterArray(REPORT_TYPE, field, value, projectId);
    this.refreshReportData();
  };

  handleHighSeverityOnly(field, isChecked) {
    const { match, reportFilterState } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { projectId } = params;
    const { [REPORT_TYPE]: issueFilters } = reportFilterState;
    const { severities } = issueFilters;
    let newSelectedValues = [];
    if (isChecked) {
      newSelectedValues = severities.concat(['HIGH']).join(',');
    } else {
      newSelectedValues = severities.filter(severity => severity !== 'HIGH').join(',');
    }

    this.props.reportFilterActions.updateFilterArray(
      REPORT_TYPE,
      field,
      newSelectedValues,
      projectId
    );
    this.refreshReportData();
  }

  toggleBooleanFilter = (field, isChecked) => {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { projectId } = params;

    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, isChecked, projectId);
    this.refreshReportData();
  };

  toggleActiveOrSuppressed(activeOrSuppressed) {
    this.props.reportActions.toggleActiveOrSuppressed(activeOrSuppressed);
    this.refreshReportData();
    this.clearSelectedRows();
    this.closeDropdownMenu();
  }

  handleCreateIssueClick = modal => {
    const { reportSelectedRows } = this.props;
    const currentSelectedRow = reportSelectedRows[0];
    let href;

    if (currentSelectedRow && currentSelectedRow._links && currentSelectedRow._links.issueData) {
      href = currentSelectedRow._links.issueData.href;
    }
    if (href) {
      this.props.reportIssuesActions.fetchFixInfo(href);
    }

    this.props.modalActions.openModal(modal);
  };

  toggleCtaVulnModalOpen = location => {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId } = params;

    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_VULN_METHODS,
      teamId,
      location
    );
  };

  toggleCtaCreateIssueModalOpen(location) {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId } = params;

    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_CREATE_ISSUE,
      teamId,
      location
    );
    this.props.reportIssuesActions.createIssueButtonClickedSnowplow(teamId, MODEL.NON_PAID_TEAM);
  }

  toggleCtaPremiumVulnModalOpen = location => {
    const { match } = this.props;
    const { params }: { params: IssuesPageMatchParams } = match;
    const { teamId } = params;

    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_PREMIUM_VULN,
      teamId,
      location
    );
  };

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu('issuepage-action');
  }

  handleIgnoreIssueConfirm(comment: string, suppressUntil: Date) {
    const { reportState, reportSelectedRows, reportIssuesActions } = this.props;
    const isIgnoring = reportState.activeOrSuppressed !== SUPPRESSED;
    reportSelectedRows.forEach(issue => {
      if (issue && issue._links && issue._links.self) {
        reportIssuesActions.maybeSuppressIssue(
          issue,
          REPORT_TYPE,
          isIgnoring,
          comment,
          suppressUntil
        );
      }
    });
    this.clearSelectedRows();
  }

  render() {
    const {
      reportsByType,
      repoDataById,
      sortByReportType,
      reportSelectedRows = [],
      match,
      reports,
      reportState,
      reportFilterState,
      insightsByType,
      statsByWorkspaceId,
      statsByProjectId,
      dropdownMenuState,
      integrationState,
      orgState,
      teamState,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;

    const { params }: { params: IssuesPageMatchParams } = match;
    const { isFetching, errorMessage } = reports;
    const { activeOrSuppressed } = reportState;
    const { ISSUES: issueInsights = {}, isFetchingInsights } = insightsByType;
    const {
      direct = null,
      highSeverity = null,
      mediumSeverity = null,
      lowSeverity = null,
      vulnMethods = null,
      medianSecondsToRecovery = null,
    } = issueInsights;
    const { teamId, projectId } = params;
    const stats = projectId ? statsByProjectId[projectId] || {} : statsByWorkspaceId[teamId] || {};

    const { data = {} } = stats;
    const { repos: totalRepos = 0, issues: totalIssues = {} } = data;
    const { [REPORT_TYPE]: issuesData = {} } = reportsByType;
    const { _embedded, page = {} } = issuesData;
    const issues = _embedded?.issues;
    const currentSort =
      projectId && sortByReportType[projectId]
        ? sortByReportType[projectId][REPORT_TYPE] || {}
        : sortByReportType[REPORT_TYPE] || {};
    const { medianUnit = 'N/A', medianLabel = 'days' } = medianSecondsToRecovery
      ? Helpers.getMedianTimeFormat(medianSecondsToRecovery)
      : {};
    const { openedDropdown = {} } = dropdownMenuState;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    const { repoCount } = activeTeam;
    const createJiraTxt = `${JIRA_CLOUD} / ${JIRA_ONPREM}`;

    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);

    const { data: integrationStateData = {} } = integrationState;
    const integrationList = integrationStateData.content || [];

    const isProjectDetailsPage = !!projectId;
    let totalUnfilteredIssues = 0;
    for (const type in totalIssues) {
      totalUnfilteredIssues += totalIssues[type];
    }

    const hasIgnoreUnignorePermission = shouldShowVeracodePage
      ? Helpers.hasIgnoreIssueChangesPermission({ teams, teamId })
      : true;
    const hasThirdPartyPermission = shouldShowVeracodePage
      ? Helpers.hasThirdPartyIssuePermission({ teams, teamId })
      : true;
    const showActionsButtonSelectColumn = // will show Actions button and Select column
      activeOrSuppressed !== SUPPRESSED
        ? // if it is in Active view and the user has either ignoreUnignore or createThirdParty permissions
          hasIgnoreUnignorePermission || hasThirdPartyPermission
        : // if it is in Ignore view and the user has ignoreUnignore permissions
          hasIgnoreUnignorePermission;

    const issueFilters =
      projectId && reportFilterState[projectId]
        ? reportFilterState[projectId][REPORT_TYPE]
        : reportFilterState[REPORT_TYPE];

    const {
      vulnDirectOnly = false,
      vulnMethodsOnly = false,
      severities,
      licenseRisks,
      types = [],
      statuses,
      licenseSingleOnly = false,
    } = issueFilters;
    const activeIssueType = types[0];
    const isShowingForVulnType = activeIssueType === MODEL.issueTypeMap.VULNERABILITY;
    const isShowingForLicenseType = activeIssueType === MODEL.issueTypeMap.LICENSE;

    const { [projectId]: projectData = {} } = repoDataById;
    const { type: projectType = '' } = projectData;

    // We are in a container project if this component is loaded in project details page which type is container
    const isContainerProject =
      isProjectDetailsPage && projectType.toUpperCase() === MODEL.PROJECT_TYPES.CONTAINER;

    const noIssueMessage = MODEL.NO_ISSUE_MESSAGE_MAP[activeIssueType];

    let titleColumnWidth = 'col-1-4';

    if (isProjectDetailsPage && !isShowingForVulnType) {
      titleColumnWidth = 'col-1-3';
    } else if (isPaidOrTrialing) {
      titleColumnWidth = 'col-1-5';
    }

    const columnWidths = {
      id: 'col-1-12',
      type: 'col-1-9',
      severity: 'col-1-12',
      licenseRisk: 'col-1-12',
      cve: 'col-1-10',
      title: titleColumnWidth,
      repoName: 'col-1-7',
      libraryVersion: 'col-1-12',
      libraryName: '',
      branchOrTag: '',
      status: 'col-1-12',
      select: 'col-1-18',
    };

    let issuesRows;

    if (!issues?.length) {
      issuesRows = totalUnfilteredIssues ? (
        <h3 className="ml color--muted mt+">
          {/* undefined _embedded object means no results were returned based on selected scanDate range */}
          {!_embedded && Helpers.hasScanDateEnabled()
            ? Helpers.outOfScanDateRange(REPORT_TYPE, types)
            : noIssueMessage}
        </h3>
      ) : (
        <div className="grid grid--center">
          <div className="grid__item col-3-5 mt+" data-automation-id="RowCell">
            {!repoCount && !Helpers.hasScanDateEnabled() ? (
              <ZeroIssuesPanel repos={totalRepos} />
            ) : (
              Helpers.outOfScanDateRange(REPORT_TYPE, types)
            )}
          </div>
        </div>
      );
    } else {
      issuesRows = issues?.map((issue, index) => {
        const isRowSelected = reportSelectedRows.some(row => row.id === issue.id);

        return (
          <IssueRow
            key={`issue-row-${index}`}
            issue={issue}
            columnWidths={columnWidths}
            isSelected={isRowSelected}
            onRowSelectChange={issue => this.onRowSelectChange(issue)}
            teamId={teamId}
            isProjectDetailsPage={isProjectDetailsPage}
            isPaidOrTrialing={isPaidOrTrialing}
            onToggleCtaPremiumVulnModalOpen={this.toggleCtaPremiumVulnModalOpen}
            org={org}
            team={activeTeam}
            activeIssueType={activeIssueType}
            isContainerProject={isContainerProject}
            showActionsButtonSelectColumn={showActionsButtonSelectColumn}
          />
        );
      });
    }

    const filterControls = (
      <div className="flex">
        <div className={`width--100 mr--`}>
          <ReportOptionsFilter
            value={activeOrSuppressed}
            options={MODEL.issueSuppressStatusOptions}
            onChange={(field, val) => this.toggleActiveOrSuppressed(val)}
            isMulti={false}
            isClearable={false}
            field="suppress_statuses"
            placeholder="Issue Type"
            automationId="ReportOptionsFilter-ActiveOrSuppressed"
          />
        </div>
        <div className={`${isProjectDetailsPage ? 'width--150' : 'width--200'} mr--`}>
          <ReportOptionsFilter
            value={severities}
            options={MODEL.severityOptions}
            onChange={this.updateOptionsFilter}
            field="severities"
            isMulti={true}
            placeholder="Severity"
            isClearable={true}
            automationId="ReportOptionsFilter-Severity"
          />
        </div>
        {isShowingForLicenseType && isLicenseRiskFeatureEnabled && (
          <div className={`${isProjectDetailsPage ? 'width--150' : 'width--200'} mr--`}>
            <LicenseRiskOptionsFilter
              licenseRisks={licenseRisks}
              projectId={projectId}
              teamId={teamId}
              reportType={REPORT_TYPE}
            />
          </div>
        )}
        {isPaidOrTrialing && (
          <div className="width--100 mr--">
            <ReportOptionsFilter
              value={statuses}
              options={MODEL.issueStatusOptions}
              onChange={this.updateOptionsFilter}
              isMulti={false}
              isClearable={false}
              field="statuses"
              placeholder="Status"
              automationId="ReportOptionsFilter-Status"
            />
          </div>
        )}
        {isProjectDetailsPage && (
          <div className="flex align-items--center width--100">
            <Tooltip
              content="Only show vulnerabilities found in Libraries that are direct dependencies"
              id="direct-libs-only"
            >
              <ReportBooleanFilter
                label="Direct Libraries"
                field="vulnDirectOnly"
                isChecked={vulnDirectOnly}
                onClick={this.toggleBooleanFilter}
                textJustification="text--right"
                fontSize="font--h8"
                automationId="ReportBooleanFilter-DirectLibrariesOnly"
              />
            </Tooltip>
          </div>
        )}

        {isProjectDetailsPage && !isContainerProject && (
          <div className="flex align-items--center width--100 ml-">
            <Tooltip
              content="Only show vulnerabilities with vulnerable methods"
              id="vuln-methods-only"
            >
              <ReportBooleanFilter
                showUpgradeModalIfClicked={!isPaidOrTrialing}
                callToAction={() =>
                  this.toggleCtaVulnModalOpen('ISSUE_LIST_VULN_METHOD_CHECKBOX_FILTER')
                }
                label="Vulnerable methods"
                field="vulnMethodsOnly"
                isChecked={vulnMethodsOnly}
                onClick={this.toggleBooleanFilter}
                textJustification="text--right"
                fontSize="font--h8"
                automationId="ReportBooleanFilter-VulnMethodsOnly"
              />
            </Tooltip>
          </div>
        )}
        {isShowingForLicenseType && (
          <div className="flex align-items--center width--100 ml-">
            <Tooltip
              content="Only show licenses violations with a single license"
              id="hide-dual-license"
            >
              <ReportBooleanFilter
                label="Hide multiple licenses"
                field="licenseSingleOnly"
                isChecked={licenseSingleOnly}
                onClick={this.toggleBooleanFilter}
                textJustification="text--right"
                fontSize="font--h8"
                automationId="ReportBooleanFilter-LicenseSingleOnly"
              />
            </Tooltip>
          </div>
        )}
      </div>
    );

    const actionBtn = (
      <div data-automation-id="IssuesPage-ReportHeader-ActionButton">
        {showActionsButtonSelectColumn && (
          <DropdownMenu
            animate={true}
            textAlign="left"
            align="right"
            isOpen={!!openedDropdown['issuepage-action']}
            close={() => this.delayCloseDropdownMenu()}
            toggle={
              <button
                className={`p- ${
                  reportSelectedRows.length < 1
                    ? 'disabled border-color--white-dark'
                    : 'btn--success'
                }`}
                disabled={reportSelectedRows.length < 1}
                onClick={() => this.props.dropdownMenuActions.openDropdownMenu('issuepage-action')}
              >
                Actions
              </button>
            }
          >
            {activeOrSuppressed !== SUPPRESSED ? (
              <span>
                {hasThirdPartyPermission && reportSelectedRows.length === 1 ? (
                  <NestedDropdownMenu
                    delay={0}
                    animate={true}
                    toggle={
                      <button>
                        Create Issue <i className="sci sci__caret--right pl-" />
                      </button>
                    }
                  >
                    {isPaidOrTrialing ? (
                      <span>
                        {integrationList.length > 0 && (
                          <li>
                            <button
                              onClick={() =>
                                this.handleCreateIssueClick(MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL)
                              }
                            >
                              {createJiraTxt}
                            </button>
                          </li>
                        )}
                        <li className="bo-b--1 border-color--white-dark" />
                        <li>
                          <button
                            onClick={() =>
                              this.handleCreateIssueClick(MODEL.CREATE_ISSUE_JIRA_ENTERPRISE_MODAL)
                            }
                          >
                            {JIRA_LEGACY}
                          </button>
                        </li>
                        <li className="bo-b--1 border-color--white-dark" />
                        <li>
                          <button
                            onClick={() =>
                              this.handleCreateIssueClick(MODEL.CREATE_ISSUE_GITHUB_MODAL)
                            }
                          >
                            {GITHUB}
                          </button>
                        </li>
                      </span>
                    ) : (
                      <span>
                        <li>
                          <button
                            onClick={() =>
                              this.toggleCtaCreateIssueModalOpen(
                                'ISSUE_LIST_CREATE_ISSUE_JIRA_CLOUD_BTN'
                              )
                            }
                          >
                            <ProIcon />
                            {createJiraTxt}
                          </button>
                        </li>
                        <li className="bo-b--1 border-color--white-dark" />
                        <li>
                          <button
                            onClick={() =>
                              this.toggleCtaCreateIssueModalOpen(
                                'ISSUE_LIST_CREATE_ISSUE_JIRA_ENTERPRISE_BTN'
                              )
                            }
                          >
                            <ProIcon /> {JIRA_LEGACY}
                          </button>
                        </li>
                        <li className="bo-b--1 border-color--white-dark" />
                        <li>
                          <button
                            onClick={() =>
                              this.toggleCtaCreateIssueModalOpen(
                                'ISSUE_LIST_CREATE_ISSUE_GITHUB_BTN'
                              )
                            }
                          >
                            <ProIcon /> {GITHUB}
                          </button>
                        </li>
                      </span>
                    )}
                  </NestedDropdownMenu>
                ) : (
                  <li className="color--muted">
                    <button disabled>
                      Create Issue
                      <i className="sci sci__caret--right pl-" />
                    </button>
                  </li>
                )}
                <li className="bo-b--1 border-color--white-dark" />
                {hasIgnoreUnignorePermission && (
                  <li>
                    <button
                      onClick={() => this.props.modalActions.openModal(MODEL.IGNORE_ISSUE_MODAL)}
                    >
                      Ignore
                    </button>
                  </li>
                )}
              </span>
            ) : (
              <li>
                {hasIgnoreUnignorePermission && (
                  <button
                    className="ph-"
                    onClick={() => this.props.modalActions.openModal(MODEL.IGNORE_ISSUE_MODAL)}
                  >
                    Unignore
                  </button>
                )}
              </li>
            )}
          </DropdownMenu>
        )}
        <IgnoreIssueModal
          selectedIssues={reportSelectedRows}
          isIgnoring={activeOrSuppressed !== SUPPRESSED}
          onConfirm={(comment, suppressUntil) =>
            this.handleIgnoreIssueConfirm(comment, suppressUntil)
          }
        />
        {reportSelectedRows.length === 1 && (
          <CreateIssueModal selectedIssue={reportSelectedRows[0] || {}} />
        )}
      </div>
    );

    const reportHeaderRow = (
      <ReportHeaderRow>
        <ReportHeaderItem
          label="Issue ID"
          field="id"
          widthClass={columnWidths.id}
          isSortable={true}
          onClick={field => this.updateSortField(field)}
          currentSort={currentSort}
          automationId="ReportHeaderItem-IssueId"
        />
        <ReportHeaderItem
          label="Severity"
          field="severity"
          widthClass={columnWidths.severity}
          isSortable={true}
          onClick={field => this.updateSortField(field)}
          currentSort={currentSort}
          automationId="ReportHeaderItem-Severity"
        />
        {!isLicenseRiskFeatureEnabled && (
          <ReportHeaderItem
            label="Issue Type"
            field="type"
            widthClass={columnWidths.type}
            alignment="CENTER"
            automationId="ReportHeaderItem-IssueType"
          />
        )}
        {isShowingForVulnType && (
          <ReportHeaderItem
            label="Vulnerability ID"
            field="cve"
            widthClass={columnWidths.cve}
            automationId="ReportHeaderItem-VulnerabilityId"
          />
        )}

        {isShowingForLicenseType && isLicenseRiskFeatureEnabled ? (
          <ReportHeaderItem
            label="License"
            field="title"
            widthClass={columnWidths.title}
            automationId="ReportHeaderItem-Description"
          />
        ) : (
          <ReportHeaderItem
            label="Description"
            field="title"
            widthClass={columnWidths.title}
            automationId="ReportHeaderItem-Description"
          />
        )}

        {isShowingForLicenseType && isLicenseRiskFeatureEnabled && (
          <ReportHeaderItem
            label="License Risk"
            field="risk"
            widthClass={columnWidths.licenseRisk}
            isSortable={true}
            onClick={field => this.updateSortField(field)}
            currentSort={currentSort}
            automationId="ReportHeaderItem-LicenseRisk"
            tooltip="The license information Veracode provides is not intended to be legal advice. Please consult your legal advisor for the specific details of the license, your use case, and associated risks and obligations."
          />
        )}
        {!isProjectDetailsPage && (
          <ReportHeaderItem
            label="Project"
            field="repo"
            widthClass={columnWidths.repoName}
            isSortable={true}
            onClick={field => this.updateSortField(field)}
            currentSort={currentSort}
            automationId="ReportHeaderItem-Project"
          />
        )}
        <ReportHeaderItem
          label="Library"
          field="libraryName"
          widthClass={columnWidths.libraryName}
          automationId="ReportHeaderItem-Library"
        />
        {isProjectDetailsPage && !isContainerProject && (
          <ReportHeaderItem
            label={'Branch / Tag'}
            field="branchOrTagName"
            widthClass={columnWidths.branchOrTag}
            automationId="ReportHeaderItem-BranchOrTagName"
          />
        )}
        {isPaidOrTrialing && (
          <ReportHeaderItem
            label="Status"
            field="status"
            widthClass={columnWidths.status}
            alignment="CENTER"
            automationId="ReportHeaderItem-Status"
          />
        )}
        {showActionsButtonSelectColumn && (
          <ReportHeaderItem
            label="Select"
            widthClass={columnWidths.select}
            alignment="CENTER"
            automationId="ReportHeaderItem-Select"
          />
        )}
      </ReportHeaderRow>
    );

    const reportHeader = (
      <ReportHeader
        reportType={REPORT_TYPE}
        stringFilterPlaceholder={'Search issues'}
        renderReportHeaderItems={() => (
          <Fragment>
            {activeOrSuppressed !== SUPPRESSED && (
              <div className="grid__item flex flex--justify-content--space-between p-- align-items--center">
                {filterControls}
                <div className="flex align-items--center">
                  <div className="mr--">{actionBtn}</div>
                  <CSVReportDownloader
                    reportType={REPORT_TYPE}
                    page={page}
                    noElementText="No vulnerabilities issue to download"
                    projectId={projectId}
                  />
                </div>
              </div>
            )}
            {activeOrSuppressed === SUPPRESSED && (
              <div className="col-1-1 flex justify-content--space-between p-- align-items--center">
                {filterControls}
                <div className="flex">
                  <div className="mr--">{actionBtn}</div>
                </div>
              </div>
            )}
          </Fragment>
        )}
        renderSearchResultsMetadata={() => (
          <div>
            {page.totalElements} issue
            {page.totalElements === 1 ? '' : 's'}{' '}
          </div>
        )}
        renderReportHeaderRow={() => <Fragment>{reportHeaderRow}</Fragment>}
      />
    );

    const titleText = isProjectDetailsPage ? 'Project Issues' : 'Issues';
    const issuesBySeverity = [
      { id: 'high', name: 'High', count: highSeverity },
      { id: 'medium', name: 'Medium', count: mediumSeverity },
      { id: 'low', name: 'Low', count: lowSeverity },
    ];

    if (errorMessage) {
      return (
        <div className="grid pt grid--center">
          {reportHeader}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage />
          </div>
        </div>
      );
    }

    return (
      <div className="grid">
        <Helmet>
          <title>{titleText}</title>
        </Helmet>
        {!isProjectDetailsPage && (
          <div className="grid__item col-1-1">
            <div className="col-1-1 font--h6 mb--">INSIGHTS</div>
            <InsightsWrapper>
              {isPaidOrTrialing && (
                <InsightNumber
                  title="Median Time to Resolution"
                  field="medianTimeToRecovery"
                  isFetchingInsights={isFetchingInsights}
                  content={medianUnit}
                  contentUnit={medianLabel}
                  automationId="InsightNumber-MedianTimeToResolution"
                />
              )}
              <IssuesInsightsBar
                title="Issues by Severity"
                content={issuesBySeverity}
                isFetchingInsights={isFetchingInsights}
              />
              <InsightNumber
                title="High Severity Issues"
                field="severities"
                isFetchingInsights={isFetchingInsights}
                content={highSeverity === null ? 'N/A' : `${highSeverity}`}
                isClickable={true}
                handleClick={(field, isChecked) => this.handleHighSeverityOnly(field, isChecked)}
                isSelected={severities.includes('HIGH')}
                automationId="InsightNumber-HighSeverityIssues"
              />
              <InsightNumber
                title="Direct Library Issues"
                field="vulnDirectOnly"
                isFetchingInsights={isFetchingInsights}
                content={direct === null ? 'N/A' : `${direct}`}
                isClickable={true}
                handleClick={this.toggleBooleanFilter}
                isSelected={vulnDirectOnly}
                automationId="InsightNumber-DirectLibrariesOnly"
              />
              <InsightNumber
                title="Vulnerable Methods"
                field="vulnMethodsOnly"
                isFetchingInsights={isFetchingInsights}
                content={vulnMethods === null ? 'N/A' : `${vulnMethods}`}
                isClickable={true}
                handleClick={this.toggleBooleanFilter}
                isSelected={vulnMethodsOnly}
                showUpgradeModalIfClicked={!isPaidOrTrialing}
                callToAction={() =>
                  this.toggleCtaVulnModalOpen('ISSUE_LIST_VULN_METHOD_INSIGHTS_FILTER')
                }
                automationId="InsightNumber-VulnerableMethods"
              />
            </InsightsWrapper>
          </div>
        )}

        {isProjectDetailsPage ? (
          <div className="grid__item col-1-1" id="reportTop">
            <div className="bg-color--black-light color--white pl- pv- flex">
              <div className="width--200 mr-- zIndex-9--overlay">
                <ReportOptionsFilter
                  value={types}
                  options={MODEL.issueTypesOptions}
                  onChange={this.updateOptionsFilter}
                  isMulti={false}
                  isClearable={false}
                  field="types"
                  placeholder="Issue Type"
                  automationId="ReportOptionsFilter-IssueTypesOptionsFilter"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid__item col-1-1 mt mb- flex align-items--center" id="reportTop">
            <div className="font--h6 mr inline-block" data-e2e={'IssuesPage-Title'}>
              ISSUES LIST
            </div>
            <div className="width--150 zIndex-2--surfaceOver">
              <ReportOptionsFilter
                value={types}
                options={MODEL.issueTypesOptions}
                onChange={this.updateOptionsFilter}
                isMulti={false}
                isClearable={false}
                field="types"
                placeholder="Issue Type"
                automationId="ReportOptionsFilter-issueTypesOptionsFilter"
              />
            </div>
          </div>
        )}
        <div className="col-1-1 zIndex-1--screenForeground">
          <div className="col-1-1 mb--- p0">
            <div className="grid">
              <div className="grid__item col-1-1">{reportHeader}</div>
            </div>
          </div>
        </div>

        <div className="grid__item col-1-1">
          <LoaderWrapper isLoaderShowing={isFetching}>
            <div data-automation-id="ReportRows">{issuesRows}</div>
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    insightsByType: state.insightsByType,
    repoDataById: state.repoDataById,
    reportIssuesState: state.reportIssuesState,
    reports: state.reports,
    reportsByType: state.reportsByType,
    reportFilterState: state.reportFilterState,
    reportSelectedRows: state.reportSelectedRows,
    reportState: state.reportState,
    sortByReportType: state.sortByReportType,
    statsByProjectId: state.statsByProjectId,
    statsByWorkspaceId: state.statsByWorkspaceId,
    dropdownMenuState: state.dropdownMenuState,
    integrationState: state.integrationState,
    orgState: state.orgState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
    navigationState: state.navigationState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    insightsActions: bindActionCreators(insightsActions as any, dispatch),
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    reportActions: bindActionCreators(reportActions as any, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions as any, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions as any, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions as any, dispatch),
    sortByReportTypeActions: bindActionCreators(sortByReportTypeActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
    dropdownMenuActions: bindActionCreators(dropdownMenuActions as any, dispatch),
    modalActions: bindActionCreators(modalActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuesPage);
