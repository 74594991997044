import React from 'react';
import Helmet from 'react-helmet';
import LoaderWrapper from '~/components/LoaderWrapper';
import SelectUnifiedPolicy from '~/components/SelectUnifiedPolicy';
import { useParams } from 'react-router-dom';

function WorkspaceUnifiedPoliciesPage() {
  const { teamId } = useParams();

  return (
    <LoaderWrapper>
      <div className="grid mt mb++">
        <Helmet>
          <title>Policies</title>
        </Helmet>
        <form onSubmit={e => e.preventDefault()} className="grid__item col-1-1">
          <div className="grid">
            <div className="grid__item col-1-1">
              <h3 data-automation-id="WorkspacePoliciesPage-Title">Policy</h3>
            </div>
            <div className="grid__item col-1-1 mt-">
              Select the policy to use for evaluating the projects in this workspace. Veracode
              creates issues when findings violate policy rules. Policy Administrators can create or
              edit policies in Policies.
            </div>
            <div className="grid__item col-1-1 pt+ pb">
              <strong className="font--h6">Select Policy</strong>
            </div>
            <div className="grid__item col-1-1">
              <SelectUnifiedPolicy teamId={teamId} />
            </div>
          </div>
        </form>
      </div>
    </LoaderWrapper>
  );
}

export default WorkspaceUnifiedPoliciesPage;
