export enum Severity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface IssueInsightSeverity {
  id: Severity.High | Severity.Medium | Severity.Low;
  name: string;
  count: number | null;
}
