import React, { Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
// import { hot } from 'react-hot-loader';

import { renderRoutesInPath } from '~/utils/RouterUtils';

import CaptureRouteNotFound from '~/components/CaptureRouteNotFound';
import SourceClearLoader from '~/components/SourceClearLoader';
import SourceClearToastrSystem from '~/containers/SourceClearToastrSystem';
import UpgradeCtaModal from '~/containers/UpgradeCtaModal';
import OveragesModal from '~/containers/OveragesModal';
import WelcomeModal from '~/containers/WelcomeModal';
import ErrorBoundary from '~/components/ErrorBoundary';
import SCPage from '~/components/SCPage';
const VCPage = React.lazy(() => import(/* webpackChunkName: "veracode" */ './VCPage'));

import * as appLoadActions from '~/actions/appLoad';
import * as meActions from '~/actions/me';
import * as navigationActions from '~/actions/navigation';
import * as teamActions from '~/actions/team';
import * as toastrActions from '~/actions/toastr';
import * as userStatusActions from '~/actions/userStatus';

import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import routes from '~/routes';
import { RootState } from '~/reducers';
import { QueryClient, QueryClientProvider } from 'react-query';

interface AppProps extends RouteComponentProps {
  appLoadState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  teamState: App.TeamState;
  toastrState: object;
  userStatus: object;
  vcPageState: VCPageState;
}
class App extends React.Component<AppProps & ReturnType<typeof mapDispatchToProps>> {
  componentDidMount() {
    const { history } = this.props;
    // Todo: only load stripe on standalone https://jira.veracode.local/jira/browse/SCAF-1633

    const head = document.getElementsByTagName('head')[0];
    const stripe = document.createElement('script');
    stripe.src = 'https://js.stripe.com/v3/';
    head.appendChild(stripe);

    this.props.appLoadActions.checkUserStatus(history);

    history.listen(location => {
      this.props.navigationActions.handleLocationChange(location, history);
    });
  }

  render() {
    //react-query
    const queryClient = new QueryClient();

    const { myState, teamState, userStatus, appLoadState, vcPageState } = this.props;

    const { teamsFetchResolved } = teamState;
    const { fetchMeResolved, fetchMeFailed, me = {} } = myState;
    const { shouldShowVeracodePage } = vcPageState;
    const { roles = [], fetchUserStatusFailed } = userStatus;
    const isLoggingIn = roles.includes('USER');
    const { preAppLoadResolved } = appLoadState;
    const { organization } = me;

    /*
     * we don't want the Switch to be rendered until the following is true:
     * - /user-status has been resolved AND:
     *   - the user is not authed (roles will not have 'USER')
     *   OR
     *   - user *IS* authed
     *     in which case wait until both `/me` and `/teams` are resolved and stored in state
     * this will help avoid race conditions with pages attempting to mount/render without the necessary state
     */
    const isAppLoadResolved =
      preAppLoadResolved && (!isLoggingIn || (teamsFetchResolved && fetchMeResolved));

    if (fetchUserStatusFailed) {
      // this will be caught by the ErrorBoundary and the GenericError screen will be shown
      throw new Error('/user-status response failed.');
    }

    if (fetchMeFailed) {
      // this will be caught by the ErrorBoundary and the GenericError screen will be shown
      throw new Error('/me response failed.');
    }

    const SCOrVCPage = shouldShowVeracodePage ? (
      <ErrorBoundary>
        <Suspense fallback={<SourceClearLoader />}>
          <VCPage
            organization={organization}
            render={() => (
              <CaptureRouteNotFound>
                <Switch children={renderRoutesInPath(routes)} />
              </CaptureRouteNotFound>
            )}
          />
        </Suspense>
      </ErrorBoundary>
    ) : (
      <ErrorBoundary>
        <SCPage
          render={() => (
            <CaptureRouteNotFound>
              <Switch children={renderRoutesInPath(routes)} />
            </CaptureRouteNotFound>
          )}
        />
      </ErrorBoundary>
    );

    return (
      <QueryClientProvider client={queryClient}>
        <Fragment>
          <div className="flex flex-direction--column height--100vh">
            {isAppLoadResolved ? (
              SCOrVCPage
            ) : (
              <div className="container flex flex--content">
                <div className="flex flex--content justify-content--center">
                  <div className="col-1-1">
                    <SourceClearLoader />
                  </div>
                </div>
              </div>
            )}
          </div>

          <UpgradeCtaModal />
          <OveragesModal />
          <WelcomeModal />
          <SourceClearToastrSystem />
        </Fragment>
      </QueryClientProvider>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    appLoadState: state.appLoadState,
    myState: state.myState,
    orgState: state.orgState,
    teamState: state.teamState,
    toastrState: state.toastrState,
    userStatus: state.userStatus,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    appLoadActions: bindActionCreators(appLoadActions as any, dispatch),
    meActions: bindActionCreators(meActions as any, dispatch),
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    toastrActions: bindActionCreators(toastrActions as any, dispatch),
    userStatusActions: bindActionCreators(userStatusActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
