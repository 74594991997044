import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Helpers from '~/utils/Helpers';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';

/**
 * withAccessCheck
 *
 * Curry-ing to allow us to inject function (accessCheck) to perform checks and actions based on props.
 * The accessCheck function can be used for authorization or paywall access to WrappedComponent.
 */
export const withAccessCheck = WrappedComponent => accessCheck => {
  const HOC = class C extends React.Component {
    render() {
      let path = accessCheck(this.props);
      if (path) {
        // eslint-disable-next-line react/prop-types
        this.props.history.push(path);
      }
      return (
        <Fragment>
          {path ? null : (
            <div className="col-1-1">
              <WrappedComponent {...this.props} />
            </div>
          )}
        </Fragment>
      );
    }
  };

  function mapStateToProps(state) {
    return {
      teamState: state.teamState,
      myState: state.myState,
      vcPageState: state.vcPageState,
      orgState: state.orgState,
      vcMyState: state.vcMyState,
    };
  }

  return connect(mapStateToProps)(HOC);
};

/**
 * withManageAgentCheck
 *
 * Perform Manage Agent access-related actions such as page redirections
 */
export const withManageAgentCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const { teamState, match, location } = props;
    const { params = {} } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;

    const { pathname = '' } = location;

    if (pathname.includes('agents') && !Helpers.hasManageAgentPermissions({ teams, teamId })) {
      return '/no-workspace-admin';
    }
  });
};

// Extend the HOC withAccessCheck to perform org level admin check or for paywall or combinations of such

/**
 * withPermissionsCheck
 *
 * Perform permissions-based actions such as page redirections
 */
export const withPermissionsCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const { myState, location } = props;
    const { me = {} } = myState;
    const { permissions = {} } = me;
    const { update } = permissions;
    const { pathname = '' } = location;

    //Perform redirection when user has no update permission in personal settings page
    if (pathname.includes('settings/profile') && !update) {
      return '/settings/notifications'; //Setting to a non-permission-based page
    }
  });
};

/**
 * withVeracodeUserCheck
 *
 * Redirect Veracode users to a 404 page if that route is not supported in the
 * integrated view.
 */
export const withVeracodeUserCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const { vcPageState } = props;

    const { shouldShowVeracodePage } = vcPageState;

    if (shouldShowVeracodePage) {
      return '/not-found';
    }
  });
};

export const withEnforceWorkspaceRulesCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const {
      myState: {
        me: {
          organization: {
            permissions: { readPolicy },
          }
        },
      },
    } = props;

    if (!readPolicy) {
      return '/not-found';
    }
  });
};

export const withEnforceRulesCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const {
      myState: {
        me: {
          organization: {
            // both 'readPolicy' and 'updatePolicy' should be true for SecLeads, false for everybody else
            permissions: { readPolicy, updatePolicy },
          }
        },
      },
    } = props;

    const canEnforceOrgLevelRules = readPolicy && updatePolicy;

    if (!canEnforceOrgLevelRules) {
      return '/not-found';
    }
  });
};

export const withEnforceUnifiedPolicyCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const {
      myState: {
        me: {
          organization: {
            permissions: { readPolicy },
          },
          permissions: { unifiedPolicy },
        },
      },
    } = props;

    if (!unifiedPolicy || !readPolicy) {
      return '/not-found';
    }
  });
};

export const withLibraryCatalogCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(() => {
    if (!Helpers.hasLibraryCatalogEnabled()) {
      return '/not-found';
    }
  });
};

/**
 * withFeatureFlagCheck
 *
 * Redirect users to a 404 page if that route is not supported if flag is false
 */
export const withFeatureFlagCheck = (WrappedComponent, FeatureFlag) => {
  return withAccessCheck(WrappedComponent)(props => {
    const { orgState }: { orgState: App.OrgState } = props;
    const { org } = orgState;
    const isFeatureFlagEnabled = FeatureFlagHelper.isFeatureEnabledForOrg(FeatureFlag, org);
    if (!isFeatureFlagEnabled) {
      return '/not-found';
    }
  });
};

/**
 * withOrgPermissionsCheck
 *
 * Redirect users to a 404 page if that route is not supported if flag is false
 */
export const withOrgPermissionCheck = (WrappedComponent, permissionToCheck) => {
  return withAccessCheck(WrappedComponent)(props => {
    const { orgState }: { orgState: App.OrgState } = props;
    const { org } = orgState;
    const { permissions } = org;
    const hasPermission = permissions[permissionToCheck];
    if (!hasPermission) {
      return '/org/no-admin';
    }
  });
};

/**
 * withStartScanPermissionCheck
 *
 * Redirect users to a 404 page if that route is not supported if flag is false
 */
export const withStartScanPermissionCheck = WrappedComponent => {
  return withAccessCheck(WrappedComponent)(props => {
    const { vcMyState } = props;
    const { permissions } = vcMyState;
    const { startVeracodeStaticScan, orgAgents, workspaceAgents } = permissions;
    const hasPermission = startVeracodeStaticScan || orgAgents || workspaceAgents;

    if (!hasPermission) {
      return '/not-found';
    }
  });
};
