import React, { Component } from 'react';
import { withRouter, Link, Switch } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';

import { renderRoutesInPath } from '~/utils/RouterUtils';
import Helpers from '~/utils/Helpers';
import OrgPaidStatus from '~/utils/OrgPaidStatus';

import InsightHorizontalBar from '~/components/InsightHorizontalBar';
import InsightNumber from '~/components/InsightNumber';
import InsightsWrapper from '~/components/InsightsWrapper';
import ListTab from '~/components/ProjectDetailsPage/ListTab';
import LoaderWrapper from '~/components/LoaderWrapper';
import ProIcon from '~/components/ProIcon';
import ProjectScanHistory from '~/containers/ProjectScanHistory';
import ProjectSettingsModal from '~/containers/ProjectSettingsModal';
import RepoScopeSwitcher from '~/containers/RepoScopeSwitcher';
import SidebarWrapper from '~/containers/SidebarWrapper';
import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import WatchProject from '~/containers/WatchProject';
import UpdateProjectDetailsModal from '~/containers/UpdateProjectDetailsModal';
import * as modalActions from '~/actions/modal';
import * as popoverActions from '~/actions/popover';
import * as projectSettingsActions from '~/actions/projectSettings';
import * as projectScanHistoryActions from '~/actions/projectScanHistory';
import * as repoActions from '~/actions/repo';
import * as repoScopeActions from '~/actions/repoScope';
import * as reportScopeActions from '~/actions/reportScope';
import * as sidebarActions from '~/actions/sidebar';
import * as toastrActions from '~/actions/toastr';
import * as upgradeModalActions from '~/actions/upgradeModal';
import * as userNotificationsActions from '~/actions/userNotifications';
import * as workspaceScanDateActions from '~/actions/workspaceScanDate';

import {
  ISSUE_NOTIFICATION_TYPES,
  PLAN_TYPE,
  PROJECT_TYPES,
  UPGRADE_MODAL_WATCH_REPO,
} from '~/constants/ModelConstants';

import {
  ProjectDetailsPageProps,
  ProjectDetailsPageMatchParams,
} from '~/containers/ProjectDetailsPage.types';
import { RootState } from '~/reducers';

const NOTIFICATIONS_TURNED_ON_MODAL = 'NOTIFICATIONS_TURNED_ON_MODAL';

class ProjectDetailsPage extends Component<
  ProjectDetailsPageProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    // always request Issues for All dates at the ProjectDetailsLevel
    if (Helpers.hasScanDateEnabled()) {
      this.props.workspaceScanDateActions.toggleScopeScansToAllDates(true);
    }

    this.loadProjectData();
  }

  // SAF 37: redirect user to no access premium if they are not accessing old scan id
  componentDidUpdate() {
    const { orgState, navigationState, projectScanHistory, match, history } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);

    const { routes = [], params: prevParams } = navigationState.prevState;

    const redirectedFromScanId = routes.find(route => route.state && route.state.scanIdRedirect);
    const previousScanId = redirectedFromScanId && prevParams ? prevParams.scanId : '';

    const {
      latestScanByDefaultBranch = {},
      isFetching: isFetchingScanHistory,
    } = projectScanHistory;
    const { id: latestScanId = 'na' } = latestScanByDefaultBranch;

    if (
      !isFetchingScanHistory &&
      !isPaidOrTrialing &&
      previousScanId &&
      previousScanId.toString() !== latestScanId.toString()
    ) {
      const { projectId } = params;
      history.replace(`/no-access-premium?projectId=${projectId}`);
    }
  }

  componentWillUnmount() {
    this.props.reportScopeActions.updateReportScope('repos', '*');
    this.props.repoScopeActions.resetRepoScope();
    this.props.modalActions.closeAllModals();
    if (Helpers.hasScanDateEnabled()) {
      this.props.workspaceScanDateActions.toggleScopeScansToAllDates(false);
    }
  }

  loadProjectData() {
    const { match, location, reportScope, history } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { projectId, teamId } = params;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { repoScanId = '' } = reportScope;
    const scope = repoScanId ? { repoScanId } : query;
    this.props.reportScopeActions.updateReportScope('repos', [projectId]);

    if (projectId) {
      // Fetch stats, insights and scan history based on any changes to repo scope
      this.props.repoActions
        .fetchRepoDataAndSetRepoScope(projectId, scope, history)
        .catch(() => {
          const { branch, tag } = query;
          // Remove query param from URL
          Helpers.removeURLQueryParams(window.location.href, ['branch', 'tag'], history);

          // Only display toastr when branch/tag is not undefined
          if (branch || tag) {
            let branchOrTagLabel = '';

            if (branch) {
              branchOrTagLabel = 'branch';
            } else if (tag) {
              branchOrTagLabel = 'tag';
            }

            const options = {
              id: 'BRANCHTAG_NOT_FOUND',
              title: `${Helpers.capFirst(branchOrTagLabel)} not found`,
              level: 'error',
              message: (
                <span>
                  The {branchOrTagLabel} you requested, <strong>{branch || tag}</strong>, doesn't
                  exist. Check the link and try again.
                </span>
              ),
            };

            this.props.toastrActions.addToastr(options);
          }
        })
        .finally(() => {
          // Make sure that we only load data (ie project scans, stats, etc) once repo data is received and scope set.
          this.props.repoActions.refreshProjectData(teamId, projectId);
          this.props.projectScanHistoryActions.fetchLatestProjectScanByDefaultBranch(
            teamId,
            projectId
          );
        });
    }
  }

  toggleSidebar = () => {
    const { sidebarState = {} } = this.props;
    const id = 'project-scan-history';
    const isSidebarOpen = sidebarState[id];

    if (isSidebarOpen) {
      this.props.sidebarActions.closeSidebar(id);
    } else {
      this.props.sidebarActions.openSidebar(id);
    }
  };

  showProjectSettingsModal = () => {
    this.props.projectSettingsActions.showProjectSettingsModal();
  };

  getRiskScoreColor(riskScore) {
    if (!riskScore) {
      return '';
    }

    if (riskScore >= 70) {
      return 'color--danger';
    } else if (riskScore >= 40) {
      return 'color--warning';
    } else {
      return '';
    }
  }

  toggleDeleteClosed = () => {
    const { match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { projectId } = params;

    const deletePopoverId = `delete-${projectId}`;
    this.props.popoverActions.closePopover(deletePopoverId);
  };

  toggleDeleteOpen = () => {
    const { match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { projectId } = params;

    const deletePopoverId = `delete-${projectId}`;
    this.props.popoverActions.openPopover(deletePopoverId);
  };

  toggleEditClosed = () => {
    const { match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { projectId } = params;

    const editPopoverId = `edit-${projectId}`;
    this.props.popoverActions.closePopover(editPopoverId);
  };

  toggleEditOpen = () => {
    const { match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { projectId } = params;
    const editPopoverId = `edit-${projectId}`;
    this.props.popoverActions.openPopover(editPopoverId);
  };

  // Appends query params to link
  appendQueryToLink = link => {
    const { repoScope = {} } = this.props;
    const { branch = '', tag = '' } = repoScope;

    const maybeTag = tag ? `?tag=${tag}` : '';
    const query = branch ? `?branch=${branch}` : maybeTag;

    return `${link}${query}`;
  };

  deleteRepository(repo) {
    const { history, match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { teamId } = params;

    this.props.repoActions.deleteRepository(repo).then(res => {
      if (res.success) {
        history.push(`/workspaces/${teamId}/projects`);
      }
    });
  }

  toggleCtaWatchModalOpen(location) {
    const { match } = this.props;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(location, teamId, location);
  }

  onWatchProjectChange(watching) {
    if (!watching) return;

    // If user wants to watch a project and currently there are no notification is enabled (/settings/notifications),
    // show modal telling user that notifications will be turned on.
    // Notification type SOURCECLEAR_ISSUES will be enabled by default. (see ModelConstants#ISSUE_NOTIFICATION_TYPES)
    this.props.userNotificationsActions.fetchNotificationsSettings().then(res => {
      const { settings: userNotificationsSettings = [] } = res;
      const isUserNotifiedOnProjectWatched = userNotificationsSettings.find(
        notification =>
          notification.notificationType === ISSUE_NOTIFICATION_TYPES.SOURCECLEAR_ISSUES
      );

      if (!isUserNotifiedOnProjectWatched) {
        this.props.modalActions.openModal(NOTIFICATIONS_TURNED_ON_MODAL);
        this.props.userNotificationsActions.updateNotificationSettings(
          ISSUE_NOTIFICATION_TYPES.SOURCECLEAR_ISSUES
        );
      }
    });
  }

  closeModal(modalType) {
    this.props.modalActions.closeModal(modalType);
  }

  render() {
    const {
      routes,
      match,
      repoDataById = {},
      statsByProjectId = {},
      popoverState = {},
      messageState = {},
      insightsByType = {},
      projectScanHistory = {},
      orgState,
      teamState,
      navigationState,
      modalState,
    } = this.props;
    const { openedModal = {} } = modalState;
    const { activeReportType } = navigationState;
    const { PROJECT: projectInsights = {}, isFetchingInsights } = insightsByType;
    const { riskScore, issuesBySeverity, vulnsBySeverity } = projectInsights;
    const { params }: { params: ProjectDetailsPageMatchParams } = match;
    const { teamId, projectId } = params;
    const { teams = [] } = teamState;
    const activeTeam = teams.find(team => team.id === teamId) || {};

    const { permissions = {} } = activeTeam;
    const { manageProjects } = permissions;

    const {
      [projectId]: projectData = {},
      isFetching: isFetchingRepoData = false,
      isSettingRepoScope = false,
    } = repoDataById;

    const isRepoDataLoading = isFetchingRepoData || _.isEmpty(projectData) || isSettingRepoScope;
    const deletePopoverId = `delete-${projectId}`;
    const editPopoverId = `edit-${projectId}`;
    const isDeleteOpen = popoverState[deletePopoverId] || false;
    const isEditOpen = popoverState[editPopoverId] || false;
    const { messages = {} } = messageState;
    const deleteRepoError = messages.DELETE_REPO_FAILURE;
    const stats = statsByProjectId[projectId] || {};
    const { data: statsData = {}, isFetching: isFetchingStats = false, hasError = false } = stats;
    const { issues = {}, vulnerabilities, licenses, libraries } = statsData;
    const issueTypeNameMap = {
      vulnerabilities: 'Vulns',
      libraries: 'Libraries',
      licenses: 'Licenses',
    };
    const issuesByType = [];
    const { type: projectType = '' } = projectData;
    let issuesTotal = 0;

    for (const type in issues) {
      issuesTotal += issues[type];
      issuesByType.push({ name: issueTypeNameMap[type], count: issues[type] });
    }

    const { latestScanByDefaultBranch = {}, isFetchingLatestScan = false } = projectScanHistory;
    const { id: latestScanId = 'na', scanDate } = latestScanByDefaultBranch;
    const latestScanDate = (scanDate && Helpers.formatDate(scanDate)) || 'na';

    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const isOrgEnterprise = OrgPaidStatus.getOrgPlanState(org) === PLAN_TYPE.ENTERPRISE;

    const baseUrl = projectId
      ? `/workspaces/${teamId}/projects/${projectId}`
      : `/workspaces/${teamId}`;
    const sidebarTitleHtml = (
      <div
        className="flex align-items--center font--h6 text--bold"
        data-automation-id="ProjectScanHistoryTitle"
      >
        {isPaidOrTrialing ? (
          <i className="fas fa-clock mr- font--h4 color--primary" />
        ) : (
          <ProIcon />
        )}
        Project Scan History
      </div>
    );

    const isContainerProject = projectType.toUpperCase() === PROJECT_TYPES.CONTAINER;

    /**
     * For Insights that contain inventory stats and issues count
     */
    const getErrorIcon = (errorId, errorMsg = 'Error fetching data') => (
      <Tooltip id={errorId} content={errorMsg}>
        <i className="sci sci--sm sci__alerts color--danger font--14" />
      </Tooltip>
    );
    const insightsInventoryContent = (
      <div className={`block text--bold`}>
        {hasError ? (
          getErrorIcon('inventory-stats')
        ) : (
          <div className="text--right">
            <p className="lh0 p0 m0 font--18">
              {libraries} <i className="fas fa-book color--muted" title="Libraries" />
            </p>
            <p className="lh0 p0 m0 font--18">
              {vulnerabilities}{' '}
              <i className="sci sci__shield--cross color--muted" title="Vulnerabilities" />
            </p>
            <p className="lh0 p0 m0 font--18">
              {licenses} <i className="fas fa-certificate color--muted" title="Licenses" />
            </p>
          </div>
        )}
      </div>
    );

    const maybeHasIssuesTotal = issuesTotal === null ? 'N/A' : issuesTotal;
    const insightsOpenIssuesContent = hasError
      ? getErrorIcon('total-issues-stats')
      : maybeHasIssuesTotal;
    const insightBySeverity =
      issuesBySeverity && vulnsBySeverity ? (
        <div className="grid text--center mt--">
          <div className="grid__item col-1-1 pl0">
            <div className="grid pb--">
              <div className="grid__item col-3-8"></div>
              <div className="grid__item col-1-4 font-family--roboto-light font--h8 pl--">
                Issues
              </div>
              <div className="grid__item col-3-8 font-family--roboto-light font--h8 pl-">
                Vulnerabilities
              </div>
            </div>
            <div className="grid min-height--20">
              <div className="grid__item col-3-8 text--right font-family--roboto-light font--h8 pl0">
                High
              </div>
              <div className="grid__item col-1-4 font--h8 pl-- text--bold">
                {issuesBySeverity.HIGH}
              </div>
              <div className="grid__item col-3-8 font--h8 pad-left--30 text--bold">
                {vulnsBySeverity.HIGH}
              </div>
            </div>
            <div className="grid min-height--20">
              <div className="grid__item col-3-8 text--right font-family--roboto-light font--h8 pl0">
                Medium
              </div>
              <div className="grid__item col-1-4 font--h8 pl-- text--bold">
                {issuesBySeverity.MEDIUM}
              </div>
              <div className="grid__item col-3-8 font--h8 pad-left--30 text--bold">
                {vulnsBySeverity.MEDIUM}
              </div>
            </div>
            <div className="grid min-height--20">
              <div className="grid__item col-3-8 text--right font-family--roboto-light font--h8 pl0">
                Low
              </div>
              <div className="grid__item col-1-4 font--h8 pl-- text--bold">
                {issuesBySeverity.LOW}
              </div>
              <div className="grid__item col-3-8 font--h8 pad-left--30 text--bold">
                {vulnsBySeverity.LOW}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      );

    const latestScanData = (
      <React.Fragment>
        <div className="mt--- mb--">
          <span className="text--bold">Latest Scan ID:</span>{' '}
          {isFetchingLatestScan ? (
            <i className="fas fa-spin fa-spinner color--white" />
          ) : (
            latestScanId
          )}
        </div>
        <div className="pt--">
          <span className="text--bold">Latest Scan Date:</span>{' '}
          {isFetchingLatestScan ? (
            <i className="fas fa-spin fa-spinner color--white" />
          ) : (
            latestScanDate
          )}
        </div>
      </React.Fragment>
    );

    const projectName = Helpers.formatBreakOnSlash(projectData.name);

    return (
      <div className="grid mb col-1-1">
        <div className="grid__item col-1-1 mt">
          <div className="grid__item mb col-1-1">
            <div className="grid">
              <div
                className="flex flex--align-items--flex-start grid__item col-3-5 pl0"
                data-automation-id="ProjectDetailsPage-Heading"
              >
                <div
                  className="font--h3 pr-"
                  data-automation-id="ProjectDetailsPage-Heading-ProjectLabel"
                >
                  Project
                </div>
                <div
                  className="font--h4 pb--- color--black-light mt--"
                  data-automation-id="ProjectDetailsPage-Heading-ProjectName"
                >
                  <strong>
                    {isFetchingRepoData ? (
                      <i className="fas fa-spin fa-spinner color--muted font--h5" />
                    ) : (
                      // eslint-disable-next-line react/no-danger
                      <span dangerouslySetInnerHTML={projectName} />
                    )}
                  </strong>
                </div>
                {manageProjects && (
                  <div className="ml-- flex align-self--flex-start align-items--end mb---">
                    <button
                      className="color--muted-dark"
                      onClick={isEditOpen ? this.toggleEditClosed : this.toggleEditOpen}
                    >
                      <Tooltip id={`edit-${projectId}-tooltip`} content="Rename Project">
                        <i className="sci sci__pencil" />
                      </Tooltip>
                    </button>
                  </div>
                )}
                {isContainerProject && manageProjects && (
                  <div className="flex align-self--stretch align-items--end mb---">
                    <button
                      className="color--muted-dark"
                      onClick={isDeleteOpen ? this.toggleDeleteClosed : this.toggleDeleteOpen}
                    >
                      <Tooltip id={`delete-${projectId}-tooltip`} content="Delete project">
                        <i className="sci sci__trash" />
                      </Tooltip>
                    </button>
                  </div>
                )}
              </div>
              <div
                className="flex grid__item flex--justify-content--end flex--align-items--flex-start col-2-5 pt--"
                data-automation-id="ProjectDetailsPage-Actions"
              >
                <div>
                  {!isContainerProject && (
                    <React.Fragment>
                      {manageProjects && (
                        <div
                          className="link--obvious link--no-underline pb--- pt-- mr inline-block"
                          onClick={this.showProjectSettingsModal}
                          data-automation-id="ProjectDetailsPage-Actions-Settings"
                        >
                          <span className="font--16 flex flex--align-items--center">
                            <i className="fas fa-cog pr--" /> Settings
                          </span>
                        </div>
                      )}
                      {isOrgEnterprise ? (
                        <div className="pb--- pt-- mr inline-block">
                          <WatchProject
                            projectData={projectData}
                            className="link--obvious link--no-underline font--16 flex flex--align-items--center"
                            onChange={watching => this.onWatchProjectChange(watching)}
                          />
                        </div>
                      ) : (
                        <div className="pb--- pt-- mr inline-block font--16 pb--- pt-- flex flex--align-items--center">
                          <a
                            onClick={() => this.toggleCtaWatchModalOpen(UPGRADE_MODAL_WATCH_REPO)}
                            className="position--relative ph0 hover--no-underline link--obvious link--no-underline"
                          >
                            <ProIcon />
                            Not Watching
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div
                  className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center"
                  onClick={this.toggleSidebar}
                  data-automation-id="ProjectDetailsPage-Actions-History"
                >
                  {isPaidOrTrialing ? <i className="fas fa-clock pr--" /> : <ProIcon />} Show
                  History
                </div>
              </div>
            </div>
          </div>

          <div
            className="grid__item col-1-1 pl0 mb bg-color--black-light color--white bo-rad--3"
            data-automation-id="ProjectDetailsPage-Details"
          >
            <div className="grid p-">
              <div className="grid__item col-1-1">
                <div
                  className="font--18 pb--- mb-"
                  data-automation-id="ProjectDetailsPage-Details-Heading"
                >
                  PROJECT DETAILS
                </div>
              </div>
              <div className="grid__item col-2-3">
                <div>
                  <span>
                    <RepoScopeSwitcher
                      projectId={projectId}
                      teamId={teamId}
                      activeReportType={'ISSUES'}
                      projectData={projectData}
                      projectType={projectType}
                    />
                  </span>
                </div>
              </div>
              <div className="grid__item col-1-3">
                {!isContainerProject ? ( // Display latest scan data with tooltip if non-container type
                  <Tooltip
                    place="top"
                    maxWidthClass="max-width--300"
                    content={`If the project's default branch is set, then Latest Scan ID and Latest Scan Date reflect the most recent scan in this project's default branch.`}
                    id="latest-scan-id-text"
                  >
                    {latestScanData}
                  </Tooltip>
                ) : (
                  latestScanData
                )}
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="grid__item col-1-1">
              <div
                className="font--18 col-1-1 mb---"
                data-automation-id="ProjectDetailsPage-Heading-Insights"
              >
                INSIGHTS
              </div>
              <InsightsWrapper>
                <InsightNumber
                  title={`Active, Open Issues in ${
                    isContainerProject ? 'this Tag' : 'Selected Branches'
                  }`}
                  content={insightsOpenIssuesContent}
                  isFetchingInsights={isFetchingRepoData || isFetchingStats}
                />
                <InsightHorizontalBar
                  title="Issues by Type"
                  content={issuesByType}
                  isFetchingInsights={isFetchingRepoData || isFetchingInsights}
                />
                {issuesBySeverity && vulnsBySeverity && (
                  <InsightNumber
                    title="By Severity"
                    content={insightBySeverity}
                    isFetchingInsights={isFetchingRepoData || isFetchingStats}
                  />
                )}
                <InsightNumber
                  title="Total Project Inventory"
                  content={insightsInventoryContent}
                  isFetchingInsights={isFetchingRepoData || isFetchingStats}
                />
                <InsightNumber
                  title="Risk Score"
                  content={riskScore === null ? 'N/A' : `${riskScore}/100`}
                  isFetchingInsights={isFetchingRepoData || isFetchingInsights}
                  contentClassName={this.getRiskScoreColor(riskScore)}
                  tooltip="This score is based on the quantity and risk level of the vulnerability issues in your project compared to a large sample of open-source projects."
                />
              </InsightsWrapper>
            </div>
          </div>

          <div className="grid mt">
            <div className="grid__item col-1-5" />
            <div className="grid__item col-2-5">
              <div
                className="font--h8 pb--- bo-b--1 border-color--muted-light text--center"
                data-automation-id="ProjectDetailsPage-ProjectInventoryLabel"
              >
                PROJECT INVENTORY
              </div>
            </div>
            <div className="grid__item col-2-5" />

            <div className="grid__item col-1-1 mt--">
              <div className="bo-b--3 border-color--black-light">
                <div className="grid">
                  <div className="grid__item col-1-5">
                    <div className="col-2-3">
                      <ListTab
                        label={'Issues'}
                        isActive={activeReportType === 'ISSUES'}
                        link={this.appendQueryToLink(`${baseUrl}/issues`)}
                        automationId="ListTab-Issues"
                      />
                    </div>
                  </div>
                  <div className="grid__item col-2-5">
                    <div className="grid grid--full">
                      <div className="grid__item ph0">
                        <ListTab
                          label={'Vulnerabilities'}
                          isActive={activeReportType === 'VULNERABILITIES'}
                          link={this.appendQueryToLink(`${baseUrl}/vulnerabilities`)}
                          automationId="ListTab-Vulnerabilities"
                        />
                      </div>
                      <div className="grid__item ph0 bo-l--2 border-color--white">
                        <ListTab
                          label={'Libraries'}
                          isActive={activeReportType === 'LIBRARIES'}
                          link={this.appendQueryToLink(`${baseUrl}/libraries`)}
                          automationId="ListTab-Libraries"
                        />
                      </div>
                      <div className="grid__item ph0 bo-l--2 border-color--white">
                        <ListTab
                          label={'Licenses'}
                          isActive={activeReportType === 'LICENSES'}
                          link={this.appendQueryToLink(`${baseUrl}/licenses`)}
                          automationId="ListTab-Licenses"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoaderWrapper isLoaderShowing={isRepoDataLoading}>
            <Switch children={renderRoutesInPath(routes, match.path)} />
          </LoaderWrapper>
        </div>

        <SidebarWrapper
          id={`project-scan-history`}
          title={sidebarTitleHtml}
          pullRight={true}
          sidebarClassName={`bg-color--white-medium width--400`}
          shadow={true}
        >
          <div>
            <ProjectScanHistory teamId={teamId} projectId={projectId} />
          </div>
        </SidebarWrapper>

        <ProjectSettingsModal
          teamId={teamId}
          projectId={projectId}
          projectData={projectData}
          teamState={teamState}
        />

        <SourceClearModal
          isOpen={!!openedModal[NOTIFICATIONS_TURNED_ON_MODAL]}
          title={'Notifications Enabled'}
          onClose={() => this.closeModal(NOTIFICATIONS_TURNED_ON_MODAL)}
          closeWhenClickOutside={false}
          width={500}
        >
          <div>
            We've enabled notifications for watching projects. You may disable notifications for all
            your projects in{' '}
            <Link to="/settings/notifications" className="link--primary">
              your notifications settings
            </Link>
            .
          </div>
          <div className="col-1-1 flex flex--justify-content--end align-items--center mt">
            <button
              className="ph mr- btn--success pv-"
              onClick={() => this.closeModal(NOTIFICATIONS_TURNED_ON_MODAL)}
            >
              {' '}
              Okay{' '}
            </button>
          </div>
        </SourceClearModal>
        <SourceClearModal
          isOpen={isEditOpen}
          title={'Rename Project'}
          onClose={() => this.toggleEditClosed()}
          closeWhenClickOutside={true}
          width={500}
        >
          <UpdateProjectDetailsModal closeModal={this.toggleEditClosed} projectId={projectId} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={isDeleteOpen}
          title={'Delete Project'}
          onClose={() => this.toggleDeleteClosed()}
          closeWhenClickOutside={true}
          width={500}
        >
          <p className="mt-">Are you sure you want to delete this project?</p>
          <p>
            Deleting a project is permanent and cannot be undone. When you delete a project, data
            for all scans, branches, and tags for{' '}
            <span className="text--bold">{projectData.name}</span> will be permanently deleted.
            Rescanning a repository of the same name in the future will create a new project and
            will not restore prior scans from this project.
          </p>
          <div className="flex flex--justify-content--end align-items--center mt">
            <button
              className="btn--default--clear font--h7 pv- ph mr"
              onClick={() => this.toggleDeleteClosed()}
            >
              Cancel
            </button>
            <button
              className="btn--danger font--h7 pv- ph"
              onClick={() => this.deleteRepository(projectData)}
            >
              Delete Project
            </button>
          </div>
          <div
            className={
              'grid__item col-1-1 color--danger mt- text--center' +
              (deleteRepoError ? ' is-showing-50' : ' is-hiding')
            }
          >
            Insufficent privileges to delete <span className="text--bold">{projectData.name}</span>.
            Please ask a team admin to delete.
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    insightsByType: state.insightsByType,
    repoDataById: state.repoDataById,
    messageState: state.messageState,
    modalState: state.modalState,
    orgState: state.orgState,
    popoverState: state.popoverState,
    projectStats: state.projectStats,
    projectScanHistory: state.projectScanHistory,
    repoScope: state.repoScope,
    reportScope: state.reportScope,
    sidebarState: state.sidebarState,
    statsByProjectId: state.statsByProjectId,
    teamState: state.teamState,
    navigationState: state.navigationState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    modalActions: bindActionCreators(modalActions as any, dispatch),
    popoverActions: bindActionCreators(popoverActions as any, dispatch),
    repoActions: bindActionCreators(repoActions as any, dispatch),
    reportScopeActions: bindActionCreators(reportScopeActions as any, dispatch),
    repoScopeActions: bindActionCreators(repoScopeActions as any, dispatch),
    sidebarActions: bindActionCreators(sidebarActions as any, dispatch),
    projectSettingsActions: bindActionCreators(projectSettingsActions as any, dispatch),
    projectScanHistoryActions: bindActionCreators(projectScanHistoryActions as any, dispatch),
    toastrActions: bindActionCreators(toastrActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
    userNotificationsActions: bindActionCreators(userNotificationsActions as any, dispatch),
    workspaceScanDateActions: bindActionCreators(workspaceScanDateActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsPage));
