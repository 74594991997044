import githubSrc from '~/images/github.png';
import gitSrc from '~/images/gitserver.png';
import bitbucketSrc from '~/images/bitbucket.png';
import bambooSrc from '~/images/bamboo.png';
import cliSrc from '~/images/commandline.png';
import mavenSrc from '~/images/maven.png';
import mavenSquareSrc from '~/images/maven-sq.png';
import gradleSrc from '~/images/gradle.png';
import jenkinsSrc from '~/images/jenkins.png';
import travisSrc from '~/images/travis.png';
import circleSrc from '~/images/circleci.png';
import codeshipSrc from '~/images/codeship.png';
import osxSrc from '~/images/apple.png';
import linuxSrc from '~/images/linux.png';
import windowsSrc from '~/images/windows.png';
import { Region } from '~/components/RegionState.types';

/** The number of minutes before inactivity timer deletes the local credentials. */
export const LOGOUT_MINUTES = 30;
/** The number of <b>milliseconds</b> represented by {@link #LOGOUT_MINUTES}. */
export const LOGOUT_TIME = LOGOUT_MINUTES * 60 * 1000;

/** The regex one can apply to the window.location.hash to obtain any session-id that is present. */
export const SESSION_ID_HASH_RE = /session=([0-9A-Fa-f-]{32,36})/;

export const PROXY_CONNECTED_GRACE_PERIOD = 300000;

export const UNRECOGNIZED_LICENSE_RISK = 'UNRECOGNIZED';

export const DEFAULT_WORKSPACE_SCAN_DATE = 'last_30_days';
export const WORKSPACE_SCAN_DATE_ALL_DATES = 'all_dates';
export const WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS = 'more_than_7_days';
export const WORKSPACE_SCAN_DATE_LAST_7_DAYS = 'last_7_days';

export const downloadOptions = [
  {
    name: 'curl',
    commands: [
      {
        title: 'In your terminal, enter:',
        value: 'curl -sSL https://www.sourceclear.com/install | sh',
      },
    ],
  },
  {
    name: 'apt-get',
    commands: [
      {
        title: 'Retrieve and install our GPG signing key:',
        value: 'sudo apt-key adv --keyserver keyserver.ubuntu.com --recv-keys DF7DD7A50B746DD4',
      },
      {
        title: 'Add srcclr to your apt repo list:',
        value: 'sudo add-apt-repository "deb https://download.srcclr.com/ubuntu stable/"',
      },
      { title: 'Update and install:', value: 'sudo apt-get update' },
      { value: 'sudo apt-get install srcclr' },
    ],
  },
  {
    name: 'apk',
    commands: [
      {
        title: 'Retrieve and install our RSA signing key:',
        value:
          'sudo wget -P /etc/apk/keys https://download.srcclr.com/alpine/public-keys/production@srcclr.com-5e266d90.rsa.pub',
      },
      {
        title: 'Add srcclr to your apk repo list:',
        value: 'sudo sh -c "echo https://download.srcclr.com/alpine/main >> /etc/apk/repositories"',
      },
      { title: 'Install:', value: 'sudo apk add srcclr' },
    ],
  },
  {
    name: 'homebrew',
    commands: [
      {
        title: 'In your terminal, run these commands:',
        value: 'brew tap veracode/srcclr',
      },
      { value: 'brew install srcclr' },
    ],
  },
  {
    name: 'chocolatey',
    commands: [
      {
        title: 'Run the following command in your PowerShell/cmd then restart your terminal:',
        value: 'choco install srcclr',
      },
    ],
  },
];

export const upgradeOptions = [
  {
    name: 'curl',
    commands: [{ value: 'curl -sSL https://www.sourceclear.com/install | sh' }],
  },
  {
    name: 'apt-get',
    commands: [
      { title: 'Update and install:', value: 'sudo apt-get update' },
      { value: 'sudo apt-get upgrade' },
    ],
  },
  {
    name: 'apk',
    commands: [
      { title: 'Update and install:', value: 'sudo apk update' },
      { value: 'sudo apk add --upgrade srcclr' },
    ],
  },
  {
    name: 'homebrew',
    commands: [{ value: 'brew update && brew upgrade srcclr' }],
  },
  {
    name: 'chocolatey',
    commands: [{ value: 'choco upgrade srcclr' }],
  },
  {
    name: 'powershell',
    commands: [
      {
        value: `Set-ExecutionPolicy AllSigned -Scope Process -Force; iex ((New-Object System.Net.WebClient).DownloadString('https://download.srcclr.com/ci.ps1'))`,
      },
    ],
  },
];

export const agentTokenOptions = {
  github: { agentType: 'CLI', scmType: 'GITHUB' },
  ghe: { agentType: 'CLI', scmType: 'GITHUBENTERPRISE' },
  bitbucket: { agentType: 'CLI', scmType: 'BITBUCKET' },
  gitserver: { agentType: 'CLI', scmType: 'GIT' },
  jenkins: { agentType: 'JENKINS', scmType: 'GIT' },
  gradle: { agentType: 'GRADLE', scmType: 'GIT' },
  maven: { agentType: 'MAVEN', scmType: 'GIT' },
  travis: { agentType: 'TRAVIS', scmType: 'GIT' },
  windows: { agentType: 'WINDOWSCI', scmType: 'GIT' },
  circle: { agentType: 'CIRCLECI', scmType: 'GIT' },
  codeship: { agentType: 'CODESHIP', scmType: 'GIT' },
  bamboo: { agentType: 'BAMBOO', scmType: 'GIT' },
  pipelines: { agentType: 'PIPELINES', scmType: 'GIT' },
  cli: { agentType: 'CLI' },
};

export const agentTypeImageMap = {
  GITHUB: githubSrc,
  GIT: gitSrc,
  GHE: githubSrc,
  GITHUBENTERPRISE: githubSrc,
  BITBUCKET: bitbucketSrc,
  BAMBOO: bambooSrc,
  CLI: cliSrc,
  MAVEN: mavenSrc,
  MAVENSQ: mavenSquareSrc,
  GRADLE: gradleSrc,
  JENKINS: jenkinsSrc,
  TRAVISCI: travisSrc,
  CIRCLECI: circleSrc,
  CODESHIP: codeshipSrc,
  OSX: osxSrc,
  LINUX: linuxSrc,
  WINDOWS: windowsSrc,
};

export const agentWorkspaceSelectionInstructions = {
  TRAVISCI: {
    header: 'Add workspace environment variable',
    body:
      'In the Environment Variables page above, add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  CIRCLECI: {
    header: 'Add workspace environment variable',
    body:
      'In the Environment Variables page above, add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  JENKINS: {
    header: 'Add workspace environment variable',
    body:
      'Add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  CODESHIP: {
    header: 'Add workspace environment variable',
    body:
      'In the Environment Variables page above, add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  BAMBOO: {
    header: 'Add workspace environment variable',
    body:
      'In the Variables page above, add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  BITBUCKET: {
    header: 'Add workspace environment variable',
    body:
      'In the Environment variables page above, add an environment variable <code>SRCCLR_WORKSPACE_SLUG</code> and set it to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  MAVEN: {
    header: 'Add workspace environment variable',
    body:
      'Set the environment variable <code>SRCCLR_WORKSPACE_SLUG</code> to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
  GRADLE: {
    header: 'Add workspace environment variable',
    body:
      'Set the environment variable <code>SRCCLR_WORKSPACE_SLUG</code> to the target workspace slug above. Scan results for this agent will be sent to the target workspace.',
  },
};

export const languagePrettifiedNames = {
  JS: 'JavaScript',
  JAVASCRIPT: 'JavaScript',
  RUBY: 'Ruby',
  JAVA: 'Java',
  PYTHON: 'Python',
  OBJECTIVEC: 'Objective-C',
  SWIFT: 'Swift',
  CPP: 'C/C++',
  GO: 'GO',
  PHP: 'PHP',
  CSHARP: '.NET',
  OS: 'OS',
};

export const integrationPrettyNameMap = {
  ghe: 'GitHub Enterprise',
  github: 'GitHub.com',
  bitbucket: 'Bitbucket Server',
  gitserver: 'Git Server',
  jenkins: 'Jenkins / Hudson',
  gradle: 'Gradle',
  maven: 'Maven',
  cli: 'Command Line Interface',
  travis: 'Travis CI',
  circleci: 'Circle CI',
  bamboo: 'Atlassian Bamboo',
  codeship: 'CodeShip',
  pipelines: 'Bitbucket Pipelines',
  windowsci: 'Windows',
};

export enum IssueTypePretty {
  VULN = 'Vulnerability',
  OUT_OF_DATE = 'Outdated Library',
  LICENSE = 'License',
}

export const integrationOptions = [
  { title: 'Travis CI', routeName: 'travis', imageEnum: 'TRAVISCI', description: '' },
  { title: 'Jenkins / Hudson', routeName: 'jenkins', imageEnum: 'JENKINS', description: '' },
  {
    title: 'Bitbucket Pipelines',
    routeName: 'pipelines',
    imageEnum: 'BITBUCKET',
    description: '',
  },
  { title: 'Circle CI', routeName: 'circle', imageEnum: 'CIRCLECI', description: '' },
  { title: 'CodeShip', routeName: 'codeship', imageEnum: 'CODESHIP', description: '' },
  { title: 'Atlassian Bamboo', routeName: 'bamboo', imageEnum: 'BAMBOO', description: '' },
  { title: 'Maven', routeName: 'maven', imageEnum: 'MAVENSQ', description: '' },
  { title: 'Gradle', routeName: 'gradle', imageEnum: 'GRADLE', description: '' },
];

export const defaultOSCliMap = {
  LINUX: 'apt-get',
  WINDOWS: 'chocolatey',
};

export const vulnMethodsSupportedLanguages = ['PYTHON', 'JAVA', 'RUBY', 'JS', 'CSHARP'];

export const vulnMethodsSupportedCoordTypes = ['PYPI', 'MAVEN', 'GEM', 'NPM', 'NUGET'];

export const messageTextMap = {
  ActivationAttempt: 'Agent activation started',
  AgentActivated: 'Agent activation complete',
  AgentConnectionEstablished: 'Agent connected',
  AgentConnectionClosed: 'Agent disconnected',
  ResourceAcquisitionStart: 'Cloning repo',
  ImportNewReposRequest: 'Received import new repos request',
};

export const messageWithRepoTextMap = {
  ResourceAcquisitionEnd: 'Finished cloning repo',
  ResourceAcquisitionFailure: 'Unable to clone repo',
  ReceivedVulnerableMethods: 'Saved vulnerable method data',
  ReceivedProjectEvidence: 'Saved scan data',
  VulnerableMethodsScanStart: 'Scanning vulnerable methods',
  VulnerableMethodsScanEnd: 'Finished scanning vulnerable methods',
  LineCountingStart: 'Counting lines of code',
  LineCountingEnd: 'Finished counting lines of code',
  EvidenceCollectionStart: 'Determining libraries',
  EvidenceCollectionEnd: 'Finished determining libraries',
  EvidenceMatchingFailure: 'Unable to determine libraries',
  EngineConfigurationStart: 'Initializing scan engine',
  EngineConfigurationEnd: 'Scan engine initialized',
  WebhookReceived: 'Received webhook event',
  WebhookAgentOffline: 'Ignoring webhook event due to offline agent',
  ScanRepoRequest: 'Received scan request',
  ScanAllReposRequest: 'Received scan all repos request',
  ScanDataPersisted: 'Scan data saved',
};

export const errorMessageTextMap = {
  EvidenceCollectionStart: 'Error collecting libraries',
  orgNameError:
    'Must be between 3 and 20 characters, start with a letter, and include only letters, numbers, spaces, underscores, or dashes.',
  repoNameError:
    'Must be between 3 and 40 characters, start with a letter or number, and include only letters, numbers, underscores, dashes, at sign (@), colon, period, and forward slashes.',
  workspaceNameError:
    'Must be between 3 and 20 characters, start with a letter, and include only letters, numbers, spaces, underscores, or dashes.',
  teamNameError:
    'Must be between 3 and 20 characters, start with a letter or number, and include only letters, numbers, underscores, and dashes.',
  agentNameError:
    'Must be between 3 and 20 characters, start with a letter or number, and include only letters, numbers, underscores, and dashes.',
};

export const countryCodes = [
  {
    country: 'United States of America (+1)',
    code: '1',
  },
  {
    country: 'Canada (+1)',
    code: '1',
  },
  {
    country: 'Russia (+7)',
    code: '7',
  },
  {
    country: 'Kazakhstan (+7)',
    code: '7',
  },
  {
    country: 'Egypt (+20)',
    code: '20',
  },
  {
    country: 'South Africa (+27)',
    code: '27',
  },
  {
    country: 'Greece (+30)',
    code: '30',
  },
  {
    country: 'Netherlands (+31)',
    code: '31',
  },
  {
    country: 'Belgium (+32)',
    code: '32',
  },
  {
    country: 'France (+33)',
    code: '33',
  },
  {
    country: 'Spain (+34)',
    code: '34',
  },
  {
    country: 'Hungary (+36)',
    code: '36',
  },
  {
    country: 'Italy (+39)',
    code: '39',
  },
  {
    country: 'Romania (+40)',
    code: '40',
  },
  {
    country: 'Switzerland (+41)',
    code: '41',
  },
  {
    country: 'Austria (+43)',
    code: '43',
  },
  {
    country: 'United Kingdom (+44)',
    code: '44',
  },
  {
    country: 'Guernsey (+44)',
    code: '44',
  },
  {
    country: 'Isle of Man (+44)',
    code: '44',
  },
  {
    country: 'Jersey (+44)',
    code: '44',
  },
  {
    country: 'Denmark (+45)',
    code: '45',
  },
  {
    country: 'Sweden (+46)',
    code: '46',
  },
  {
    country: 'Norway (+47)',
    code: '47',
  },
  {
    country: 'Poland (+48)',
    code: '48',
  },
  {
    country: 'Germany (+49)',
    code: '49',
  },
  {
    country: 'Peru (+51)',
    code: '51',
  },
  {
    country: 'Mexico (+52)',
    code: '52',
  },
  {
    country: 'Cuba (+53)',
    code: '53',
  },
  {
    country: 'Argentina (+54)',
    code: '54',
  },
  {
    country: 'Brazil (+55)',
    code: '55',
  },
  {
    country: 'Chile (+56)',
    code: '56',
  },
  {
    country: 'Colombia (+57)',
    code: '57',
  },
  {
    country: 'Venezuela (+58)',
    code: '58',
  },
  {
    country: 'Malaysia (+60)',
    code: '60',
  },
  {
    country: 'Australia (+61)',
    code: '61',
  },
  {
    country: 'Indonesia (+62)',
    code: '62',
  },
  {
    country: 'Philippines (+63)',
    code: '63',
  },
  {
    country: 'New Zealand (+64)',
    code: '64',
  },
  {
    country: 'Singapore (+65)',
    code: '65',
  },
  {
    country: 'Thailand (+66)',
    code: '66',
  },
  {
    country: 'Japan (+81)',
    code: '81',
  },
  {
    country: 'Korea (+South) (+82)',
    code: '82',
  },
  {
    country: 'Vietnam (+84)',
    code: '84',
  },
  {
    country: 'China (+86)',
    code: '86',
  },
  {
    country: 'Turkey (+90)',
    code: '90',
  },
  {
    country: 'India (+91)',
    code: '91',
  },
  {
    country: 'Pakistan (+92)',
    code: '92',
  },
  {
    country: 'Afghanistan (+93)',
    code: '93',
  },
  {
    country: 'Sri Lanka (+94)',
    code: '94',
  },
  {
    country: 'Myanmar (+95)',
    code: '95',
  },
  {
    country: 'Iran (+98)',
    code: '98',
  },
  {
    country: 'Morocco (+212)',
    code: '212',
  },
  {
    country: 'Algeria (+213)',
    code: '213',
  },
  {
    country: 'Tunisia (+216)',
    code: '216',
  },
  {
    country: 'Libya (+218)',
    code: '218',
  },
  {
    country: 'Gambia (+220)',
    code: '220',
  },
  {
    country: 'Senegal (+221)',
    code: '221',
  },
  {
    country: 'Mauritania (+222)',
    code: '222',
  },
  {
    country: 'Mali Republic (+223)',
    code: '223',
  },
  {
    country: 'Guinea (+224)',
    code: '224',
  },
  {
    country: 'Ivory Coast (+225)',
    code: '225',
  },
  {
    country: 'Burkina Faso (+226)',
    code: '226',
  },
  {
    country: 'Niger (+227)',
    code: '227',
  },
  {
    country: 'Togo (+228)',
    code: '228',
  },
  {
    country: 'Benin (+229)',
    code: '229',
  },
  {
    country: 'Mauritius (+230)',
    code: '230',
  },
  {
    country: 'Liberia (+231)',
    code: '231',
  },
  {
    country: 'Sierra Leone (+232)',
    code: '232',
  },
  {
    country: 'Ghana (+233)',
    code: '233',
  },
  {
    country: 'Nigeria (+234)',
    code: '234',
  },
  {
    country: 'Chad (+235)',
    code: '235',
  },
  {
    country: 'Central African Republic (+236)',
    code: '236',
  },
  {
    country: 'Cameroon (+237)',
    code: '237',
  },
  {
    country: 'Cape Verde Islands (+238)',
    code: '238',
  },
  {
    country: 'Sao Tome and Principe (+239)',
    code: '239',
  },
  {
    country: 'Gabon (+241)',
    code: '241',
  },
  {
    country: 'Congo, Democratic Republ (+243)',
    code: '243',
  },
  {
    country: 'Angola (+244)',
    code: '244',
  },
  {
    country: 'Guinea-Bissau (+245)',
    code: '245',
  },
  {
    country: 'Seychelles (+248)',
    code: '248',
  },
  {
    country: 'Sudan (+249)',
    code: '249',
  },
  {
    country: 'Rwanda (+250)',
    code: '250',
  },
  {
    country: 'Ethiopia (+251)',
    code: '251',
  },
  {
    country: 'Somalia (+252)',
    code: '252',
  },
  {
    country: 'Djibouti (+253)',
    code: '253',
  },
  {
    country: 'Kenya (+254)',
    code: '254',
  },
  {
    country: 'Tanzania (+255)',
    code: '255',
  },
  {
    country: 'Uganda (+256)',
    code: '256',
  },
  {
    country: 'Burundi (+257)',
    code: '257',
  },
  {
    country: 'Mozambique (+258)',
    code: '258',
  },
  {
    country: 'Zambia (+260)',
    code: '260',
  },
  {
    country: 'Madagascar (+261)',
    code: '261',
  },
  {
    country: 'Reunion (+262)',
    code: '262',
  },
  {
    country: 'Zimbabwe (+263)',
    code: '263',
  },
  {
    country: 'Namibia (+264)',
    code: '264',
  },
  {
    country: 'Malawi (+265)',
    code: '265',
  },
  {
    country: 'Lesotho (+266)',
    code: '266',
  },
  {
    country: 'Botswana (+267)',
    code: '267',
  },
  {
    country: 'Swaziland (+268)',
    code: '268',
  },
  {
    country: 'Mayotte Island (+269)',
    code: '269',
  },
  {
    country: 'Aruba (+297)',
    code: '297',
  },
  {
    country: 'Faroe Islands (+298)',
    code: '298',
  },
  {
    country: 'Greenland (+299)',
    code: '299',
  },
  {
    country: 'Gibraltar (+350)',
    code: '350',
  },
  {
    country: 'Portugal (+351)',
    code: '351',
  },
  {
    country: 'Luxembourg (+352)',
    code: '352',
  },
  {
    country: 'Ireland (+353)',
    code: '353',
  },
  {
    country: 'Iceland (+354)',
    code: '354',
  },
  {
    country: 'Albania (+355)',
    code: '355',
  },
  {
    country: 'Malta (+356)',
    code: '356',
  },
  {
    country: 'Cyprus (+357)',
    code: '357',
  },
  {
    country: 'Finland (+358)',
    code: '358',
  },
  {
    country: 'Bulgaria (+359)',
    code: '359',
  },
  {
    country: 'Lithuania (+370)',
    code: '370',
  },
  {
    country: 'Latvia (+371)',
    code: '371',
  },
  {
    country: 'Estonia (+372)',
    code: '372',
  },
  {
    country: 'Moldova (+373)',
    code: '373',
  },
  {
    country: 'Armenia (+374)',
    code: '374',
  },
  {
    country: 'Belarus (+375)',
    code: '375',
  },
  {
    country: 'Andorra (+376)',
    code: '376',
  },
  {
    country: 'Monaco (+377)',
    code: '377',
  },
  {
    country: 'San Marino (+378)',
    code: '378',
  },
  {
    country: 'Ukraine (+380)',
    code: '380',
  },
  {
    country: 'Serbia (+381)',
    code: '381',
  },
  {
    country: 'Montenegro (+382)',
    code: '382',
  },
  {
    country: 'Croatia (+385)',
    code: '385',
  },
  {
    country: 'Slovenia (+386)',
    code: '386',
  },
  {
    country: 'Bosnia-Herzegovina (+387)',
    code: '387',
  },
  {
    country: 'Macedonia (+389)',
    code: '389',
  },
  {
    country: 'Czech Republic (+420)',
    code: '420',
  },
  {
    country: 'Slovakia (+421)',
    code: '421',
  },
  {
    country: 'Liechtenstein (+423)',
    code: '423',
  },
  {
    country: 'Falkland Islands (+500)',
    code: '500',
  },
  {
    country: 'Belize (+501)',
    code: '501',
  },
  {
    country: 'Guatemala (+502)',
    code: '502',
  },
  {
    country: 'El Salvador (+503)',
    code: '503',
  },
  {
    country: 'Honduras (+504)',
    code: '504',
  },
  {
    country: 'Nicaragua (+505)',
    code: '505',
  },
  {
    country: 'Costa Rica (+506)',
    code: '506',
  },
  {
    country: 'Panama (+507)',
    code: '507',
  },
  {
    country: 'Haiti (+509)',
    code: '509',
  },
  {
    country: 'Guadeloupe (+590)',
    code: '590',
  },
  {
    country: 'Bolivia (+591)',
    code: '591',
  },
  {
    country: 'Guyana (+592)',
    code: '592',
  },
  {
    country: 'Ecuador (+593)',
    code: '593',
  },
  {
    country: 'French Guiana (+594)',
    code: '594',
  },
  {
    country: 'Paraguay (+595)',
    code: '595',
  },
  {
    country: 'Martinique (+596)',
    code: '596',
  },
  {
    country: 'Suriname (+597)',
    code: '597',
  },
  {
    country: 'Uruguay (+598)',
    code: '598',
  },
  {
    country: 'Netherlands Antilles (+599)',
    code: '599',
  },
  {
    country: 'Timor-Leste (+670)',
    code: '670',
  },
  {
    country: 'Guam (+1671)',
    code: '1671',
  },
  {
    country: 'Brunei (+673)',
    code: '673',
  },
  {
    country: 'Nauru (+674)',
    code: '674',
  },
  {
    country: 'Papua New Guinea (+675)',
    code: '675',
  },
  {
    country: 'Tonga (+676)',
    code: '676',
  },
  {
    country: 'Solomon Islands (+677)',
    code: '677',
  },
  {
    country: 'Vanuatu (+678)',
    code: '678',
  },
  {
    country: 'Fiji Islands (+679)',
    code: '679',
  },
  {
    country: 'Cook Islands (+682)',
    code: '682',
  },
  {
    country: 'Samoa (+685)',
    code: '685',
  },
  {
    country: 'New Caledonia (+687)',
    code: '687',
  },
  {
    country: 'French Polynesia (+689)',
    code: '689',
  },
  {
    country: 'Korea (+North) (+850)',
    code: '850',
  },
  {
    country: 'HongKong (+852)',
    code: '852',
  },
  {
    country: 'Macau (+853)',
    code: '853',
  },
  {
    country: 'Cambodia (+855)',
    code: '855',
  },
  {
    country: 'Laos (+856)',
    code: '856',
  },
  {
    country: 'Bangladesh (+880)',
    code: '880',
  },
  {
    country: 'International (+882)',
    code: '882',
  },
  {
    country: 'Taiwan (+886)',
    code: '886',
  },
  {
    country: 'Maldives (+960)',
    code: '960',
  },
  {
    country: 'Lebanon (+961)',
    code: '961',
  },
  {
    country: 'Jordan (+962)',
    code: '962',
  },
  {
    country: 'Syria (+963)',
    code: '963',
  },
  {
    country: 'Iraq (+964)',
    code: '964',
  },
  {
    country: 'Kuwait (+965)',
    code: '965',
  },
  {
    country: 'Saudi Arabia (+966)',
    code: '966',
  },
  {
    country: 'Yemen (+967)',
    code: '967',
  },
  {
    country: 'Oman (+968)',
    code: '968',
  },
  {
    country: 'Palestine (+970)',
    code: '970',
  },
  {
    country: 'United Arab Emirates (+971)',
    code: '971',
  },
  {
    country: 'Israel (+972)',
    code: '972',
  },
  {
    country: 'Bahrain (+973)',
    code: '973',
  },
  {
    country: 'Qatar (+974)',
    code: '974',
  },
  {
    country: 'Bhutan (+975)',
    code: '975',
  },
  {
    country: 'Mongolia (+976)',
    code: '976',
  },
  {
    country: 'Nepal (+977)',
    code: '977',
  },
  {
    country: 'Tajikistan (+992)',
    code: '992',
  },
  {
    country: 'Turkmenistan (+993)',
    code: '993',
  },
  {
    country: 'Azerbaijan (+994)',
    code: '994',
  },
  {
    country: 'Georgia (+995)',
    code: '995',
  },
  {
    country: 'Kyrgyzstan (+996)',
    code: '996',
  },
  {
    country: 'Uzbekistan (+998)',
    code: '998',
  },
  {
    country: 'Bahamas (+1242)',
    code: '1242',
  },
  {
    country: 'Barbados (+1246)',
    code: '1246',
  },
  {
    country: 'Anguilla (+1264)',
    code: '1264',
  },
  {
    country: 'Antigua and Barbuda (+1268)',
    code: '1268',
  },
  {
    country: 'Virgin Islands, British (+1284)',
    code: '1284',
  },
  {
    country: 'Cayman Islands (+1345)',
    code: '1345',
  },
  {
    country: 'Bermuda (+1441)',
    code: '1441',
  },
  {
    country: 'Grenada (+1473)',
    code: '1473',
  },
  {
    country: 'Turks and Caicos Islands (+1649)',
    code: '1649',
  },
  {
    country: 'Montserrat (+1664)',
    code: '1664',
  },
  {
    country: 'Saint Lucia (+1758)',
    code: '1758',
  },
  {
    country: 'Dominica (+1767)',
    code: '1767',
  },
  {
    country: 'St. Vincent and The Gren (+1784)',
    code: '1784',
  },
  {
    country: 'Puerto Rico (+1787)',
    code: '1787',
  },
  {
    country: 'Dominican Republic (+1809)',
    code: '1809',
  },
  {
    country: 'Dominican Republic2 (+1829)',
    code: '1829',
  },
  {
    country: 'Dominican Republic3 (+1849)',
    code: '1849',
  },
  {
    country: 'Trinidad and Tobago (+1868)',
    code: '1868',
  },
  {
    country: 'Saint Kitts and Nevis (+1869)',
    code: '1869',
  },
  {
    country: 'Jamaica (+1876)',
    code: '1876',
  },
  {
    country: 'Congo (+242)',
    code: '242',
  },
];

export const packageManagerTypeMap = {
  bower: 'Bower',
  gem: 'RubyGems',
  maven: 'Maven',
  npm: 'npm',
  pypi: 'PyPI',
  cocoapods: 'CocoaPods',
};

export const reduxApiMap = {
  LIBRARIES: {
    apiPath: 'libraries',
    contentKey: 'libraries',
    statsKey: 'libraries',
  },
  UNMATCHED_LIBRARIES: {
    apiPath: 'libraries/unmatched',
    contentKey: 'libraries',
    statsKey: 'libraries',
  },
  VULNERABILITIES: {
    apiPath: 'vulns',
    contentKey: 'vulns',
    statsKey: 'vulnerabilities',
  },
  LICENSES: {
    apiPath: 'licenses',
    contentKey: 'licenses',
    statsKey: 'licenses',
  },
  REPOSITORIES: { apiPath: 'repos', contentKey: 'repos', statsKey: 'repos' },
  ISSUES: { apiPath: 'issues', contentKey: 'issues', statsKey: 'issues' },
  ISSUES_SUPPRESSED: {
    apiPath: 'issues/suppressed',
    contentKey: 'issues',
    statsKey: 'issues',
  },
  PROJECT_SCANS: {
    apiPath: 'scans',
    contentKey: 'repoScans',
    statsKey: 'scans',
  },
  WORKSPACES: {
    apiPath: 'teams',
    contentKey: 'orgReportTeamList',
    statsKey: 'workspaces',
  },
};

export const orgLevelReports = ['WORKSPACES'];
export const teamLevelReports = [
  'LIBRARIES',
  'VULNERABILITIES',
  'LICENSES',
  'REPOSITORIES',
  'ISSUES',
  'ISSUE_SUPPRESSED',
  'PROJECT_SCANS',
];

export const reportCsvMap = {
  ISSUES: {
    fileName: 'issues',
    fields: [
      { value: 'id', label: 'Issue ID' },
      { value: 'type', label: 'Issue type' },
      { value: 'suppressed', label: 'Ignored' },
      { value: 'status', label: 'Status' },
      { value: 'repoId', label: 'Project ID' },
      { value: 'libraryName', label: 'Library' },
      { value: 'libraryVersion', label: 'Version in use' },
      { value: 'libraryReleaseDate', label: 'Library release date' },
      { value: 'coordinateType', label: 'Package manager' },
      { value: 'coord1', label: 'Coordinate 1' },
      { value: 'coord2', label: 'Coordinate 2' },
      { value: 'latestVersion', label: 'Latest version' },
      { value: 'latestReleaseDate', label: 'Latest release date' },
      { value: 'repoName', label: 'Project' },
      { value: 'branch', label: 'Branch' },
      { value: 'tag', label: 'Tag' },
      { value: 'openedScanId', label: 'Issue opened: Scan ID' },
      { value: 'openedScanDate', label: 'Issue opened: Scan date' },
      { value: 'fixedScanId', label: 'Issue fixed: Scan ID' },
      { value: 'fixedScanDate', label: 'Issue fixed: Scan date' },
      { value: 'dependencyMode', label: 'Dependency (Transitive or Direct)' },
      { value: 'scanId', label: 'Scan' },
      { value: 'scanDate', label: 'Scan date' },
      { value: 'artifactId', label: 'Vulnerability ID' },
      { value: 'title', label: 'Title' },
      { value: 'cvss', label: 'CVSS score' },
      { value: 'severity', label: 'Severity' },
      { value: 'cve', label: 'CVE' },
      { value: 'cveStatus', label: 'Public or Veracode Customer Access' },
      { value: 'disclosureDate', label: 'Disclosure date' },
      { value: 'hasVulnMethods', label: 'Has vulnerable methods' },
      { value: 'vulnMethods', label: 'Number of vulnerable methods' },
      { value: 'updatedReleaseDate', label: 'Updated release date' },
      { value: 'releaseDate', label: 'Release date' },
      { value: 'updatedVersion', label: 'Updated Version' },
      { value: 'license', label: 'License' },
    ],
  },
  LIBRARIES: {
    fileName: 'library',
    fields: [
      { label: 'Library', value: 'name' },
      { label: 'Version', value: 'version' },
      { label: 'Latest version', value: 'latestVersion' },
      { label: 'Out of date', value: 'outOfDate' },
      { label: 'Language', value: 'languageType' },
      { label: 'Package Manager', value: 'coordinateType' },
      { label: 'Coordinate 1', value: 'coord1' },
      { label: 'Coordinate 2', value: 'coord2' },
      { label: 'Total vulnerabilities', value: 'totalArtifactCount' },
      { label: 'High risk vulnerability', value: 'highArtifactCount' },
      { label: 'Medium risk vulnerability', value: 'mediumArtifactCount' },
      { label: 'Low risk vulnerability', value: 'lowArtifactCount' },
      { label: 'Dependency (Transitive or Direct)', value: 'dependencyMode' },
      { label: 'Projects count', value: 'repos' },
      { label: 'Licenses', value: 'licenses' },
    ],
  },
  VULNERABILITIES: {
    fileName: 'security',
    fields: [
      { label: 'Severity', value: 'severity' },
      { label: 'CVSS Score', value: 'cvss' },
      { label: 'Vulnerability', value: 'title' },
      { label: 'Library', value: 'libraryName' },
      { label: 'Coordinate 1', value: 'coord1' },
      { label: 'Coordinate 2', value: 'coord2' },
      { label: 'Version', value: 'libraryVersion' },
      { label: 'Vulnerable Methods', value: 'vulnMethods' },
      { label: 'Projects', value: 'repos' },
      { label: 'Disclosure date', value: 'disclosureDate' },
      { label: 'CVE', value: 'cve' },
    ],
  },
  LICENSES: {
    fileName: 'license',
    fields: [
      { label: 'License', value: 'name' },
      { label: 'Libraries', value: 'libraries' },
      { label: 'Projects', value: 'repos' },
    ],
  },
  REPOSITORIES: {
    fileName: 'projects',
    fields: [
      { label: 'Project', value: 'name' },
      { label: 'Language/OS', value: 'language' },
      { label: 'Vulnerability Issues', value: 'vulnIssues' },
      { label: 'Outdated Library Issues', value: 'libraryIssues' },
      { label: 'License Issues', value: 'licenseIssues' },
      { label: 'Last scan', value: 'lastScanDate' },
    ],
  },
  WORKSPACES: {
    fileName: 'workspaces',
    fields: [
      { label: 'Workspace', value: 'name' },
      { label: 'Total Projects', value: 'repoCount' },
      { label: 'Vulnerability Issues', value: 'vulnIssues' },
      { label: 'Library Issues', value: 'libraryIssues' },
      { label: 'License Issues', value: 'licenseIssues' },
      { label: 'Users', value: 'numUsers' },
      { label: 'Teams', value: 'numGroups' },
      { label: 'Last Scan', value: 'lastScanDate' },
    ],
  },
  UNMATCHED_LIBRARIES: {
    fileName: 'unmatched_library',
    fields: [
      { label: 'Coordinate 1', value: 'coord1' },
      { label: 'Coordinate 2', value: 'coord2' },
      { label: 'Version', value: 'libraryVersion' },
      { label: 'File Name', value: 'filename' },
      { label: 'File Path', value: 'filePath' },
    ],
  },
};

export const PRO_TEAM = 'pro';
export const NON_PAID_TEAM = 'open';

export const UPGRADE_MODAL_VULN_METHODS = 'UPGRADE_MODAL_VULN_METHODS';
export const UPGRADE_MODAL_PREMIUM_VULN = 'UPGRADE_MODAL_PREMIUM_VULN';
export const UPGRADE_MODAL_CREATE_ISSUE = 'UPGRADE_MODAL_CREATE_ISSUE';
export const UPGRADE_MODAL_DOWNLOAD_CSV = 'UPGRADE_MODAL_DOWNLOAD_CSV';
export const UPGRADE_MODAL_CREATE_INTEGRATION = 'UPGRADE_MODAL_CREATE_INTEGRATION';
export const UPGRADE_MODAL_SCAN_HISTORY = 'UPGRADE_MODAL_SCAN_HISTORY';
export const UPGRADE_MODAL_WATCH_REPO = 'UPGRADE_MODAL_WATCH_REPO';

export const UPGRADE_MODAL_CONTENT = {
  UPGRADE_MODAL_VULN_METHODS: {
    title: 'Reduce false positives with vulnerable methods',
    paragraph:
      'Find out which line of code is calling a vulnerable method from a vulnerable library.',
    subParagraph:
      'Upgrade to access this and other premium features. Get higher scan counts, access premium vulnerability data, view previous scan results, and more.',
    bullet: [],
  },
  UPGRADE_MODAL_PREMIUM_VULN: {
    title: 'Unlock premium security data',
    paragraph: 'Access unique security data generated by our security research team.',
    subParagraph:
      'Upgrade to access this and other premium features. Get higher scan counts, reduce false positives with vulnerable methods, view previous scan results, and more.',
    bullet: [],
  },
  UPGRADE_MODAL_CREATE_INTEGRATION: {
    title: 'Integrate with developer tools to resolve vulnerabilities',
    paragraph: 'Create Jira or GitHub Issues to collaborate with developers.',
    subParagraph:
      'Upgrade to access this and other premium features. Get higher scan counts, access premium vulnerability data, view previous scan results, and more.',
    bullet: [],
  },
  UPGRADE_MODAL_CREATE_ISSUE: {
    title: 'Integrate with developer tools to resolve vulnerabilities',
    paragraph: 'Create Jira or GitHub Issues to collaborate with developers.',
    subParagraph:
      'Upgrade to access this and other premium features. Get higher scan counts, access premium vulnerability data, view previous scan results, and more.',
    bullet: [],
  },
  UPGRADE_MODAL_DOWNLOAD_CSV: {
    title: 'Export data',
    paragraph: 'Share data with your team and perform your own analysis.',
    subParagraph:
      'Upgrade to access this and other premium features. Get higher scan counts, access premium vulnerability data, view previous scan results, and more.',
    bullet: [],
  },
  UPGRADE_MODAL_WATCH_REPO: {
    planType: 'ENTERPRISE',
    title: 'Watch this Project',
    paragraph: 'Watch your projects and be notified when an issue has been discovered.', //TODO:revise copy
    subParagraph:
      'Upgrade to Enterprise to access this and other features such as Advanced User Management and Custom Rules.',
    bullet: [],
  },
  UPGRADE_MODAL_SCAN_HISTORY: {
    title: 'View Scan History',
    paragraph:
      "View issues, vulnerabilities, libraries and licenses from your projects' previous scans", //TODO:revise copy
    bullet: [],
  },
  GENERIC_PAYWALL: {
    title: 'You are trying to reach a premium page',
    paragraph: 'Upgrade today for all these benefits:',
    bullet: [
      'Get more scans',
      'Access premium vulnerability data',
      'Reduce false positives with vulnerable methods',
      'Access previous scan results',
      'Integrate with developer tools like Jira and GitHub Issues',
      'Automate your CI workflow with instant insights right in the agent',
    ],
  },
};

/** The name of the <tt>localStorage</tt> key in which any post-login redirect URL lives. */
export const REDIRECT_URL = 'REDIRECT_URL';

/** The name of the <tt>localStorage</tt> key in which a relevant saml email address lives. */
export const SAML_EMAIL = 'SAML_EMAIL';

/** The name of the <tt>localStorage</tt> flag for a new account. */
export const IS_NEW_ACCOUNT = 'IS_NEW_ACCOUNT';

export const strengthProps = {
  0: { color: 'bg-color--danger', width: 'col-1-24', text: 'Weak' },
  1: { color: 'bg-color--warning', width: 'col-5-24', text: 'Okay' },
  2: { color: 'bg-color--success-light', width: 'col-9-24', text: 'Good' },
  3: { color: 'bg-color--success', width: 'col-13-24', text: 'Strong' },
  4: { color: 'bg-color--success-dark', width: 'col-17-24', text: 'Excellent' },
};

/** The multiple selection items for issue types field*/
export const issueTypesOptions = [
  { value: 'VULN', label: 'Vulnerability Issues' },
  { value: 'LICENSE', label: 'License Issues' },
  { value: 'OUT_OF_DATE', label: 'Library Issues' },
];

/** The multiple selection items for severity field */
export const severityOptions = [
  { value: 'HIGH', label: 'High' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'LOW', label: 'Low' },
];

/** The multiple selection items for issue status field */
export const issueStatusOptions = [
  { value: '', label: 'All' },
  { value: 'OPEN', label: 'Open' },
  { value: 'FIXED', label: 'Resolved' },
];

/** The multiple selection items for issue suppress field */
export const issueSuppressStatusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'SUPPRESSED', label: 'Ignored' },
];

export const portfolioSearchContextOptions = [
  { value: 'WORKSPACE', label: 'Workspace name' },
  { value: 'PROJECT', label: 'Project name' },
  { value: 'LIBRARY', label: 'Library name' },
  { value: 'VULN', label: 'CVE name or description' },
  { value: 'LICENSE', label: 'License name' },
];

export const catalogFilterOptions = [
  { value: '', label: 'All libraries' },
  { value: true, label: 'In catalog' },
  { value: false, label: 'Not in catalog' },
];

export const issueTypeMap = {
  VULNERABILITY: 'VULN',
  OUTDATED_LIBRARY: 'OUT_OF_DATE',
  LICENSE: 'LICENSE',
};

export const scanFindingsOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'WITH_ISSUES', label: 'With Issues' },
  { value: 'NO_ISSUES', label: 'No Issues' },
];

export const dateRangeOptions = [
  { value: 'last7days', label: 'Last 7 days' },
  { value: 'last14days', label: 'Last 14 days' },
  { value: 'last30days', label: 'Last 30 days' },
  { value: 'last3months', label: 'Last 3 months' },
  { value: 'custom', label: 'Custom Range' },
];

export const matchedLibOptions = [
  { value: 'MATCHED', label: 'Matched Libraries' },
  { value: 'UNMATCHED', label: 'Unmatched Libraries' },
];

export const licenseRiskSeverityOptions = [
  { value: 'HIGH', label: 'High', rating: 4 },
  { value: 'MEDIUM', label: 'Medium', rating: 3 },
  { value: 'LOW', label: 'Low', rating: 2 },
  { value: 'UNKNOWN', label: 'Non-OSS', rating: 1 },
  { value: UNRECOGNIZED_LICENSE_RISK, label: 'Unrecognized', rating: 0, isDisabled: false },
];

export const tooltipContent = {
  ALLOW_SCANS_OVER_LIMIT:
    'Check to allow scanning after the monthly plan limit is reached. Additional scans will be charged at the rate listed in your subscription welcome email and added to your next monthly bill.',
  WORKSPACE_ADMIN:
    'Workspace administrators can rename a workspace, create and delete workspace agents, and delete projects within a workspace.',
};

export const WELCOME_MODAL = 'WELCOME_MODAL';

export const MAX_WORKSPACE_HOBBY_USER = '2';

// JIRA_PREM modals
export const CREATE_PROXY_CLIENT_STEP_1 = 'CREATE_PROXY_CLIENT_STEP_1'; // modal about confirming if user really need JIRA on premise
export const CREATE_PROXY_CLIENT_STEP_2 = 'CREATE_PROXY_CLIENT_STEP_2'; // instructions on install seps client
export const CHECK_PROXY_URL_REQUEST = 'CHECK_PROXY_URL_REQUEST';
export const CHECK_PROXY_CLIENT_REQUEST = 'CHECK_PROXY_CLIENT_REQUEST';

export const CREATE_JIRA_STEP_1 = 'CREATE_JIRA_STEP_1'; // contains public key, consumer key etc..
export const CREATE_JIRA_STEP_2 = 'CREATE_JIRA_STEP_2'; // contains oauth url
export const CREATE_JIRA_STEP_3 = 'CREATE_JIRA_STEP_3';
export const DELETE_INTEGRATION_MODAL = 'DELETE_INTEGRATION_MODAL';
export const TICKET_TEMPLATE_MODAL = 'TICKET_TEMPLATE_MODAL';
export const EDIT_TICKET_TEMPLATE_MODAL = 'EDIT_TICKET_TEMPLATE_MODAL';
export const CREATE_JIRA_TICKET_TEMPLATE = 'CREATE_JIRA_TICKET_TEMPLATE';
export const CREATE_JIRA_TICKET_MODAL = 'CREATE_JIRA_TICKET_MODAL';
export const DELETE_JIRA_TICKET_TEMPLATE = 'DELETE_JIRA_TICKET_TEMPLATE';

export const JIRA_PROJECTS = 'JIRA_PROJECTS';
export const JIRA_ISSUE_TYPES = 'JIRA_ISSUE_TYPES';
export const JIRA_ASSIGNEES = 'JIRA_ASSIGNEES';
export const JIRA_REPORTERS = 'JIRA_REPORTERS';
export const JIRA_EPICS = 'JIRA_EPICS';
export const JIRA_LABELS = 'JIRA_LABELS';
export const JIRA_STATUS = 'JIRA_STATUS';
export const JIRA_FIELDS = 'JIRA_FIELDS';
export const CREATE_JIRA_TICKET = 'CREATE_JIRA_TICKET';

export const IGNORE_ISSUE_MODAL = 'IGNORE_ISSUE_MODAL';
export const IGNORE_COMMENT_MODAL = 'IGNORE_COMMENT_MODAL'; // open when ignoring issue
export const COMMENT_ONLY_MODAL = 'COMMENT_ONLY_MODAL'; // open when commenting on issue
export const CREATE_ISSUE_JIRA_CLOUD_MODAL = 'CREATE_ISSUE_JIRA_CLOUD_MODAL';
export const CREATE_ISSUE_JIRA_ENTERPRISE_MODAL = 'CREATE_ISSUE_JIRA_ENTERPRISE_MODAL';
export const CREATE_ISSUE_GITHUB_MODAL = 'CREATE_ISSUE_GITHUB_MODAL';
export const MODAL_MAPPING = {
  CREATE_ISSUE_JIRA_CLOUD_MODAL: 'Create Jira Cloud Issue',
  CREATE_ISSUE_JIRA_ENTERPRISE_MODAL: 'Create Jira Legacy Issue',
  CREATE_ISSUE_GITHUB_MODAL: 'Create Github Issue',
};

// JIRA Fields schema type
export const JIRA_SCHEMA = {
  MULTI_SELECT: 'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
  TEXT_AREA: 'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
};

export const CREATE_GITHUB_INTEGRATION_MODAL = 'CREATE_GITHUB_INTEGRATION_MODAL';
export const EDIT_GITHUB_INTEGRATION_MODAL = 'EDIT_GITHUB_INTEGRATION_MODAL';
export const CREATE_JIRA_ENTERPRISE_INTEGRATION_MODAL = 'CREATE_JIRA_ENTERPRISE_INTEGRATION_MODAL';
export const EDIT_JIRA_ENTERPRISE_INTEGRATION_MODAL = 'EDIT_JIRA_ENTERPRISE_INTEGRATION_MODAL';

export const LOADER_TYPES = {
  DEFAULT: 'DEFAULT',
  SPINNER: 'SPINNER',
};

export const inviteModalTabs = {
  CHOOSE_USER_ROLES: 'CHOOSE_USER_ROLES',
  ADD_USERS_TO_GROUP: 'ADD_USERS_TO_GROUP',
};

export const USER_TYPES = {
  SOURCECLEAR_USER: 'SOURCECLEAR_USER',
  VERACODE_USER: 'VERACODE_USER',
};

export const USER_ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
};

export const VC_USER_ROLES = {
  VC_CREATOR: 'Creator',
  VC_EXEC: 'Executive',
  VC_SEC_LEAD: 'Security Lead',
  VC_SUBMITTER: 'Submitter',
  VC_REVIEWER: 'Reviewer',
  VC_ADMIN: 'Administrator',
};

export const ORG_ROLES = [
  { value: 'OWNER', label: 'Owner' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'USER', label: 'User' },
];

export const INTEGRATION_NAME_MAP = {
  JIRA_CLOUD: 'Jira Cloud',
  JIRA_ONPREM: 'Jira On-Prem',
  JIRA_LEGACY: 'Jira Legacy',
  GITHUB: 'GitHub',
};

// Object keys are mapped to the types returned by the API
export const INTEGRATION_TYPE_MAPPING = {
  JIRA: INTEGRATION_NAME_MAP.JIRA_CLOUD,
  JIRA_PREM: INTEGRATION_NAME_MAP.JIRA_ONPREM,
  Github: INTEGRATION_NAME_MAP.GITHUB,
  'JIRA Enterprise': INTEGRATION_NAME_MAP.JIRA_LEGACY, // legacy jira enterprise integration
};

export const SEP_CLIENT_INSTALL_OPTIONS = [
  { value: 'command', label: 'Quick start through a docker command' },
  { value: 'image', label: 'Create your own docker image' },
];

export const ENVIRONMENT_MAP = {
  QA: 'QA',
  DEV: 'DEV',
  PROD: 'PROD',
};

export const policiesMap = {
  FIELD: {
    severity: 'Severity',
    level: 'Level',
    matcher: 'Matcher',
    type: 'Descriptor',
    vulnerable_method: 'Vulnerable Method',
    latest_version: 'Latest Version',
    kind: 'Kind',
    includes: 'includes',
    name: 'Name',
  },
  VALUE: {
    should_be: 'should be',
    should_not_contain: 'should not contain',
  },
};

export const experimentCookieMap = {
  signupFlow: 'SIGNUP_FLOW',
};

export const snowplowSchemas = {
  general: 'iglu:com.srcclr/fe_generic_event/jsonschema/1-0-0',
  experimentContext: 'iglu:com.srcclr/ab_context/jsonschema/1-0-1',
  userContext: 'iglu:com.srcclr/user_context/jsonschema/1-0-1',
};

export const planOptions = [
  // {type: 'TEXT', title: 'Cost', hobby: 'Free', pro: 'From $55 per month', enterprise: 'From $60,000 per year', hideFromPlanDetails: true},
  {
    type: 'TEXT',
    title: 'Cost',
    hobby: 'Free',
    pro: 'From $55 per month',
    business: '$99 per month',
    enterprise: 'Contact us',
    hideFromPlanDetails: true,
  },
  // {type: 'TEXT', title: 'Scans per month', hobby: '1', pro: '100', enterprise: 'Contact us'},
  {
    type: 'TEXT',
    title: 'Scans/month',
    hobby: '5',
    business: '100',
    enterprise: 'Contact us',
  },
  // {type: 'TEXT', title: 'Workspaces', hobby: '1', pro: '1', enterprise: 'Contact us'},
  {
    type: 'TEXT',
    title: 'Workspaces',
    hobby: '1',
    business: '1',
    enterprise: 'Contact us',
  },
  // {type: 'TEXT', title: 'Projects', hobby: '1', pro: '10', enterprise: 'Contact us'},
  {
    type: 'TEXT',
    title: 'Projects',
    hobby: '1',
    business: '10',
    enterprise: 'Contact us',
  },
  // {type: 'TEXT', title: 'Users', hobby: '1', pro: '5', enterprise: 'Unlimited'},
  {
    type: 'TEXT',
    title: 'Users',
    hobby: '1',
    pro: 'Up to 250',
    business: '5',
    enterprise: 'Unlimited',
  },
  // {type: 'BOOLEAN', title: 'CVE data (public disclosures)', hobby: true, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'CVE data (public disclosures)',
    hobby: true,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'Vulnerable library analysis', hobby: true, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'Vulnerable library analysis',
    hobby: true,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'SVE data (non-public disclosures)', hobby: false, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'SVE data (non-public disclosures)',
    hobby: false,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'Vulnerable methods analysis', hobby: false, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'Vulnerable methods analysis',
    hobby: false,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'Open-source license analysis', hobby: false, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'Open-source license analysis',
    hobby: false,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'GitHub issues and JIRA integration', hobby: false, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'GitHub issues and Jira integration',
    hobby: false,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'Data export to CSV and JSON', hobby: false, pro: true, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'Data export to CSV and JSON',
    hobby: false,
    pro: true,
    business: true,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'SAML available', hobby: false, pro: false, enterprise: true},
  {
    type: 'BOOLEAN',
    title: 'SAML available',
    hobby: false,
    pro: false,
    business: false,
    enterprise: true,
  },
  // {type: 'BOOLEAN', title: 'User management', hobby: true, pro: true, enterprise: true, hideFromPlanDetails: true},
  // {type: 'TEXT', title: 'Data retention', hobby: 'Last scan only', pro: '12 months',, enterprise: 'Unlimited'},
  {
    type: 'TEXT',
    title: 'Data retention',
    hobby: 'Last scan only',
    pro: '12 months',
    business: '12 months',
    enterprise: '18 months',
  },
  // {type: 'BOOLEAN', title: 'Advanced user management', hobby: false, pro: false, enterprise: true, tooltip: 'Manage a large number of users through groups instead of individually.'},
  {
    type: 'BOOLEAN',
    title: 'Advanced user management',
    hobby: false,
    pro: false,
    business: false,
    enterprise: true,
    tooltip: 'Manage a large number of users through groups instead of individually.',
  },
  // {type: 'BOOLEAN', title: 'Custom rules', hobby: false, pro: false, enterprise: true, tooltip: 'Define when to create SourceClear issues and break your builds based on analysis of your code.'},
  {
    type: 'BOOLEAN',
    title: 'Custom rules',
    hobby: false,
    pro: false,
    business: false,
    enterprise: true,
    tooltip:
      'Define when to create SourceClear issues and break your builds based on analysis of your code.',
  },
  {
    type: 'TEXT',
    title: 'Support',
    hobby: 'Community only',
    pro: 'Basic email',
    business: 'Basic email',
    enterprise: 'Basic email included, support packages available',
  },
];

export const FIELD_PATTERNS = {
  name: {
    pattern: '[a-zA-Z]{1}[a-zA-Z0-9 \\-_]{2,19}',
    failMessage:
      'Must be between 3 and 20 characters, start with a letter,' +
      ' and include only letters, numbers, spaces, dashes or underscores',
  },
};

export const FEATURE_SLUG_MAP = {
  ORG_AGENTS: 'org_agents',
  DASHBOARD: 'dashboard',
  GROUPS: 'groups',
  POLICIES: 'policies',
  SAML_SELF_MANAGE: 'saml_self_manage',

  // Forces user to add a comment when ignore/ unignore issue.
  ISSUE_COMMENT: 'issue_comment',

  // Veracode Integration
  VERACODE_PORTFOLIO: 'veracode_portfolio',
  LIBRARY_CATALOG_FEATURE: 'library_catalog',
  CUSTOMER_MIGRATION_BEFORE_SUNSET: 'customer_migration_before_sunset',
};

export enum ISSUE_EVENT_SOURCE {
  SOURCECLEAR = 'SOURCECLEAR',
  SCAN = 'SCAN',
  USER = 'USER',
}

export enum ISSUE_EVENT_TYPE {
  CREATED = 'CREATED',
  STATUS = 'STATUS',
  SUPPRESSED = 'SUPPRESSED',
  SEVERITY = 'SEVERITY',
  COMMENT = 'COMMENT',
}

export const ISSUE_STATUS = {
  OPEN: 'OPEN',
  FIXED: 'FIXED',
};

export const ISSUE_EVENT_AFFECTED_SOURCE = {
  VULN_RELEASED: 'VULN_RELEASED',
  VULN_COMPONENT_UPDATE: 'VULN_COMPONENT_UPDATE',
};

export const SOURCECLEAR_COMPONENTS = {
  VULNERABILITY_DATABASE: {
    name: 'Vulnerability Database',
    slug: 'vulnerability-database',
  },
};

export const ENTERPRISE_UNLIMITED = 20; // plan id

export const DEFAULT_POLICY_ID = '00000000-0000-0000-0000-000000000000';

export const PROJECT_SETTINGS_PAGES = {
  BRANCHES: 'BRANCHES',

  NOTIFICATIONS: 'NOTIFICATIONS',
  NOTIFICATIONS_PAYWALL: 'NOTIFICATIONS_PAYWALL',
  LINK_TO_APPLICATIONS: 'LINK_TO_APPLICATIONS',
  POLICIES: 'POLICIES',
  OTHER: 'OTHER',
};

export const PROJECT_SETTINGS_STAGES = {
  CREATE_WEBHOOK: 'CREATE_WEBHOOK',
  UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',
};

export const REPO_EVENTS = {
  SCAN_SUCCESS: 'SCAN_SUCCESS',
  VULN_ISSUES_DISCOVERED_AFTER_SCAN: 'VULN_ISSUES_DISCOVERED_AFTER_SCAN',
  VULN_ISSUES_CHANGED_AFTER_SCAN: 'VULN_ISSUES_CHANGED_AFTER_SCAN',
};

export const REPO_EVENTS_CLEAN_NAMES = {
  [REPO_EVENTS.SCAN_SUCCESS]: 'Scan success',
  [REPO_EVENTS.VULN_ISSUES_DISCOVERED_AFTER_SCAN]: 'Vuln issues discovered after scan',
  [REPO_EVENTS.VULN_ISSUES_CHANGED_AFTER_SCAN]: 'Vuln issues changed after scan',
};

export const NOTIFICATION_TYPES = {
  WEBHOOK: 'WEBHOOK',
  EMAIL: 'EMAIL',
};

export const TRIGGER_EVENTS_OPTIONS = {
  JUST_SCAN_EVENT: 'JUST_SCAN_EVENT',
  ALL_EVENTS: 'ALL_EVENTS',
  INDIVIDUAL_EVENTS: 'INDIVIDUAL_EVENTS',
};

export const ISSUE_NOTIFICATION_TYPES = {
  SCAN_ISSUES: 'SCAN_ISSUES',
  SOURCECLEAR_ISSUES: 'SOURCECLEAR_ISSUES',
};

export const PLAN_TYPE = {
  FREE: 'FREE',
  PRO: 'PRO',
  ENTERPRISE: 'ENTERPRISE',
};

export const PLAN_TYPE_ID_MAP = {
  OPEN: 1001,
};

export const SUBSCRIPTION_TYPE = {
  TRIAL: 'TRIAL',
  ACTIVE: 'ACTIVE',
};

// total items per page for client-side pagination
export const CLIENT_PAGINATION_LIMITS = {
  agentList: 20,
  agentEventsList: 10,
};

export const STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'VI', label: 'US Virgin Islands' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const PROVINCES = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Northwest Territories' },
  { value: 'NT', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon Territories' },
];

export const COUNTRIES = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Cote dIvoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curacao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea, Democratic Peoples Republic of' },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao Peoples Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libyan Arab Jamahiriya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PS', label: 'Palestinian Territory, Occupied' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthelemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Chinese Taipei' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'HK', label: 'Hong Kong' },
];

export const PROJECT_TYPES = {
  CONTAINER: 'CONTAINER',
  REPO: 'REPO',
};

export const PROJECT_TYPES_CLEAN_NAMES = {
  CONTAINER: 'Container',
  REPO: 'Repository',
};

export const veracodeToastrBlockList = [
  // BILLING
  { id: 'PAYMENT_FAILURE' },
  { id: 'USAGE_ALERT' },
  { id: 'VERIFY_BILLING_EMAIL' },
  { id: 'SCANS_LIMIT' },
  { id: 'payment-update-succes' },
  { id: 'PLAN_CHANGE_SUCCESS' },
  { id: 'PLAN_CHANGE_ERROR' },
  { id: 'BILLING_EMAIL_RESENT' },
  { id: 'UPGRADE_SUCCESS' },
  { id: 'trial-extended' },
  { id: 'BILLING-EMAIL-VERIFIED' },
  // LOGIN
  { id: `EMAIL_ERROR` },
  { id: 'UNVERIFIED-EMAIL' },
  { id: 'EMAIL-VERIFIED' },
  { id: 'EMAIL-SENT' },
];

export const vcAppLinkingErrorMessage = {
  pageSize: 'An error occurred fetching page size.',
  fetchLinkedApp: 'An error occurred fetching linked application.',
  linkApp: 'An error occurred linking the project to the application.',
  unlinkApp: 'An error occurred unlinking the project from the application.',
  fetchApplications: 'An error fetching applications data.',
  tooManyApplications:
    'Your account has more than 1,000 applications. For performance reasons, we are unable to display them all. Showing first 1,000 applications in list.',
};

export const resourceTypeMap = {
  library: 'library',
};

export const highMediumLowRisks = ['high-risk', 'medium-risk', 'low-risk', 'non-oss'];

export const exitLevelOptions = [
  { value: 'error', label: 'Error' },
  { value: 'warning', label: 'Warning' },
];

export const descriptorTypeOptions = [
  { value: 'vulnerability', label: 'vulnerability' },
  { value: 'license', label: 'license' },
  { value: 'library', label: 'library' },
];

// The value for cvssv2 are not cvssv2_high due to backward
// compatiability with previous value in PolicyControlCard
export const descriptorSeverityOptions = [
  { value: 'high_risk', label: 'CVSS v2 high' },
  { value: 'medium_risk', label: 'CVSS v2 medium' },
  { value: 'low_risk', label: 'CVSS v2 low' },
  { value: 'cvss_v3_critical', label: 'CVSS v3 critical' },
  { value: 'cvss_v3_high', label: 'CVSS v3 high' },
  { value: 'cvss_v3_medium', label: 'CVSS v3 medium' },
  { value: 'cvss_v3_low', label: 'CVSS v3 low' },
];

export const descriptorLicenseOptions = [
  { value: 'license-by-name', label: 'License by name' },
  { value: 'high-risk', label: 'High-risk' },
  { value: 'medium-risk', label: 'Medium-risk' },
  { value: 'low-risk', label: 'Low-risk' },
  { value: 'non-oss', label: 'Non-OSS' },
  { value: 'unlicensed', label: 'Unrecognized' },
];

export const matcherOptions = [
  { value: 'should_not_contain', label: 'should not contain' },
  { value: 'should_be', label: 'should be' },
];

export const vulnerableMethodOptions = [
  { value: true, label: 'yes' },
  { value: false, label: 'no' },
];

export const descriptorLibraryOptions = [
  { value: true, label: 'Latest' },
  { value: false, label: 'no' },
];

export const resourceDependencyOptions = [
  { value: 'any', label: 'Any' },
  { value: 'direct', label: 'Direct' },
  { value: 'indirect', label: 'Transitive' },
  { value: 'both', label: 'Both' },
];

export const scanDateOptions = [
  { value: WORKSPACE_SCAN_DATE_LAST_7_DAYS, label: 'Last 7 days' },
  { value: DEFAULT_WORKSPACE_SCAN_DATE, label: 'Last 30 days' },
  { value: 'last_3_months', label: 'Last 3 months' },
  { value: 'last_6_months', label: 'Last 6 months' },
  { value: 'last_13_months', label: 'Last 13 months' },
  {
    value: WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS,
    label: 'More than 7 days ago',
  },
];

// scanDateOptions for Standalone users
export const scanDateOptionsForStandalone = [
  { value: WORKSPACE_SCAN_DATE_LAST_7_DAYS, label: 'Last 7 days' },
  { value: 'last_14_days', label: 'Last 14 days' },
  { value: DEFAULT_WORKSPACE_SCAN_DATE, label: 'Last 30 days' },
  { value: 'last_3_months', label: 'Last 3 months' },
  { value: 'last_6_months', label: 'Last 6 months' },
  { value: 'last_12_months', label: 'Last 12 months' },
  { value: WORKSPACE_SCAN_DATE_ALL_DATES, label: 'All dates' },
  {
    value: WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS,
    label: 'More than 7 days ago',
  },
];

// TODO: move urls to ApiConstants

export const veracodeNavService = '/api/navigation/navigations';
export const veracodeLogout = '/#!/login?logout';
export const veracodeSessionTimeout = '/#!/login?sessiontimeout';
export const veracodeAuthSessionUrl = '/api/authn/authenticate/session';
export const veracodeApplicationsService = '/appsec/v1/applications';
export const veracodePoliciesService = '/appsec/v1/policies';
export const veracodeCategoryService = '/appsec/v1/categories';
export const veracodeCwesService = '/appsec/v1/cwes';
export const veracodeStartAScanPage = '/start-scan';
export const xsrfCookieName = 'XSRF-TOKEN';
export const veracodeScaUrl = '/auth/index.jsp#ScaHomeEUI';
export const veracodeUpsellUrl = '/index.jsp#ScaGetStartedHome';
export const veracodeScaDemoUrl = 'https://info.veracode.com/veracode-sca-demo.html';
export const veracodeCreateAppUrl = '/auth/index.jsp#AnalyzeAppDefault';
export const veracodeAppProfileUrl = '/auth/index.jsp#HomeAppProfile';

export const NO_ISSUE_MESSAGE_MAP = {
  VULN: 'No vulnerability issues found. Change your issue type to view all your issues.',
  LICENSE: 'No license issues found. Change your issue type to view all your issues.',
  OUT_OF_DATE: 'No library issues found. Change your issue type to view all your issues.',
};

export const licenseRisksMap = {
  high: { type: 'high', kind: 'high-risk' },
  medium: { type: 'medium', kind: 'medium-risk' },
  low: { type: 'low', kind: 'low-risk' },
  unknown: { type: 'unknown', kind: 'non-oss' },
};

export const LICENSE_RISK_CONST_MAP = {
  LICENSE: 'license',
  LIBRARY: 'library',
  RISK: 'risk',
  GPL: 'gpl',
  MULTI: 'multi',
  LICENSE_BY_NAME: 'license-by-name',
  CUSTOM: 'custom',
  INCLUDES: 'includes',
  EXCLUDES: 'excludes',
  KIND: 'kind',
  HIGH_RISK: 'high-risk',
  MEDIUM_RISK: 'medium-risk',
  LOW_RISK: 'low-risk',
  NON_OSS: 'non-oss',
};

export const repoNameMaxLength = 40;

export const VERACODE_SESSION_TIMEOUT_COOKIE_KEY = 'veracode-session-timeout';

export const USE_LAST_SCANNED = 'Use Last Scanned';

export const PageSizeOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

export const PageSizeSmallOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
];

export const AVAILABLE_REGIONS = [Region.ER, Region.FED];

export const SCA_RULES_TYPE = ['CVSS', 'LICENSE_RISK'];
