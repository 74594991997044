import {
  vulnMethodsSupportedLanguages as supportedLanguages,
  vulnMethodsSupportedCoordTypes as supportedCoordTypes,
} from '~/constants/ModelConstants';

const VulnMethodSupportStatus = () => {
  const isCoordTypeSupported = coordType => {
    const normalizedCoordType = coordType && coordType.toUpperCase();
    return supportedCoordTypes.includes(normalizedCoordType);
  };

  const isLanguageSupported = language => {
    const normalizedLanguage = language && language.toUpperCase();
    return supportedLanguages.includes(normalizedLanguage);
  };

  return {
    isCoordTypeSupported,
    isLanguageSupported,
  };
};

export default new VulnMethodSupportStatus();
