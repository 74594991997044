/** Used the logic from the agora policy ui **/

export enum RuleTypeEnum {
  Blacklist = 'BLACKLIST',
  Category = 'CATEGORY',
  Cvss = 'CVSS',
  Cwe = 'CWE',
  LicenseRisk = 'LICENSE_RISK',
  MinScore = 'MIN_SCORE',
  ScanType = 'FAIL_ALL',
  Security = 'SECURITY_STANDARD',
  Severity = 'MAX_SEVERITY',
  AllowList = 'ALLOWLIST',
}

export enum ScanTypeEnum {
  Any = 'ANY',
  Dynamic = 'DYNAMIC',
  Manual = 'MANUAL',
  Mobile = 'MOBILE',
  Sca = 'SCA',
  Static = 'STATIC',
}

export enum SecurityEnum {
  Pci = 'pci',
  PciLatest = 'pci_veracode',
  Pci2020 = 'pci_2020',
  Owasp = 'owasp',
  Owasp13 = 'owasp_13',
  Owasp17 = 'owasp_17',
  OwaspMobile = 'owasp_mobile',
  CweLatest = 'cwe_veracode',
  Cwe2019 = 'cwe_2019',
  Cwe2020 = 'cwe_2020',
  Sans2011 = 'sans',
  Cert = 'cert',
}

export enum SeverityEnum {
  VERY_HIGH = 5,
  HIGH = 4,
  MED = 3,
  LOW = 2,
  VERY_LOW = 1,
  INFORMATIONAL = 0,
}

export enum CvssScoreEnum {
  V1 = '1',
  V2 = '2',
  V3 = '3',
  V4 = '4',
  V5 = '5',
  V6 = '6',
  V7 = '7',
  V8 = '8',
  V9 = '9',
  V10 = '10',
}

enum LicenseEnum {
  HIGH = 4,
  MED = 3,
  LOW = 2,
}

export enum PolicyCategoryEnum {
  Application = 'APPLICATION',
  Component = 'COMPONENT',
}

export enum ScanFrequencyEnum {
  Once = 'ONCE',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnually = 'SEMI_ANNUALLY',
  Annually = 'ANNUALLY',
  Every18Mos = 'EVERY_18_MONTHS',
  Every2Years = 'EVERY_2_YEARS',
  Every3Years = 'EVERY_3_YEARS',
  /* Valid, but not being used
  NotRequired = 'NOT_REQUIRED',
  SetByVlPolicy = 'SET_BY_VL_POLICY',
  SetByPolicyRule = 'SET_BY_POLICY_RULE',
  */
}

export enum RepoTypeEnum {
  MAVEN = 'Maven',
  NPM = 'npm',
  NUGET = 'NuGet',
}

export const severities = [
  { label: 'Very High', value: SeverityEnum.VERY_HIGH },
  { label: 'High and above', value: SeverityEnum.HIGH },
  { label: 'Medium and above', value: SeverityEnum.MED },
  { label: 'Low and above', value: SeverityEnum.LOW },
  { label: 'Very Lowe and above', value: SeverityEnum.VERY_LOW },
  { label: 'Informational and above', value: SeverityEnum.INFORMATIONAL },
];

export const severitiesDisplay = [
  { label: 'Very High', value: SeverityEnum.VERY_HIGH },
  { label: 'High', value: SeverityEnum.HIGH },
  { label: 'Medium', value: SeverityEnum.MED },
  { label: 'Low', value: SeverityEnum.LOW },
  { label: 'Very Low', value: SeverityEnum.VERY_LOW },
  { label: 'Informational', value: SeverityEnum.INFORMATIONAL },
];

export const securityStandards = [
  { label: 'PCI 3.2.1 (OWASP 2017, CWE/SANS 2011, CERT 2018)', value: SecurityEnum.Pci },
  { label: 'OWASP 2017', value: SecurityEnum.Owasp17 },
  { label: 'OWASP 2013', value: SecurityEnum.Owasp13 },
  { label: 'OWASP Mobile', value: SecurityEnum.OwaspMobile },
  { label: 'Auto-Update CWE Top 25', value: SecurityEnum.CweLatest },
  { label: '2019 CWE Top 25', value: SecurityEnum.Cwe2019 },
  { label: '2011 CWE/SANS Top 25', value: SecurityEnum.Sans2011 },
  { label: 'Auto-Update CERT', value: SecurityEnum.Cert },
  { label: 'Auto-Update OWASP', value: SecurityEnum.Owasp },
  { label: '2020 CWE Top 25', value: SecurityEnum.Cwe2020 },
  {
    label: 'Auto-Update PCI (Latest version of OWASP, CWE Top 25, and CERT)',
    value: SecurityEnum.PciLatest,
  },
  { label: 'PCI 3.2.1 (OWASP 2017, CWE 2020, CERT 2018)', value: SecurityEnum.Pci2020 },
];

export const cvsss = [
  { label: '10.0', value: CvssScoreEnum.V10 },
  { label: '9.0', value: CvssScoreEnum.V9 },
  { label: '8.0', value: CvssScoreEnum.V8 },
  { label: '7.0', value: CvssScoreEnum.V7 },
  { label: '6.0', value: CvssScoreEnum.V6 },
  { label: '5.0', value: CvssScoreEnum.V5 },
  { label: '4.0', value: CvssScoreEnum.V4 },
  { label: '3.0', value: CvssScoreEnum.V3 },
  { label: '2.0', value: CvssScoreEnum.V2 },
  { label: '1.0', value: CvssScoreEnum.V1 },
];

export const licenseRisks = [
  { label: 'High', value: LicenseEnum.HIGH },
  { label: 'Medium and above', value: LicenseEnum.MED },
  { label: 'Low and above', value: LicenseEnum.LOW },
];

export const scanTypes = [
  { label: 'Static', value: ScanTypeEnum.Static },
  { label: 'Dynamic', value: ScanTypeEnum.Dynamic },
  { label: 'Manual', value: ScanTypeEnum.Manual },
];

export const repoTypes = [
  { label: 'Maven', value: RepoTypeEnum.MAVEN },
  { label: 'npm', value: RepoTypeEnum.NPM },
  { label: 'Nuget', value: RepoTypeEnum.NUGET },
];

export const scanFrequencies = [
  { label: 'Once', value: ScanFrequencyEnum.Once },
  { label: 'Weekly', value: ScanFrequencyEnum.Weekly },
  { label: 'Monthly', value: ScanFrequencyEnum.Monthly },
  { label: 'Quarterly', value: ScanFrequencyEnum.Quarterly },
  { label: 'Semi Annually', value: ScanFrequencyEnum.SemiAnnually },
  { label: 'Annually', value: ScanFrequencyEnum.Annually },
  { label: 'Every 18 Months', value: ScanFrequencyEnum.Every18Mos },
  { label: 'Every 2 Years', value: ScanFrequencyEnum.Every2Years },
  { label: 'Every 3 Years', value: ScanFrequencyEnum.Every3Years },
];

const staticDynamicManualScanTypes: ScanTypeEnum[] = [
  ScanTypeEnum.Static,
  ScanTypeEnum.Dynamic,
  ScanTypeEnum.Manual,
  /*
   'DYNAMICDS',
   'DYNAMICMP',
     */
];

export const findingRuleTypeMap = new Map([
  [
    RuleTypeEnum.Blacklist,
    {
      label: 'Component Blocklist Enforcement',
      scan_type: [ScanTypeEnum.Sca],
    },
  ],
  [
    RuleTypeEnum.Category,
    {
      label: 'Findings in CWE Category',
      scan_type: staticDynamicManualScanTypes,
    },
  ],
  [
    RuleTypeEnum.Cwe,
    {
      label: 'Findings with CWE ID',
      scan_type: staticDynamicManualScanTypes,
    },
  ],
  [
    RuleTypeEnum.Cvss,
    {
      label: 'Vulnerability CVSS Score',
      scan_type: [ScanTypeEnum.Sca],
      options: cvsss,
    },
  ],
  [
    RuleTypeEnum.LicenseRisk,
    {
      label: 'Component License Risk',
      scan_type: [ScanTypeEnum.Sca],
      options: licenseRisks,
    },
  ],
  [
    RuleTypeEnum.AllowList,
    {
      label: 'Component Allowlist',
      scan_type: [ScanTypeEnum.Sca],
      options: repoTypes,
    },
  ],
  [
    RuleTypeEnum.MinScore,
    {
      label: 'Minimum Scan Score',
      scan_type: staticDynamicManualScanTypes,
    },
  ],
  [
    RuleTypeEnum.ScanType,
    {
      label: 'Findings within Scan Type',
      scan_type: [],
      options: scanTypes,
    },
  ],
  [
    RuleTypeEnum.Security,
    {
      label: 'Security Standard',
      scan_type: staticDynamicManualScanTypes,
      options: securityStandards,
    },
  ],
  [
    RuleTypeEnum.Severity,
    {
      label: 'Findings by Severity',
      scan_type: [],
      options: severities,
    },
  ],
]);

export const findingScanTypeMap = new Map([
  [ScanTypeEnum.Any, { label: 'Any Scan Type' }],
  [ScanTypeEnum.Dynamic, { label: 'Dynamic Analysis' }],
  [ScanTypeEnum.Manual, { label: 'Manual Penetration Testing' }],
  [ScanTypeEnum.Mobile, { label: 'Mobile' }],
  [ScanTypeEnum.Sca, { label: 'Software Composition Analysis' }],
  [ScanTypeEnum.Static, { label: 'Static Analysis' }],
]);

export interface CategoryRow {
  description: string;
  id: number;
  name: string;
  recommendation: string;
}

export interface Cwereference {
  name: string;
  url: string;
}

export interface CweRow {
  description: string;
  id: number;
  name: string;
  recommendation: string;
  references: Cwereference[];
  remediation_effort: number;
  severity: number;
  // Not part of the schema, used internally
  severityText?: string;
  referenceUrl?: string;
  customSeverity?: number;
}

interface AllowlistCoordinate {
  created_by?: string;
  created_date?: number;
  version: string;
  repo_type: RepoTypeEnum;
  coordinate1: string;
  coordinate2?: string;
  // Artificial - used in Allowlist grid
  name?: string;
  _id?: string; // for delete operation
}

export interface PolicyFindingRule {
  scan_type: ScanTypeEnum[];
  type: RuleTypeEnum;
  value: string;
  coordinates?: AllowlistCoordinate[];

  // Artificial - used in Rules grid, combining rows with equal rule#type
  requirement?: string[];

  type_text: string;
  severity_field: string[];
  requirement_field: string;
}

export interface PolicyCustomSeverity {
  cwe: number;
  severity: number;
  // Artificial: used internally
  cweRef?: CweRow;
}
