import React from 'react';

interface IssueSeverityFlagProps {
  severity: number;
  hideText?: boolean;
  hideValue?: boolean;
}

export function rangeBySeverity(severity: number | null) {
  if (7 <= severity) {
    return 'HIGH';
  } else if (4 <= severity) {
    return 'MEDIUM';
  } else if (0.1 <= severity) {
    return 'LOW';
  } else {
    return 'UNKNOWN';
  }
}

export const displayTextBySeverity = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  UNKNOWN: '-',
};

const IssueSeverityFlag: React.FunctionComponent<IssueSeverityFlagProps> = props => {
  const { severity, hideText, hideValue = false } = props;
  const colorBySeverity = {
    HIGH: 'bg-color--danger',
    MEDIUM: 'bg-color--warning',
    LOW: 'bg-color--warning-light',
    UNKNOWN: 'bg-color--muted-light',
  };

  const borderColorBySeverity = {
    HIGH: 'border-color--danger',
    MEDIUM: 'border-color--warning',
    LOW: 'border-color--warning-light',
    UNKNOWN: 'border-color--muted-light',
  };

  const severityRange = rangeBySeverity(severity);
  const color = colorBySeverity[severityRange];
  const displayText = displayTextBySeverity[severityRange];
  const borderColor = borderColorBySeverity[severityRange];
  const severityText = formatSeverity(severity);

  function formatSeverity(severity) {
    const severityString = (severity && severity.toString()) || '0';
    if (!severityString.includes('.')) {
      return `${severity}.0`;
    }

    return `${severity}`;
  }

  return (
    <div className={`flex`}>
      <span className={`flex flex--align-self--center ${color} pv---`}>
        {!hideValue ? (
          <strong className={`color--white text--center ${color} pv-- width--40`}>
            {severityText}
          </strong>
        ) : (
          <i
            className={`color--white text--center font--h6 sci ${color} pv-- sci__vulnerability ph-`}
          />
        )}
      </span>
      {!hideText && (
        <span
          className={`flex align-items--center bo--1 bo-l--0 ph- width--100 font-family--heading color--black bg-color--white ${borderColor}`}
        >
          {displayText}
        </span>
      )}
    </div>
  );
};

export default IssueSeverityFlag;
