import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Helpers from '~/utils/Helpers';

import * as quickviewActions from '~/actions/quickview';
import * as libraryCatalogActions from '~/actions/libraryCatalog';
import * as upgradeModalActions from '~/actions/upgradeModal';

import { QuickviewClosedIcon, QuickviewOpenIcon } from '~/components/Icons';
import LoaderWrapper from '~/components/LoaderWrapper';

import ReportExpandedDependencyPath from '~/containers/ReportExpandedDependencyPath';
import ReportExpandedVulnView from '~/components/ReportComponents/ReportExpandedVulnView';
import ReportExpandedLicense from '~/containers/ReportExpandedLicense';
import VulnBox from '~/components/ReportComponents/VulnBox';
import ProIcon from '~/components/ProIcon';

import Tooltip from '~/components/Tooltip';
import RegistryLink from '~/components/RegistryLink';

import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import { VelocityTransitionGroup } from 'velocity-react';
import Animations from '~/utils/Animations';

import * as MODEL from '~/constants/ModelConstants';

import LicenseRiskIcon, { Rating } from '~/components/Icons/LicenseRiskIcon';
import checkmark from '~/images/checkmark.png';

interface ReportLibrariesRowProps {
  columnWidths: {
    [key: string]: string;
  };
  rowValues: object;
  rowId: string;
  isSelected: boolean;
  quickviewActions: object;
  quickviewState: object;
  isSingleRepoScope?: boolean;
  libraryCatalogActions: object;
  upgradeModalActions: object;
  onRowSelectChange: (...args: any[]) => any;
  myState: App.MyState;
  orgState: App.OrgState;
  isPaidOrTrialing?: boolean;
  navigationState: object;
  shouldShowVeracodePage?: boolean;
}

export class ReportLibrariesRow extends React.Component<ReportLibrariesRowProps, {}> {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.quickviewState.currentOpenRowId.startsWith(this.props.rowId) ||
      this.props.isSelected !== nextProps.isSelected
    ) {
      return true;
    } else {
      return !(
        _.isEqual(this.props.rowValues, nextProps.rowValues) &&
        _.isEqual(
          this.props.quickviewState.currentOpenRowId,
          nextProps.quickviewState.currentOpenRowId
        )
      );
    }
  }

  getQuickviewLinks = () => {
    const { _links = {} } = this.props.rowValues;
    return {
      component: _links.library.href,
      vulns: _links.vulns.href,
      dependencyPath: _links.graph.href,
    };
  };

  getFormattedLicense = () => {
    const { licenses = {} } = this.props.rowValues;
    const formattedLicense = [];
    for (let key in licenses) {
      formattedLicense.push(Object.assign({}, licenses[key], { key: key }));
    }

    return formattedLicense;
  };

  getHighestLicenseRisk() {
    const { licenses = {} } = this.props.rowValues;

    const severities = Object.values(licenses).map(license =>
      MODEL.licenseRiskSeverityOptions.find(severity => severity.value === license.risk)
    );

    return (
      severities.find(
        severity =>
          severity.rating ===
          Math.max.apply(
            null,
            severities.map(s => s.rating)
          )
      ) || {}
    ).value;
  }

  getQuickviewId = ({ rowField, rowId }) => {
    return `${rowId}-${rowField}`;
  };

  toggleQuickview = (quickviewId, isExpanded, quickviewLink) => {
    const { isSingleRepoScope } = this.props;

    if (isExpanded) {
      if (quickviewId.endsWith('license')) {
        this.props.quickviewActions.getQuickViewLicenses(quickviewId, this.getFormattedLicense());
      } else if (quickviewId.endsWith('dependencyPath') && isSingleRepoScope) {
        // special case. See the method for more info
        this.props.quickviewActions.loadQuickviewForDependencyPathSelectedRepo(
          quickviewId,
          quickviewLink
        );
      } else {
        this.props.quickviewActions.loadQuickview(quickviewId, quickviewLink);
      }
      this.props.quickviewActions.setCurrentOpenRow(quickviewId);
    } else {
      this.props.quickviewActions.setCurrentOpenRow('');
    }
  };

  onQuickviewPageClick = (quickviewId, link, page) => {
    this.props.quickviewActions.loadQuickview(quickviewId, link, page);
  };

  handleChange = library => {
    this.props.onRowSelectChange(library);
  };

  toggleCtaPremiumVulnModalOpen = location => {
    const { navigationState } = this.props;
    const { activeTeamParent: teamId } = navigationState;
    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_PREMIUM_VULN,
      teamId,
      location
    );
  };

  render() {
    const { rowValues, rowId, isSingleRepoScope, columnWidths, isPaidOrTrialing } = this.props;
    const {
      latestVersion,
      latestCommitHash,
      name,
      repos,
      version = '',
      coord1 = '',
      coord2 = '',
      coordinateType = '',
      languageType,
      commitHash,
      highArtifactCount,
      mediumArtifactCount,
      lowArtifactCount,
      totalArtifactCount,
      isCommLicense = false,
    } = rowValues;
    const formattedLicense = this.getFormattedLicense();

    const risk = this.getHighestLicenseRisk() || 'UNRECOGNIZED';

    const { currentOpenRowId = '', dataByRowId = {}, isLoading = true } = this.props.quickviewState;
    const vulnsQuickviewId = this.getQuickviewId({ rowId, rowField: 'vulns' });
    const licenseQuickviewId = this.getQuickviewId({
      rowId,
      rowField: 'license',
    });
    const dependencyPathQuickviewId = this.getQuickviewId({
      rowId,
      rowField: 'dependencyPath',
    });
    const {
      vulns: vulnsQuickviewLink,
      dependencyPath: pathQuickviewLink,
      component: componentLink,
    } = this.getQuickviewLinks();
    const componentCommitHashLink =
      componentLink.includes('?version=HEAD') && commitHash
        ? `${componentLink.split('?version=HEAD')[0]}?commit_hash=${commitHash}`
        : null;
    const {
      [vulnsQuickviewId]: vulnQuickviewData = {},
      [dependencyPathQuickviewId]: dependencyPathQuickviewData = {},
      [licenseQuickviewId]: licenseQuickviewData = {},
    } = dataByRowId;

    const showLibraryCatalog = Helpers.hasLibraryCatalogEnabled();

    const renderCommitHashOrVersion = () => {
      if (commitHash) {
        return (
          <Tooltip
            fullWidth={true}
            place="right"
            id={`${rowId}-commitHash`}
            content={`Commit Hash: ${commitHash}`}
          >
            ({Helpers.shortedCommitHash(commitHash)})
          </Tooltip>
        );
      } else if (languageType === 'GO' && version === 'HEAD') {
        return (
          <Tooltip
            fullWidth={true}
            place="right"
            id={`${rowId}-commitHash`}
            content={`Can't detect commit hash for this library`}
          >
            {version}
          </Tooltip>
        );
      } else {
        return <span> {version} </span>;
      }
    };

    return (
      <div className="bo--1 border-color--white-dark pl- pv-- mv---">
        <div className="grid grid--narrower grid--middle flex--align-items--stretch">
          <div
            className={`grid__item flex flex--align-items--center ${columnWidths.name}`}
            data-automation-id="RowCell"
          >
            <Tooltip
              fullWidth={true}
              place="right"
              id={`${rowId}-libRow`}
              content={`${coord1} ${coord2} ${version} ${
                coordinateType ? '(' + coordinateType + ')' : ''
              }`}
            >
              <p className="text--overflow">
                <RegistryLink
                  href={componentCommitHashLink || componentLink}
                  className="link--obvious"
                >
                  {name}
                </RegistryLink>
              </p>
            </Tooltip>
          </div>

          <div
            className={`grid__item flex flex--align-items--center text--overflow ${columnWidths.version}`}
            data-automation-id="RowCell"
          >
            {renderCommitHashOrVersion()}
          </div>

          <div
            className={`grid__item color--muted-dark flex flex--align-items--center text--overflow ${columnWidths.latestVersion}`}
            data-automation-id="RowCell"
          >
            {latestCommitHash && latestVersion === 'HEAD' ? (
              <Tooltip
                fullWidth={true}
                place="right"
                id={`${rowId}-latestCommitHash`}
                content={`Commit Hash: ${latestCommitHash}`}
              >
                {latestVersion}-{Helpers.shortedCommitHash(latestCommitHash)}
              </Tooltip>
            ) : (
              <span> {latestVersion} </span>
            )}
          </div>

          {!isPaidOrTrialing ? (
            <div
              className={`grid__item flex flex--align-items--center cursor--pointer ${columnWidths.vulns}`}
              onClick={() => this.toggleCtaPremiumVulnModalOpen('LIBRARY_LIST_VULNS_COLUMN')}
              data-automation-id="RowCell"
            >
              <ProIcon />
            </div>
          ) : (
            <div
              className={`grid__item flex flex--align-items--center ${
                totalArtifactCount > 0 && 'cursor--pointer'
              } ${columnWidths.vulns}`}
              onClick={
                totalArtifactCount > 0
                  ? () =>
                      this.toggleQuickview(
                        vulnsQuickviewId,
                        currentOpenRowId !== vulnsQuickviewId,
                        vulnsQuickviewLink
                      )
                  : null
              }
              data-automation-id="RowCell"
            >
              <div className="col-11-12">
                <span className="text--center mr--">
                  <VulnBox level="HIGH" num={highArtifactCount} />
                </span>
                <span className="text--center mr--">
                  <VulnBox level="MEDIUM" num={mediumArtifactCount} />
                </span>
                <span className="text--center mr--">
                  <VulnBox level="LOW" num={lowArtifactCount} />
                </span>
                {totalArtifactCount > 0 && (
                  <span>
                    {currentOpenRowId !== vulnsQuickviewId ? (
                      <QuickviewClosedIcon className="cursor--pointer color--primary" />
                    ) : (
                      <QuickviewOpenIcon className="cursor--pointer color--primary" />
                    )}
                  </span>
                )}
              </div>
            </div>
          )}

          <div
            className={`grid__item color--muted-dark flex flex--align-items--center cursor--pointer ${columnWidths.dependencyPath}`}
            onClick={() =>
              this.toggleQuickview(
                dependencyPathQuickviewId,
                currentOpenRowId !== dependencyPathQuickviewId,
                pathQuickviewLink
              )
            }
            data-automation-id="RowCell"
          >
            <div className="flex flex--justify-content--space-between col-11-12">
              {!isSingleRepoScope && (
                <div>
                  Found in {repos} Project
                  {repos > 1 && <span>s</span>}
                </div>
              )}
              {isSingleRepoScope && <div>View Dependencies</div>}

              {currentOpenRowId !== dependencyPathQuickviewId ? (
                <QuickviewClosedIcon className="cursor--pointer color--primary" />
              ) : (
                <QuickviewOpenIcon className="cursor--pointer color--primary" />
              )}
            </div>
          </div>

          <div
            className={`grid__item color--muted-dark flex flex--align-items--center ${
              columnWidths.license
            } ${formattedLicense.length && 'cursor--pointer'}`}
            onClick={
              formattedLicense.length
                ? () =>
                    this.toggleQuickview(
                      licenseQuickviewId,
                      currentOpenRowId !== licenseQuickviewId
                    )
                : null
            }
            data-automation-id="RowCell"
          >
            {formattedLicense.length ? (
              <div className="flex flex--justify-content--space-between flex--align-items--center col-11-12">
                <div className="text--overflow">
                  {formattedLicense.length === 1
                    ? formattedLicense[0].name
                    : `${formattedLicense.length} licenses`}
                </div>
                {currentOpenRowId !== licenseQuickviewId ? (
                  <QuickviewClosedIcon className="cursor--pointer color--primary" />
                ) : (
                  <QuickviewOpenIcon className="cursor--pointer color--primary" />
                )}
              </div>
            ) : (
              <div className="flex flex--justify-content--space-between flex--align-items--center col-11-12">
                <div>Unrecognized</div>
                <QuickviewClosedIcon className="disabled color--muted" />
              </div>
            )}
          </div>
          <div className="grid__item col-1-12" data-automation-id="RowCell">
            <div className="pad-left--30">
              <LicenseRiskIcon rating={Rating[risk]} id={rowId} />
            </div>
          </div>
          {showLibraryCatalog && (
            <div
              className={`grid__item color--muted-dark flex flex--align-items--center justify-content--center text--overflow ${columnWidths.commLicense}`}
              data-automation-id="RowCell"
            >
              {isCommLicense && (
                <div>
                  <Tooltip
                    fullWidth={true}
                    place="right"
                    id={`${rowId}-commLicense`}
                    content={`Library marked as commercially licensed by your organization.`}
                  >
                    <img
                      src={checkmark}
                      alt={`checkmark`}
                      width="15"
                      height="15"
                      className="center"
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <VelocityTransitionGroup
          enter={Animations.slideDown_quickView}
          leave={Animations.slideUp_default}
        >
          {currentOpenRowId === dependencyPathQuickviewId && (
            <div className="p- pb0">
              <LoaderWrapper isLoaderShowing={_.isEmpty(dependencyPathQuickviewData) && isLoading}>
                {!_.isEmpty(dependencyPathQuickviewData) && (
                  <ReportExpandedDependencyPath
                    rowId={dependencyPathQuickviewId}
                    component={rowValues}
                    data={dependencyPathQuickviewData}
                  />
                )}
              </LoaderWrapper>
            </div>
          )}

          {currentOpenRowId === vulnsQuickviewId && (
            <div className="p- pb0">
              <LoaderWrapper isLoaderShowing={_.isEmpty(vulnQuickviewData) && isLoading}>
                {!_.isEmpty(vulnQuickviewData) && (
                  <ReportExpandedVulnView
                    data={vulnQuickviewData}
                    onPageClick={this.onQuickviewPageClick}
                    quickviewId={vulnsQuickviewId}
                    quickviewLink={vulnsQuickviewLink}
                  />
                )}
              </LoaderWrapper>
            </div>
          )}

          {currentOpenRowId === licenseQuickviewId && (
            <div className="p- pb0">
              { /* isCommLicense && (
                <div>
                  <div className="font--h5">Commercially Licensed</div>
                  <p className="mt mb">
                    Your organization has marked the library as commercially licensed in the library
                    catalog. Refer to the terms of your licensing agreement to assess the license
                    risk.
                  </p>
                  <hr />
                </div>
              ) */}
              <LoaderWrapper isLoaderShowing={_.isEmpty(licenseQuickviewData) && isLoading}>
                {!_.isEmpty(licenseQuickviewData) && (
                  <ReportExpandedLicense rowId={licenseQuickviewId} />
                )}
              </LoaderWrapper>
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
    myState: state.myState,
    orgState: state.orgState,
    navigationState: state.navigationState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    quickviewActions: bindActionCreators(quickviewActions, dispatch),
    libraryCatalogActions: bindActionCreators(libraryCatalogActions, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportLibrariesRow);
