import { useQuery } from 'react-query';
import { veracodePoliciesService } from '~/constants/ModelConstants';
import config from '~/config';
import ApiService from '~/utils/ApiService';

export function useGetPolicyList() {
  return useQuery('policyList', () =>
    (ApiService as any).get(
      `${config.VERACODE_UIGATEWAY_HOST}${veracodePoliciesService}?sort=created,type,DESC`
    )
  );
}
