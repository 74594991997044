import React from 'react';
import _ from 'lodash';

interface VulnMethodDescriptionProps {
  method: object;
  lineNumber?: number;
  callee?: object;
}

const VulnMethodDescription: React.FunctionComponent<VulnMethodDescriptionProps> = props => {
  const { method, lineNumber, callee } = props;
  const { className, methodName, moduleName, descriptor } = method;
  let lineNumberElement = null,
    calleeElement = null,
    calleeText = '',
    moduleElement,
    replacedClassName = className ? className.replace(/\//g, '.') : '';
  if (_.isEmpty(method)) {
    return null;
  }

  if (moduleName) {
    calleeText = moduleName;
    moduleElement = (
      <div className="grid grid--middle">
        <div className="grid__item col-1-8">Module</div>
        <div className="grid__item col-7-8 word-wrap font-family--code">{moduleName}</div>
      </div>
    );
    if (replacedClassName) {
      calleeText += '.';
    }
  }
  if (replacedClassName) {
    calleeText += replacedClassName + '.';
  }
  calleeText += methodName;

  if (lineNumber) {
    lineNumberElement = (
      <div className="grid grid--middle pt--">
        <div className="grid__item col-1-8">Line Number</div>
        <div className="grid__item col-7-8 word-wrap font-family--code">{lineNumber}</div>
      </div>
    );
  }
  if (callee && callee.methodName && callee.className) {
    calleeElement = (
      <div className="grid grid--middle pt--">
        <div className="grid__item col-1-8">Callee</div>
        <div className="grid__item col-7-8 word-wrap font-family--code">{calleeText}</div>
      </div>
    );
  }

  return (
    <div className="grid grid--full bo--1 p- border-color--muted">
      <div className="grid__item col-1-1 pl0">
        {moduleElement}
        {!!replacedClassName && (
          <div className={`grid grid--middle ${moduleName ? ' pt--' : ''}`}>
            <div className="grid__item col-1-8">Class</div>
            <div className="grid__item col-7-8 word-wrap font-family--code">
              {replacedClassName}
            </div>
          </div>
        )}
        {!!methodName && (
          <div className="grid grid--middle pt--">
            <div className="grid__item col-1-8">Method</div>
            <div className="grid__item col-7-8 word-wrap font-family--code">{methodName}</div>
          </div>
        )}
        {!!descriptor && (
          <div className="grid grid--middle pt--">
            <div className="grid__item col-1-8">Signature</div>
            <div className="grid__item col-7-8 word-wrap font-family--code">{descriptor}</div>
          </div>
        )}

        {lineNumberElement}
        {calleeElement}
      </div>
    </div>
  );
};

export default VulnMethodDescription;
