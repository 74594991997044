import React from 'react';

interface SeverityFlagProps {
  severity: string;
  hideText?: boolean;
}

const SeverityFlag: React.FunctionComponent<SeverityFlagProps> = props => {
  const { severity, hideText } = props;
  const colorBySeverity = {
    HIGH: 'bg-color--danger',
    MEDIUM: 'bg-color--warning',
    LOW: 'bg-color--warning-light',
  };
  const displayTextBySeverity = {
    HIGH: 'High Risk',
    MEDIUM: 'Medium Risk',
    LOW: 'Low Risk',
  };
  const borderColorBySeverity = {
    HIGH: 'border-color--danger',
    MEDIUM: 'border-color--warning',
    LOW: 'border-color--warning-light',
  };

  const color = colorBySeverity[severity];
  const displayText = displayTextBySeverity[severity];

  return (
    <div className={`flex`}>
      <span className={`flex flex--align-self--center ${color} pv--- ph-`}>
        <i className={`color--white text--center font--h6 sci ${color} pv-- sci__vulnerability`} />
      </span>
      {!hideText && (
        <span
          className={`flex align-items--center bo--1 bo-l--0 ph- width--100 font-family--heading color--black bg-color--white ${
            borderColorBySeverity[severity]
          }`}
        >
          {displayText}
        </span>
      )}
    </div>
  );
};

export default SeverityFlag;
