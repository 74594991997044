import { useMutation } from 'react-query';
import ApiService from '~/utils/ApiService';
import config from '~/config';
import { useDispatch } from 'react-redux';
import * as toastrActions from '~/actions/toastr';

export function useSaveWorkspacePolicyDetails(teamId: string) {
  const dispatch = useDispatch();

  return useMutation(
    async (policyGuid: {}) => {
      return ApiService.post(`${config.VC_API_URL}/v1/workspaces/${teamId}/policy`, {
        data: policyGuid,
      });
    },
    {
      mutationKey: 'saveWorkspacePolicy',
      onSuccess: () => {
        const options = {
          id: 'SAVE_SUCCESS',
          title: 'Policy Changed',
          level: 'success',
          message: 'This policy will be used in subsequent scans',
        };
        dispatch(toastrActions.addToastr(options));
      },
      onError: () => {
        const options = {
          id: 'SAVE_ERROR',
          title: 'Unable to Save',
          level: 'error',
          message:
            'There has been an error saving the policy selection for this workspace. Please try again or contact support.',
        };
        dispatch(toastrActions.addToastr(options));
      },
    }
  );
}
