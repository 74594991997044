import React from 'react';
import classNames from 'classnames';

interface VulnBoxProps {
  num: number;
  level?: string;
}

const VulnBox: React.FunctionComponent<VulnBoxProps> = props => {
  const { num = 0, level } = props;
  const vulnBoxCls = classNames({
    'font-family--body text--bold inline-block': true,
    'color--white': true,
    'col-1-1 max-width--30': true,
    'border-color--danger bo--1--hover-color-danger': level === 'HIGH',
    'border-color--warning bo--1--hover-color-warning': level === 'MEDIUM',
    'border-color--warning-light bo--1--hover-color-warning-light': level === 'LOW',
    'bg-color-imp--danger': level === 'HIGH' && num > 0,
    'bg-color-imp--warning': level === 'MEDIUM' && num > 0,
    'bg-color-imp--warning-light': level === 'LOW' && num > 0,
    'bg-color--white-dark border-color--white-dark': num < 1,
    'pv-- bo--1': true,
  });

  return <span className={vulnBoxCls}>{num}</span>;
};

export default VulnBox;
