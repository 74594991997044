import Helpers from '~/utils/Helpers';
import { SOURCECLEAR_COMPONENTS } from '~/constants/ModelConstants';

const OUT_OF_DATE = 'OUT_OF_DATE';
const VULN = 'VULN';
const LICENSE = 'LICENSE';
const JIRA = 'jira';
const GITHUB = 'github';

const IssueCreator = function () {
  const encode = string => {
    return encodeURIComponent(string);
  };

  const getHeader1 = (type, text) => {
    if (type === JIRA) {
      return `h1. ${text}`;
    } else if (type === GITHUB) {
      return `# ${text}`;
    }
  };

  const getHeader2 = (type, text) => {
    if (type === JIRA) {
      return `h4. ${text}`;
    } else if (type === GITHUB) {
      return `## ${text}`;
    }
  };

  const getLink = (type, text, linkTo) => {
    if (type === JIRA) {
      return `[${text}|${linkTo}]`;
    } else if (type === GITHUB) {
      return `[${text}](${linkTo})`;
    }
  };

  const getBold = (type, text) => {
    if (type === JIRA) {
      return `*${text}*`;
    } else if (type === GITHUB) {
      return `**${text}**`;
    }
  };

  const getPointForm = (type, text) => {
    if (type === 'jira') {
      return `* ${text}`;
    } else if (type === GITHUB) {
      return `- ${text}`;
    }
  };

  const getColorForSeverity = (type, text) => {
    if (type === JIRA) {
      let color;
      switch (text.toUpperCase()) {
        case 'LOW':
          color = '#ffca00';
          break;
        case 'MEDIUM':
          color = '#ff8f00';
          break;
        case 'HIGH':
          color = '#f9423a';
      }
      return `{color:${color}}${text}{color}`;
    } else if (type === GITHUB) {
      return text;
    }
  };

  const getLicenseText = (type, licenseCount, link) => {
    if (licenseCount === 1) {
      return `${getLink(
        type,
        `Visit the ${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}`,
        link
      )} to check if there's a non-GPL license version, otherwise consider removing this library`;
    } else if (licenseCount === 0) {
      return `This library has no license. To fix the issue, remove or replace the library with one that does have an acceptable license.`;
    } else {
      return `${getLink(
        type,
        `Visit the ${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}`,
        link
      )} to check if there's a version with only 1 license, otherwise consider removing this library`;
    }
  };

  const getVulnDescription = ({ data, integrationType }) => {
    const { issue = {}, artifact = {} } = data;
    const {
      type: issueType,
      title,
      repoName,
      branch,
      libraryName,
      libraryVersion,
      severity,
      _links: {
        html: { href: link },
      },
      scanDate,
    } = issue;
    const IssueTitle = getSummary(issueType, title, repoName, branch, libraryName, libraryVersion);
    const { overview = '' } = artifact;

    const formattedDescription = `${getHeader1(integrationType, IssueTitle)}

${getHeader2(integrationType, `Issue Details`)}

${getPointForm(integrationType, `${getBold(integrationType, 'Vulnerability')}: ${title}`)}
${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Severity')}: ${getColorForSeverity(
    integrationType,
    Helpers.capFirst(severity)
  )}`
)}
${getPointForm(integrationType, `${getBold(integrationType, 'Project')}: ${repoName}`)}
${getPointForm(integrationType, `${getBold(integrationType, 'Branch')}: ${branch}`)}
${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Scan Date')}: ${Helpers.formatTimestamp(scanDate)}`
)}

${getHeader2(integrationType, `Issue Description`)}
${overview}

${getLink(integrationType, 'View more details', link)}`;
    return formattedDescription;
  };

  const getLicenseDescription = ({ issueLink = '', data, integrationType }) => {
    const { issue = {} } = data;
    const {
      type: issueType,
      title,
      repoName,
      branch,
      libraryName,
      libraryVersion,
      scanDate,
      _links,
      licenseCount,
    } = issue;
    const {
      html: { href: link },
    } = _links;

    const IssueTitle = getSummary(issueType, title, repoName, branch, libraryName, libraryVersion);

    const formattedDescription = `${getHeader1(
      integrationType,
      getLink(integrationType, IssueTitle, issueLink)
    )}

${getHeader2(integrationType, `Issue Details`)}

${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Library')}: ${libraryName} ${libraryVersion}`
)}
${getPointForm(integrationType, `${getBold(integrationType, 'Project')}: ${repoName}`)}
${getPointForm(integrationType, `${getBold(integrationType, 'Branch')}: ${branch}`)}
${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Scan Date')}: ${Helpers.formatTimestamp(scanDate)}`
)}

${getHeader2(integrationType, `Issue Description`)}
${getLicenseText(integrationType, licenseCount, _links.library.href)}

${getLink(integrationType, 'View more details', link)}`;

    return formattedDescription;
  };

  const getOutOfDateDescription = ({ issueLink = '', data, integrationType }) => {
    const { issue = {} } = data;
    const {
      type: issueType,
      title,
      repoName,
      branch,
      libraryName,
      libraryVersion,
      scanDate,
      _links,
    } = issue;
    const {
      html: { href: link },
    } = _links;

    const IssueTitle = getSummary(issueType, title, repoName, branch, libraryName, libraryVersion);

    const formattedDescription = `${getHeader1(
      integrationType,
      getLink(integrationType, IssueTitle, issueLink)
    )}

${getHeader2(integrationType, `Issue Details`)}

${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Library')}: ${libraryName} ${libraryVersion}`
)}
${getPointForm(integrationType, `${getBold(integrationType, 'Project')}: ${repoName}`)}
${getPointForm(integrationType, `${getBold(integrationType, 'Branch')}: ${branch}`)}
${getPointForm(
  integrationType,
  `${getBold(integrationType, 'Scan Date')}: ${Helpers.formatTimestamp(scanDate)}`
)}

${getHeader2(integrationType, `Issue Description`)}
${getLink(
  integrationType,
  `Visit the ${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}`,
  _links.library.href
)} to learn about updating this library

${getLink(integrationType, 'View more details', link)}`;

    return formattedDescription;
  };

  const getJiraSettingsHref = href => {
    if (href.indexOf('http') !== 0) {
      return `http://${href}${href.endsWith('/') ? '' : '/'}secure/CreateIssueDetails!Init.jspa?`;
    }
    return `${href}${href.endsWith('/') ? '' : '/'}secure/CreateIssueDetails!Init.jspa?`;
  };

  const getGithubCreateIssueBaseLink = data => {
    const { issue = {} } = data;
    const { _links = {} } = issue;
    const { scm = {} } = _links;
    const { href: repoUrl = '' } = scm;

    let sanitizedUrl = repoUrl.replace(/\.git$/, '');
    return sanitizedUrl
      ? `${sanitizedUrl}${sanitizedUrl.endsWith('/') ? '' : '/'}issues/new?`
      : null;
  };

  const getSummary = (issueType, title, repoName, branch, libraryName, libraryVersion) => {
    const component = `${libraryName} ${libraryVersion}`;
    switch (issueType) {
      case OUT_OF_DATE:
        return `Outdated library, ${component} in ${repoName} (${branch})`;
      case VULN:
        return `${title} in ${repoName} (${branch})`;
      case LICENSE:
        return `License issue at ${component} in ${repoName} (${branch})`;
    }
  };

  return {
    // Old integration, getting the JIRA create issue url
    getJiraHref: function (data) {
      const { pid, issueType, jiraUrl } = data;
      const summary = this.getIssueSummary(data, 'jira');
      const description = this.getIssueDescription({ data, integrationType: 'jira' });
      const queryParams = {
        pid: encode(pid),
        issuetype: encode(issueType),
        summary: encode(summary),
        description: encode(description),
      };
      const encodedParamString = Object.keys(queryParams)
        .map(param => `${param}=${queryParams[param]}`)
        .join('&');
      const baseUrl = getJiraSettingsHref(jiraUrl);
      return baseUrl && encodedParamString ? baseUrl + encodedParamString : null;
    },
    // Old integration, getting the GITHUB ceate issue url
    getGitHubHref: function (data) {
      const summary = this.getIssueSummary(data, 'github');
      const description = this.getIssueDescription({ data, integrationType: 'github' });
      const queryParams = {
        title: encode(summary),
        body: encode(description),
      };
      const encodedParamString = Object.keys(queryParams)
        .map(param => `${param}=${queryParams[param]}`)
        .join('&');
      const baseUrl = getGithubCreateIssueBaseLink(data);
      return baseUrl && encodedParamString ? baseUrl + encodedParamString : null;
    },
    getIssueSummary: function (data) {
      const { issue = {} } = data;
      const { type: issueType, title, repoName, branch, libraryName, libraryVersion } = issue;
      return getSummary(issueType, title, repoName, branch, libraryName, libraryVersion);
    },
    getIssueDescription: function ({ issueLink = '', data, integrationType }) {
      const { type: issueType } = data.issue;
      switch (issueType) {
        case OUT_OF_DATE:
          return getOutOfDateDescription({ issueLink, data, integrationType });
        case VULN:
          return getVulnDescription({ issueLink, data, integrationType });
        case LICENSE:
          return getLicenseDescription({ issueLink, data, integrationType });
      }

      return '';
    },
  };
};

export default new IssueCreator();
