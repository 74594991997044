import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import * as MODEL from '~/constants/ModelConstants';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import NavigationService from '~/utils/NavigationService';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportHeader from '~/components/ReportComponents/ReportHeader';
import ReportBooleanFilter from '~/components/ReportComponents/ReportBooleanFilter';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import LoaderWrapper from '~/components/LoaderWrapper';
import Tooltip from '~/components/Tooltip';
import InsightsWrapper from '~/components/InsightsWrapper';
import IssuesInsightsBar from '~/components/IssuesInsightsBar';
import * as insightsActions from '~/actions/insights';

import VulnerabilitiesRow from '~/containers/VulnerabilitiesRow';
import CSVReportDownloader from '~/containers/CSVReportDownloader';

import * as navigationActions from '~/actions/navigation';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import * as upgradeModalActions from '~/actions/upgradeModal';

import {
  VulnerabilitiesPageProps,
  VulnerabilitiesPageMatchParams,
} from '~/containers/VulnerabilitiesPage.types';
import { Severity, IssueInsightSeverity } from '~/components/IssuesInsightsBar.types';

const REPORT_TYPE = 'VULNERABILITIES';

class VulnerabilitiesPage extends Component<
  VulnerabilitiesPageProps & ReturnType<typeof mapDispatchToProps>
> {
  async componentDidMount() {
    const { navigationState = {}, match } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { teamId, projectId } = params;
    const prevLocationPathName = NavigationService.getPreviousLocationPathname(navigationState);
    // We will not fetch any data if we came over from issue vulnerability detail page.
    if (!prevLocationPathName.includes('/issues/vulnerabilities/')) {
      this.refreshReportData();
    }

    if (projectId) {
      this.props.reportFilterActions.initProjectFilterDefaults(projectId);
      this.props.sortByReportTypeActions.initProjectSortDefaults(projectId);
    }

    this.props.insightsActions.fetchVulnerabilityInsights(teamId);

    await this.props.navigationActions.updateActiveReportType(REPORT_TYPE);
  }

  componentWillUnmount() {
    this.props.navigationActions.updateActiveReportType();
  }

  toggleCtaVulnModalOpen = () => {
    const { match } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_VULN_METHODS, teamId);
  };

  updateSortField = field => {
    const { match } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { projectId } = params;

    this.props.sortByReportTypeActions.updateSortField(REPORT_TYPE, field, projectId);
    this.refreshReportData();
  };

  refreshReportData = () => {
    const { match } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { teamId } = params;
    this.props.reportActions.fetchReport(teamId, REPORT_TYPE);
  };

  toggleBooleanFilter = (field, isChecked) => {
    const { match } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { projectId } = params;
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, isChecked, projectId);
    this.refreshReportData();
  };

  render() {
    const {
      reportFilterState,
      reportsByType,
      reports,
      sortByReportType,
      match,
      reportScope,
      repoDataById,
      orgState,
      insightsByType,
    } = this.props;
    const { params }: { params: VulnerabilitiesPageMatchParams } = match;
    const { projectId } = params;
    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const { repos } = reportScope;
    const isSingleRepoScope = _.isArray(repos) && repos.length == 1;
    const { isFetching, errorMessage } = reports;
    const { [REPORT_TYPE]: vulnsData = {} } = reportsByType;
    const { _embedded = {}, page = {} } = vulnsData;
    const { vulns: vulnerabilities = [] } = _embedded;
    const { VULNERABILITIES: issueInsights = {}, isFetchingInsights } = insightsByType;

    const { highSeverity = null, mediumSeverity = null, lowSeverity = null } = issueInsights;
    const filters =
      projectId && reportFilterState[projectId]
        ? reportFilterState[projectId][REPORT_TYPE] || {}
        : reportFilterState[REPORT_TYPE] || {};
    const { vulnMethodsOnly = false, highSeverityOnly = false } = filters;
    const currentSort =
      projectId && sortByReportType[projectId]
        ? sortByReportType[projectId][REPORT_TYPE] || {}
        : sortByReportType[REPORT_TYPE] || {};

    const columnWidths = {
      severity: 'col-1-12',
      vulnId: 'col-1-9',
      vulnerability: 'col-1-3',
      library: 'col-1-5',
      version: 'col-1-9',
      vulnMethods: '',
    };

    const vulnerabilitiesBySeverity: IssueInsightSeverity[] = [
      {
        id: Severity.High,
        name: 'High',
        count: highSeverity,
      },
      {
        id: Severity.Medium,
        name: 'Medium',
        count: mediumSeverity,
      },
      {
        id: Severity.Low,
        name: 'Low',
        count: lowSeverity,
      },
    ];

    const isProjectDetailsPage = !!projectId;

    const { [projectId]: projectData = {} } = repoDataById;
    const { type: projectType = '' } = projectData;

    // We are in a container project if this component is loaded in project details page which type is container
    const isContainerProject =
      isProjectDetailsPage && projectType.toUpperCase() === MODEL.PROJECT_TYPES.CONTAINER;

    const vulnerabilityRows = vulnerabilities.map((vulnerability, index) => {
      const rowId = `${vulnerability.id}-${index}`;
      return (
        <VulnerabilitiesRow
          isPaidOrTrialing={isPaidOrTrialing}
          data={vulnerability}
          key={rowId}
          rowId={rowId}
          params={params}
          isSingleRepoScope={isSingleRepoScope}
          columnWidths={columnWidths}
          isContainerProject={isContainerProject}
        />
      );
    });

    const reportHeaderRow = (
      <ReportHeaderRow>
        <ReportHeaderItem
          label="Severity"
          field="severity"
          isSortable={true}
          onClick={this.updateSortField}
          currentSort={currentSort}
          widthClass={`${columnWidths.severity}`}
        />
        <ReportHeaderItem
          label="Vulnerability ID"
          field="vulnId"
          widthClass={`${columnWidths.vulnId}`}
        />
        <ReportHeaderItem
          label="Vulnerability"
          field="name"
          isSortable={true}
          onClick={this.updateSortField}
          currentSort={currentSort}
          widthClass={`${columnWidths.vulnerability}`}
        />
        <ReportHeaderItem label="Library" field="library" widthClass={columnWidths.library} />
        <ReportHeaderItem label="Version" field="version" widthClass={`${columnWidths.version}`} />
        {!isContainerProject && (
          <ReportHeaderItem label="Vulnerable Methods" field="vulnMethods" alignment="CENTER" />
        )}
        {!isSingleRepoScope && (
          <ReportHeaderItem label="Projects" field="repos" alignment="CENTER" />
        )}
      </ReportHeaderRow>
    );
    const reportHeader = (
      <ReportHeader
        reportType={REPORT_TYPE}
        stringFilterPlaceholder={'Search vulnerabilities'}
        renderReportHeaderItems={() => (
          <div className="grid__item col-1-1 flex flex--justify-content--end align-items--center p-- height--40">
            <Tooltip
              content="Only show vulnerabilities with vulnerable methods"
              id="vulns-only-filter"
            >
              {!isContainerProject && (
                <ReportBooleanFilter
                  automationId="VulnerabilitiesPage-vulnMethodsOnly"
                  showUpgradeModalIfClicked={!isPaidOrTrialing}
                  callToAction={this.toggleCtaVulnModalOpen}
                  label={'Vulnerable methods'}
                  field={'vulnMethodsOnly'}
                  isChecked={vulnMethodsOnly}
                  onClick={this.toggleBooleanFilter}
                />
              )}
            </Tooltip>
            <div className="pl-">
              <Tooltip
                content="Only show vulnerabilities with high risk"
                id="high-risk-only-filter"
              >
                <ReportBooleanFilter
                  automationId="VulnerabilitiesPage-highSeverityOnly"
                  label={'High risk only'}
                  field={'highSeverityOnly'}
                  isChecked={highSeverityOnly}
                  onClick={this.toggleBooleanFilter}
                />
              </Tooltip>
            </div>
            <CSVReportDownloader
              reportType={REPORT_TYPE}
              page={page}
              noElementText="No vulnerability to download"
            />
          </div>
        )}
        renderSearchResultsMetadata={() => (
          <span>
            {' '}
            {page.totalElements} {page.totalElements === 1 ? 'vulnerability' : 'vulnerabilities'}{' '}
          </span>
        )}
        renderReportHeaderRow={() => <Fragment>{reportHeaderRow}</Fragment>}
      />
    );

    if (errorMessage) {
      return (
        <div className="grid pt grid--center">
          {reportHeader}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage />
          </div>
        </div>
      );
    }

    return (
      <div className="grid mb+">
        {projectId ? (
          <Helmet>
            <title>Project Vulnerabilities</title>
          </Helmet>
        ) : (
          <Helmet>
            <title>Vulnerabilities</title>
          </Helmet>
        )}
        {!projectId && (
          <div id="insightWrapperVulns" className="grid__item col-1-1 mb+">
            <div className="col-1-1 font--h6 mb--">INSIGHTS</div>
            <InsightsWrapper>
              <IssuesInsightsBar
                title="Vulnerabilities by Severity"
                content={vulnerabilitiesBySeverity}
                isFetchingInsights={isFetchingInsights}
              />
            </InsightsWrapper>
          </div>
        )}
        {!projectId && (
          <div className="grid__item col-1-1">
            <div className="font--h6 mb--" data-automation-id="VulnerabilitiesPage-Title">
              VULNERABILITY LIST
            </div>
          </div>
        )}
        {reportHeader}

        <div className="grid__item col-1-1 mt--">
          <LoaderWrapper isLoaderShowing={isFetching && !page.totalPages}>
            <div data-automation-id="ReportRows">{vulnerabilityRows}</div>
            {vulnerabilities.length === 0 && (
              <h3 className="ml color--muted mt+"> No vulnerabilities found. </h3>
            )}
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sortByReportType: state.sortByReportType,
    repoDataById: state.repoDataById,
    reportFilterState: state.reportFilterState,
    reportsByType: state.reportsByType,
    reports: state.reports,
    reportScope: state.reportScope,
    navigationState: state.navigationState,
    orgState: state.orgState,
    insightsByType: state.insightsByType,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    insightsActions: bindActionCreators(insightsActions as any, dispatch),
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    reportActions: bindActionCreators(reportActions as any, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions as any, dispatch),
    sortByReportTypeActions: bindActionCreators(sortByReportTypeActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VulnerabilitiesPage);
