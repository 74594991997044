import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import VCTabs from '~/views/components/veracode/VCTabs';
import vcPlatformFavicon from '~/images/vcPlatformFavicon.png';
import VCHeader from '~/views/components/veracode/VCHeader';
import VCFooter from '~/views/containers/veracode/VCFooter';
import VCStartScanFooter from '~/components/VCStartScanFooter';
import LoaderWrapper from '~/components/LoaderWrapper';
import(/* webpackChunkName: "veracode" */ '../styles/veracode/styles.scss');
import(/* webpackChunkName: "glyphicons" */ '../styles/veracode/glyphicons.css');
import ErrorBoundary from '~/components/ErrorBoundary';
import * as MODEL from '~/constants/ModelConstants';

interface VCPageProps extends RouteComponentProps {
  vcMyState?: object;
  organization: object;
  render?: (...args: any[]) => any;
  vcNavState?: object;
}

class VCPage extends Component<VCPageProps> {
  onCancelStartScan() {
    window.history.go(-1);
  }
  render() {
    const {
      history,
      vcNavState: { navigation },
      vcMyState: { permissions },
    } = this.props;
    const {
      location: { pathname },
    } = history;

    const { startVeracodeStaticScan, orgAgents, workspaceAgents, scaUpload } = permissions;
    const { platform: platformBaseUrl } = navigation;
    const scaUrl = platformBaseUrl + MODEL.veracodeScaUrl;
    const vcStartScanPageIsOpen = pathname === '/start-scan';
    const startScanButtonIsVisible = startVeracodeStaticScan || orgAgents || workspaceAgents;
    const vcPageContent = !scaUpload ? (
      <div className="container flex flex--content">
        <ErrorBoundary>{this.props.render()} </ErrorBoundary>
      </div>
    ) : (
      <VCTabs history={history} scaUrl={scaUrl}>
        <div id="upload-and-scan" label="Upload and Scan">
          <LoaderWrapper className="grid__item col-1-1 flex" isLoaderShowing={true}>
            {''}
          </LoaderWrapper>
        </div>
        <div id="agent-based-scan" label="Agent-Based Scan">
          <ErrorBoundary>{this.props.render()} </ErrorBoundary>
        </div>
      </VCTabs>
    );

    return (
      <Fragment>
        <Helmet titleTemplate="%s : Veracode Platform" defaultTitle="Veracode Platform">
          <link rel="icon" type="image/png" href={vcPlatformFavicon} sizes="32x32" />
          <title>Veracode Platform</title>
        </Helmet>
        <VCHeader
          platformBaseUrl={platformBaseUrl}
          startScanButtonIsVisible={startScanButtonIsVisible}
          vcStartScanPageIsOpen={vcStartScanPageIsOpen}
          vcStartScanHeaderTitle={
            vcStartScanPageIsOpen
              ? 'Start a Scan: Software Composition Analysis'
              : 'Software Composition Analysis'
          }
        />

        <div className="vcBody">
          {!vcStartScanPageIsOpen ? (
            vcPageContent
          ) : (
            <ErrorBoundary>{this.props.render()}</ErrorBoundary>
          )}
        </div>

        {!vcStartScanPageIsOpen ? (
          <VCFooter />
        ) : (
          <VCStartScanFooter handleCancel={() => this.onCancelStartScan()} />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { vcNavState: state.vcNavState, vcMyState: state.vcMyState };
}

export default withRouter(connect(mapStateToProps)(VCPage));
