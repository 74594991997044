import Raven from 'raven-js';
import { UserContext } from './UtilsTypes/types';
// Service to send errors to Sentry
class ErrorService {
  capture(title: string, extra?: unknown) {
    Raven.captureException(new Error(title), { extra });
  }
  setUserContext(context: UserContext = {}) {
    Raven.setUserContext(context);
  }
  lastEventId() {
    return Raven.lastEventId();
  }
}

export default new ErrorService();
