import React from 'react';

import { PolicyDetail } from '~/types/UnifiedPolicy';

interface GracePeriodProps {
  policyDetails: PolicyDetail;
}

const GracePeriod: React.FunctionComponent<GracePeriodProps> = props => {
  const policyDetails = props.policyDetails;

  const {
    sca_grace_periods,
    sca_blacklist_grace_period,
    sev0_grace_period,
    sev1_grace_period,
    sev2_grace_period,
    sev3_grace_period,
    sev4_grace_period,
    sev5_grace_period,
    score_grace_period,
  } = policyDetails;

  const scaBlacklistGracePeriod = sca_grace_periods
    ? sca_grace_periods.sca_blacklist_grace_period
    : sca_blacklist_grace_period;

  function hasCvssScores(): boolean {
    return !!sca_grace_periods && sca_grace_periods?.cvss_score_grace_period?.length > 0;
  }

  function hasSeverity(): boolean {
    return (
      sev0_grace_period > 0 ||
      sev1_grace_period > 0 ||
      sev2_grace_period > 0 ||
      sev3_grace_period > 0 ||
      sev4_grace_period > 0 ||
      sev5_grace_period > 0
    );
  }

  function hasGracePeriods() {
    const allScores =
      score_grace_period +
      sev0_grace_period +
      sev1_grace_period +
      sev2_grace_period +
      sev3_grace_period +
      sev4_grace_period +
      sev5_grace_period;

    return (
      hasSeverity() ||
      hasCvssScores() ||
      sca_grace_periods?.license_risk_grace_period > 0 ||
      allScores > 0 ||
      scaBlacklistGracePeriod > 0
    );
  }

  function daysLabel(days: number): string {
    return days === 1 ? 'day' : 'days';
  }

  function addFormat(val): string {
    let ret = val;

    const myVal = Math.trunc(val * 10 + 0.01) / 10;
    ret = (myVal as unknown) as string;

    if (myVal === Math.trunc(myVal)) {
      ret += '.0';
    }

    return ret;
  }

  return (
    <span>
      {hasGracePeriods() && (
        <div id="gracePeriodGroup" className="policyGroup">
          <div className="policyTitleRow">
            <div className="mtitle" data-automation-id="PolicyDetails-gracePeriodsTitle">
              Grace Periods
            </div>
          </div>
          <div className="policyGroupContent">
            {score_grace_period > 0 && (
              <div id="scoreGracePeriod" className="policy-grace-period">
                <span>Minimum Scan Score: </span>
                <span className="text--bold">
                  {score_grace_period}&nbsp;
                  {daysLabel(score_grace_period)}
                </span>
              </div>
            )}
            {hasSeverity() && (
              <span className="policy-grace-period">
                Findings by Severity, Security Standard, Findings by CWE ID, Findings in CWE
                Category
              </span>
            )}
            {sev5_grace_period > 0 && (
              <div id="sev5GracePeriod" className="policy-grace-period">
                <span className="indent">Very High:</span>
                <span className="text--bold">
                  {sev5_grace_period}&nbsp;
                  {daysLabel(sev5_grace_period)}
                </span>
              </div>
            )}
            {sev4_grace_period > 0 && (
              <div id="sev4GracePeriod" className="policy-grace-period">
                <span className="indent">High:</span>
                <span className="text--bold">
                  {sev4_grace_period}&nbsp;
                  {daysLabel(sev4_grace_period)}
                </span>
              </div>
            )}
            {sev3_grace_period > 0 && (
              <div id="sev3GracePeriod" className="policy-grace-period">
                <span className="indent">Medium:</span>
                <span className="text--bold">
                  {sev3_grace_period}&nbsp;
                  {daysLabel(sev3_grace_period)}
                </span>
              </div>
            )}
            {sev2_grace_period > 0 && (
              <div id="sev2GracePeriod" className="policy-grace-period">
                <span className="indent">Low:</span>
                <span className="text--bold">
                  {sev2_grace_period}&nbsp;
                  {daysLabel(sev2_grace_period)}
                </span>
              </div>
            )}
            {sev1_grace_period > 0 && (
              <div id="sev1GracePeriod" className="policy-grace-period">
                <span className="indent">Very Low:</span>
                <span className="text--bold">
                  {sev1_grace_period}&nbsp;
                  {daysLabel(sev1_grace_period)}
                </span>
              </div>
            )}
            {sev0_grace_period > 0 && (
              <div id="sev0GracePeriod" className="policy-grace-period">
                <span className="indent">Informational:</span>
                <span className="text--bold">
                  {sev0_grace_period}&nbsp;
                  {daysLabel(sev0_grace_period)}
                </span>
              </div>
            )}
            {scaBlacklistGracePeriod > 0 && (
              <div id="blockListEnforcement" className="policy-grace-period">
                <span>Component Blocklist Enforcement:</span>
                <span className="text--bold">
                  {scaBlacklistGracePeriod}&nbsp;{daysLabel(scaBlacklistGracePeriod)}
                </span>
              </div>
            )}
            {sca_grace_periods && sca_grace_periods.license_risk_grace_period > 0 && (
              <div id="componentLicenseRisk" className="policy-grace-period">
                <span>Component License Risk:</span>
                <span className="text--bold">
                  {sca_grace_periods.license_risk_grace_period}&nbsp;
                  {daysLabel(sca_grace_periods.license_risk_grace_period)}
                </span>
              </div>
            )}
            {hasCvssScores() && (
              <span>
                <div id="cvssScore" className="policy-grace-period">
                  <span>Vulnerability CVSS Score:</span>
                </div>
                <table id="dateRange" className="policyGraceTable">
                  <tbody>
                    {sca_grace_periods.cvss_score_grace_period.map(range => {
                      return (
                        <tr className="policy-grace-period" key={range.lower}>
                          <td>
                            {addFormat(range.lower)} - {addFormat(range.upper)}
                          </td>
                          <td className="text--bold">
                            {range.days} {daysLabel(range.days)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </span>
            )}
          </div>
        </div>
      )}
    </span>
  );
};

export default GracePeriod;
