import _ from 'lodash';
import moment from 'moment';
import cookie from 'react-cookie';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import httpAdapter from 'axios/lib/adapters/http';

import config from '~/config';
import { xsrfCookieName, VERACODE_SESSION_TIMEOUT_COOKIE_KEY } from '~/constants/ModelConstants';
import AuthService from './AuthService';
import ErrorService from './ErrorService';
import Helpers from '~/utils/Helpers';

const isVeracodeDomain = _.includes(config.VERACODE_DOMAINS, window.location.hostname);

export const instance = axios.create({
  baseURL: isVeracodeDomain ? config.VC_API_URL : config.API_URL,
  withCredentials: true,
  adapter: httpAdapter,
});

export function formatUrl(path: string, isVeracodeDomain: boolean) {
  if (path.startsWith('http')) {
    return path;
  }
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  const url = isVeracodeDomain ? config.VC_API_URL : config.API_URL;
  return url + adjustedPath;
}

let errorHandler = (err, request) => {
  if (err.response) {
    err.status = err.response.status;
  }

  if (err.status === 401 && (!request || !request.url.includes('/login'))) {
    // if user ever gets a 401, it means they're not logged in
    // and should be sent to /login with the message that their session has expired
    AuthService.setAuthToken(null).then(() => {
      window.location.href = '/login?autoLogout=true';
    });
  }

  ErrorService.capture(err, { err });
  return err;
};

const publishPeformanceData = (start: moment.Moment, path: string) => {
  const endTime = moment();
  const timeDiff = endTime.diff(start);
  const performanceData = {
    name: 'XHR_PERFORMANCE_DATA',
    filterField: path || '',
    filterValue: timeDiff.toString(),
  };

  if (timeDiff >= (config.XHR_THRESHOLD || 3000) && window.snowplow) {
    window.snowplow('trackUnstructEvent', {
      schema: 'iglu:com.srcclr/fe_snowplow_events/jsonschema/1-0-3',
      data: performanceData,
    });
  }
};

//request interceptor
instance.interceptors.request.use(
  async requestConfig => {
    const tokenHeader = 'x-auth-token';

    if (requestConfig.headers && !(tokenHeader in requestConfig.headers)) {
      const token = AuthService.getAuthToken();
      if (token) {
        requestConfig.headers[tokenHeader] = token;
      }
    }

    if (requestConfig.url.endsWith('type=pdf')) {
      requestConfig.headers['Accept'] = 'application/pdf';
      requestConfig.responseType = 'blob';
    } else {
      requestConfig.headers['Accept'] = 'application/json';
    }

    if (isVeracodeDomain) {
      requestConfig.headers['X-XSRF-TOKEN'] = cookie.load(xsrfCookieName);
      let sessionTimeout = cookie.load(VERACODE_SESSION_TIMEOUT_COOKIE_KEY);
      const validSession = moment().isBefore(sessionTimeout);

      if (!validSession) {
        // If the sessionTimeout cookie appears to have expired, make a request to uigateway
        // to check if there is still a valid session. It is possible for sessionTimeout
        // from the uigateway to be extended beyond what's in the cookie. For instance,
        // when we ping an identity endpoint GET /v2/principal, we shift sessionTimeout further.
        // This simply verifies if an extension has happened or timeout is the same as what's in the cookie.
        return await Helpers.checkVeracodeSessionTimeout(requestConfig);
      }
    }

    return requestConfig;
  },
  error => {
    return Promise.reject(errorHandler(error, error.config));
  }
);

//response interceptor
instance.interceptors.response.use(
  (res: AxiosResponse) => {
    let data = res.data;
    const path = res.config.url;

    if (path.includes('/signup') || path.includes('/login') || path.includes('/user-status')) {
      if (res.headers && res.headers['x-auth-token']) {
        // if the response contains an x-auth-token, we need to set (or reset) the session token cookie
        const token = res.headers['x-auth-token'];
        AuthService.setAuthToken(token);
      }

      // signup and login are special in that we want to redirect in some cases
      data = data || {};
      data.authToken = res.headers['x-auth-token'];
    }

    return data;
  },
  async (error: AxiosError) => {
    if (isVeracodeDomain) {
      await Helpers.checkVeracodeSessionTimeout(error.config);
    }
    return Promise.reject(errorHandler(error, error.config));
  }
);

class ApiService {
  getV2<T>(path: string, config?: AxiosRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .get<never, T>(formatUrl(path, isVeracodeDomain), config)
        .then(response => {
          publishPeformanceData(startTime, path);
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }
  /**
   * @deprecated use getV2 for proper typing support
   */
  get(path: string, { params = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .get(formatUrl(path, isVeracodeDomain), {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  post(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .post(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  put(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .put(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  patch(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .patch(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  del(path: string, { params = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .delete(formatUrl(path, isVeracodeDomain), {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }
}

export default new ApiService();
