import React, { useState } from 'react';

interface VCReadMoreProps {
  moreText?: string;
  lessText?: string;
  limit?: number;
  isChar?: boolean;
  isWords?: boolean;
  lineCount?: number;
  ellipsis?: string;
  isEmbedded?: boolean;
  content: string;
}

const VCReadMore: React.FunctionComponent<VCReadMoreProps> = props => {
  const {
    content,
    ellipsis = '',
    limit = 150,
    moreText = 'Show More...',
    lessText = 'Show Less',
    isEmbedded = false,
    isChar,
    isWords,
  } = props;

  const [isOverLimit, setIsOverLimit] = useState(true);
  const [isLimitInChar, setIsLimitInChar] = useState(true);
  const [lineCount, setLineCount] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [displayContent, setDisplayContent] = useState(content);

  function setCharLimitAndLineCount() {
    if (isChar !== undefined && !isChar) {
      setIsLimitInChar(false);
    }
    if (isWords !== undefined && isWords) {
      setIsLimitInChar(false);
    }
    if (props.lineCount && props.lineCount > 0) {
      setLineCount(lineCount);
      setIsLimitInChar(false);
    }
  }

  function getDisplayContent(isCollapsed: boolean) {
    if (content != null) {
      setIsOverLimit(computeOverLimit(content));
      if (isOverLimit) {
        return isCollapsed ? getLimitedContent() : content;
      }
    }
    return content;
  }

  function computeOverLimit(content: string) {
    if (isLimitInChar) {
      return content.length > limit;
    } else if (lineCount < 1) {
      return content.split(/\s+/).length > limit;
    } else {
      return content.split(/\n/).length > lineCount;
    }
  }

  function getLimitedContent(): string {
    if (isLimitInChar) {
      return content.substring(0, limit);
    } else if (lineCount < 1) {
      return content.split(/\s+/).slice(0, limit).join(' ');
    } else {
      return content.split(/\n/).slice(0, lineCount).join('\n');
    }
  }

  //Had to use React.useEffect for the test to work.
  React.useEffect(
    () => {
      setCharLimitAndLineCount();
      setDisplayContent(getDisplayContent(true));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function changeCollapse() {
    setDisplayContent(getDisplayContent(!isCollapsed));
    setIsCollapsed(!isCollapsed);
  }

  return (
    <span className="vcReadMore">
      <p className={`vc-read-more-p ${isEmbedded ? 'embedded' : ''}`}>
        <span className="content">{displayContent}</span>
        {isCollapsed && ellipsis && <span>&nbsp;</span>}
        {isCollapsed && isOverLimit && <span className="ellipsis">{ellipsis}</span>}
        <span>&nbsp;</span>
        {lineCount > 0 && (
          <span>
            <br />
          </span>
        )}
        {isCollapsed && isOverLimit && <a onClick={changeCollapse}>{moreText}</a>}
        {!isCollapsed && <a onClick={changeCollapse}>{lessText}</a>}
      </p>
    </span>
  );
};

export default VCReadMore;
