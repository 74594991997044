import { useQuery } from 'react-query';
import config from '~/config';
import ApiService from '~/utils/ApiService';
import { veracodeCwesService } from '~/constants/ModelConstants';

export function useGetCwesList() {
  return useQuery(
    'cweList',
    () => (ApiService as any).get(`${config.VERACODE_UIGATEWAY_HOST}${veracodeCwesService}`),
    {
      staleTime: 300000, // Caching the data for 5 minutes if the user is in the same page
    }
  );
}
