import React from 'react';
import SourceClearModal from '~/components/SourceClearModal';
import { useGetCategoryList } from '~/hooks/categoryList';
import { useGetCwesList } from '~/hooks/cweList';
import { useGetPolicyDetails } from '~/hooks/policyDetails';
import { useGetPolicyAppList } from '~/hooks/policyApplicationList';
import Helpers from '~/utils/Helpers';
import RuleList from '~/components/UnifiedPolicyDetailsPage/RuleList';
import GracePeriod from '~/components/UnifiedPolicyDetailsPage/GracePeriod';
import CustomSeverity from '~/components/UnifiedPolicyDetailsPage/CustomSeverity';
import {
  CategoryRow,
  CweRow,
  findingScanTypeMap,
  scanFrequencies,
} from '~/components/UnifiedPolicyDetailsPage/PolicyDetailsConstants';
import { useSelector } from 'react-redux';

interface PolicyDetailModalProps {
  isOpen: boolean;
  toggle: () => void;
  policyId: string;
}

const PolicyDetailModal: React.FunctionComponent<PolicyDetailModalProps> = props => {
  const { status: clStatus, data: categories } = useGetCategoryList();
  const { status: cweStatus, data: cwes } = useGetCwesList();

  const { status: policyDetailStatus, data: policyDetail } = useGetPolicyDetails(props.policyId);
  const { status: policyAppStatus, data: policyAppList } = useGetPolicyAppList(props.policyId);

  const { myState } = useSelector(state => state as { [key: string]: any });

  const { me = {} } = myState;
  const { permissions: mePermissions = {} } = me;
  const { enableComponentPolicies = false, evaluationTimeShow = false } = mePermissions;

  return (
    <div>
      {policyDetailStatus === 'error' && (
        <SourceClearModal
          isOpen={props.isOpen}
          onClose={() => props.toggle()}
          title={' Error getting details '}
          width={600}
          closeWhenClickOutside={false}
        />
      )}

      {policyDetailStatus === 'success' && (
        <SourceClearModal
          isOpen={props.isOpen}
          onClose={() => props.toggle()}
          title={policyDetail && policyDetail.name ? policyDetail.name : ' '}
          width={600}
          closeWhenClickOutside={false}
        >
          <div id="policyDetailDialog">
            <div className="policy-modal-content">
              <div id="policy-modal_body" className="policy-modal-body">
                <div className="modal-body-limitHeight">
                  {/*Basic Policy Group*/}
                  <div id="policyBasicGroup" className="policyGroup">
                    <div className="policyTitleRow">
                      <div
                        id="policyBasicTitle"
                        className="mtitle"
                        data-automation-id="PolicyDetails-basicPolicyTitle"
                      >
                        Policy Basics
                      </div>
                    </div>
                    <div className="policyGroupContent">
                      {enableComponentPolicies && (
                        <div id="category" className="policyLabelValue">
                          <span className="policyLabel  text--bold">Category:</span>
                          {policyDetail.category === 'COMPONENT' ? (
                            <span id="thirdParty" className="policyValue">
                              Third-Party Library Policy
                            </span>
                          ) : (
                            <span id="application" className="policyValue">
                              Application Policy
                            </span>
                          )}
                        </div>
                      )}
                      <div id="description" className="policyLabelValue">
                        <span className="policyLabel  text--bold">Description:</span>
                        <span id="descriptionValue" className="policyValue">
                          {policyDetail.description}
                        </span>
                      </div>
                      <div id="vendorPolicy" className="policyLabelValue">
                        <span className="policyLabel  text--bold">Vendor Policy:</span>
                        {policyDetail.vendor_policy ? (
                          <span id="vendorPolicyYes" className="policyValue">
                            Yes
                          </span>
                        ) : (
                          <span id="vendorPolicyNo" className="policyValue">
                            No
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Rule List */}
                  {clStatus && ( // Waiting for the Category to loaded
                    <RuleList
                      cweCategories={
                        categories ? (categories._embedded.categories as CategoryRow[]) : []
                      }
                      findingRules={policyDetail.finding_rules}
                      category={policyDetail.category}
                    />
                  )}

                  {/* Evaluation time */}
                  {policyDetail.evaluation_date && evaluationTimeShow && (
                    <div id="evalTimeGroup" className="policyGroup">
                      <div className="policyTitleRow">
                        <div
                          id="evalTimeTitle"
                          className="mtitle"
                          data-automation-id="PolicyDetails-evalTime"
                        >
                          Evaluation Timeframe
                        </div>
                      </div>
                      <div className="policyGroupContent">
                        {policyDetail.evaluation_date_type === 'AFTER' && (
                          <span id="evalTimeAfter">
                            Applications do not pass policy if findings that violate rules are
                            opened or reopened on or after
                          </span>
                        )}
                        {policyDetail.evaluation_date_type === 'BEFORE' && (
                          <span id="evalTimeBefore">
                            Applications do not pass policy if findings that violate rules are
                            opened or reopened before
                          </span>
                        )}
                        <span id="evalTimeDate" className=" text--bold">
                          &nbsp; {Helpers.formatTime(policyDetail.evaluation_date, 'D MMM YYYY')}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Grade Period */}
                  <GracePeriod policyDetails={policyDetail} />

                  {/* Customer Severities  */}
                  {cweStatus === 'success' && ( // Display only if the CWE list is success
                    <CustomSeverity
                      policyDetails={policyDetail}
                      cweSeverity={cwes ? (cwes._embedded.cwes as CweRow[]) : []}
                    />
                  )}

                  {/* Scan Requirements */}
                  {policyDetail.scan_frequency_rules &&
                    policyDetail.scan_frequency_rules.length > 0 && (
                      <div id="scanRequirementGroup" className="policyGroup">
                        <div className="policyTitleRow">
                          <div
                            id="scanRequirementTitle"
                            className="mtitle"
                            data-automation-id="PolicyDetails-scanRequirement"
                          >
                            Scan Requirements
                          </div>
                        </div>
                        {policyDetail.scan_frequency_rules.map(scan => {
                          {
                            const freq = scanFrequencies.find(f => f.value === scan.frequency);
                            scan.frequency_label = freq ? freq.label : scan.frequency;
                            const scanType = findingScanTypeMap.get(scan.scan_type);
                            scan.scan_type_label = scanType ? scanType.label : scan.scan_type;
                          }
                          return (
                            <div
                              key={scan.scan_type}
                              className="policy-grace-period"
                              id={scan.scan_type}
                            >
                              <span>{scan.scan_type_label}:</span>
                              <span className="text--bold">{scan.frequency_label}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  {/* Application List */}
                  {policyAppStatus === 'success' &&
                    policyAppList._embedded &&
                    policyAppList._embedded.applications && (
                      <div id="policyAppGroup">
                        <div className="policyTitleRow">
                          <div
                            id="policyAppTitle"
                            className="mtitle"
                            data-automation-id="PolicyDetails-policyApp"
                          >
                            Applications with this Policy
                          </div>
                        </div>
                        <div id="appListInfo">
                          This policy is currently assigned to&nbsp;
                          {policyAppList._embedded.applications.length} applications:
                        </div>
                        {policyAppList._embedded.applications.map(application => {
                          return <div key={application.id}>{application.profile.name}</div>;
                        })}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div id="modal_footer" className="modal-footer" />
          </div>
        </SourceClearModal>
      )}
    </div>
  );
};

export default PolicyDetailModal;
