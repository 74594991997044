import React from 'react';
import classnames from 'classnames';

export enum BadgeType {
  Regular,
  VeryHigh,
  High,
  Medium,
  Low,
  VeryLow,
  Informational,
  Gray,
  Black,
}

export enum BadgeWidthType {
  Regular,
  Wide,
  Narrow,
  Expand,
  Fill,
}

export enum BadgeHeightType {
  Regular,
  Tall,
  Short,
  Expand,
  Fill,
}

interface VCBadgeProps {
  message: string;
  badgeType?: BadgeType;
  badgeWidthType?: BadgeWidthType;
  badgeHeightType?: BadgeHeightType;
  notification?: boolean;
}

const VCBadge: React.FunctionComponent<VCBadgeProps> = props => {
  const {
    notification = false,
    badgeType = BadgeType.Regular,
    badgeWidthType = BadgeWidthType.Regular,
    badgeHeightType = BadgeHeightType.Regular,
    message,
  } = props;

  const vcBadgeTypeClass = classnames({
    'very-high': badgeType === BadgeType.VeryHigh,
    high: badgeType === BadgeType.High,
    medium: badgeType === BadgeType.Medium,
    low: badgeType === BadgeType.Low,
    'very-low': badgeType === BadgeType.VeryLow,
    informational: badgeType === BadgeType.Informational,
    gray: badgeType === BadgeType.Gray,
    black: badgeType === BadgeType.Black,
    regular: badgeType === BadgeType.Regular,
  });

  const vcBadgeWidthClass = classnames({
    'width-wide': badgeWidthType === BadgeWidthType.Wide,
    'width-narrow': badgeWidthType === BadgeWidthType.Narrow,
    'width-expand': badgeWidthType === BadgeWidthType.Expand,
    'width-fill': badgeWidthType === BadgeWidthType.Fill,
    'width-regular': badgeWidthType === BadgeWidthType.Regular,
  });

  const vcBadgeHeightClass = classnames({
    'height-tall': badgeHeightType === BadgeHeightType.Tall,
    'height-short': badgeHeightType === BadgeHeightType.Short,
    'height-expand': badgeHeightType === BadgeHeightType.Expand,
    'height-fill': badgeHeightType === BadgeHeightType.Fill,
    'height-regular': badgeHeightType === BadgeHeightType.Regular,
  });

  return (
    <div className="vcBadgeContainer">
      <div
        className={`vc-badge ${vcBadgeTypeClass} ${vcBadgeWidthClass} ${vcBadgeHeightClass} ${
          notification ? 'notification' : ''
        }`}
      >
        {message}
      </div>
    </div>
  );
};

export default VCBadge;
