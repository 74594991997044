import React from 'react';

import { FindingRules } from '~/types/UnifiedPolicy';
import {
  CategoryRow,
  findingRuleTypeMap,
  findingScanTypeMap,
  PolicyCategoryEnum,
  PolicyFindingRule,
  RuleTypeEnum,
  ScanTypeEnum,
  SecurityEnum,
} from '~/components/UnifiedPolicyDetailsPage/PolicyDetailsConstants';
import VCReadMore from '~/views/components/veracode/VCReadMore';
import Tooltip from '~/components/Tooltip';

const ruleTypeField = 'type_text';
const requirementField = 'requirement_field';
const scanTypeField = 'scan_type_text';
const severityField = 'severity_field';
const show2021 = true; //Read feature FEATURE_OWASP_PCI_RULE_CHANGE_2021

interface RuleListProps {
  findingRules: FindingRules[];
  category: string;
  cweCategories: CategoryRow[];
}

const RuleList: React.FunctionComponent<RuleListProps> = props => {
  const { findingRules, category, cweCategories } = props;

  //Getting unique Rules
  function uniqueRules(finding_rules): PolicyFindingRule[] {
    const map = new Map<string, PolicyFindingRule>();
    const severity: RuleTypeEnum = RuleTypeEnum.Severity;
    // Max severity is a weird duck
    const rules = finding_rules.filter(rule => rule.type !== severity);
    const scanRows = finding_rules.filter(rule => rule.type === severity);

    rules.map(rule => {
      const r = map.get(rule.type);
      if (r) {
        r.requirement.push(rule.value);
        r.scan_type.push(...rule.scan_type);
      } else {
        map.set(rule.type, JSON.parse(JSON.stringify(rule))); // clone
        map.get(rule.type).requirement = [rule.value];
      }
    });
    const result: PolicyFindingRule[] = Array.from(map.values());
    result.push(
      ...scanRows.map(r => {
        r.requirement = [r.value];
        return r;
      })
    );
    result.map(r => {
      r.scan_type = r.scan_type as ScanTypeEnum[];
    });
    return result;
  }

  function processRule(allRules: FindingRules[], policyCategory: string) {
    let rules = uniqueRules(allRules);
    rules.map(rule => {
      const frt = findingRuleTypeMap.get(rule.type);
      const scanTypes: string[] = [];
      rule.scan_type.map(scan => {
        const fst = findingScanTypeMap.get(scan);
        if (fst) {
          scanTypes.push(fst.label);
        } else {
          scanTypes.push(scan);
        }
      });

      // Shouldn't fail
      if (frt) {
        const reqs: string[] = [];
        const { options } = frt;

        rule[ruleTypeField] = frt.label;

        if (rule.requirement) {
          rule.requirement.map(req => {
            let reqText: string;

            if (options) {
              let option = options.find(o => o.value.toString() === req);
              // Hack for BLZ-1483
              if (!show2021 && req === SecurityEnum.Owasp) {
                option = options.find(o => o.value === SecurityEnum.Owasp17);
              }
              reqText = option ? option.label : '';
            } else {
              switch (rule.type) {
                case RuleTypeEnum.Blacklist:
                  reqText =
                    'Prevent an application from passing policy if blocklisted components are detected.';
                  break;
                case RuleTypeEnum.Category: {
                  const category = cweCategories.find(r => r.id === +req);
                  if (category) {
                    reqText = category.name;
                  }
                  break;
                }
              }
            }
            reqs.push(reqText || req);
          });
        }
        if (reqs.length > 1) {
          if (reqs.every(r => Number.isInteger(+r))) {
            reqs.sort((a, b) => +a - +b);
          } else {
            reqs.sort((a, b) => a.localeCompare(b));
          }
        }
        // scanType is blank in the Requirement column, Scan Type will show values
        rule[requirementField] = rule.type === RuleTypeEnum.ScanType ? '' : reqs.join(', ');

        switch (rule.type) {
          case RuleTypeEnum.LicenseRisk:
            rule[requirementField] += ` not allowed`;
            break;
          case RuleTypeEnum.Severity:
            rule[requirementField] += ` not allowed`;
            break;
          case RuleTypeEnum.Cvss:
            rule[requirementField] += ` and above not allowed`;
            break;
          case RuleTypeEnum.ScanType:
            rule[requirementField] += 'Findings through ' + scanTypes.join(', ') + ' not allowed';
            break;
        }
      } else {
        rule[ruleTypeField] = rule.type;
        rule[requirementField] = rule.requirement.join(', ');
      }
      if (policyCategory === PolicyCategoryEnum.Component) {
        rule[scanTypeField] = 'Veracode for Nexus';
      } else {
        rule[scanTypeField] = scanTypes.join(', ');
      }

      rule[scanTypeField] = scanTypes.join(', ');
    });

    const sevs: PolicyFindingRule[] = rules.filter(r => r.type === RuleTypeEnum.Severity);

    if (sevs.length > 0) {
      const sev0: PolicyFindingRule = sevs[0];
      const fields: string[] = (sev0[severityField] = []);

      sevs.forEach(sev => {
        if (!sev.scan_type.includes(ScanTypeEnum.Sca)) {
          sev[scanTypeField] = 'Dynamic, Manual, Static';
        }
        fields.push(sev[scanTypeField], sev[requirementField]);
      });

      if (sevs.length > 1) {
        rules = rules.filter(r => r.type !== RuleTypeEnum.Severity);
        rules.push(sev0);
      }
    }
    rules.sort((a, b) => a[ruleTypeField].localeCompare(b[ruleTypeField]));
    return rules;
  }

  const rules = processRule(findingRules, category);

  return (
    <div id="ruleGroup" className="policyGroup">
      <div className="policyTitleRow">
        <div id="rulesTitle" className="mtitle" data-automation-id="PolicyDetails-rulesTitle">
          Rules
        </div>
      </div>
      <div id="ruleContent" className="policyGroupContent">
        <table>
          <tbody>
            {rules.map(rule => {
              return (
                <tr key={rule.type} id={rule.type}>
                  <td className={'inline-block'}>
                    <span>{rule.type_text}: </span>
                    {rule.type === RuleTypeEnum.Severity && (
                      <span>
                        {rule.severity_field.length > 2 && (
                          <div className="policyDetailSeverity">
                            <span>{rule.severity_field[2]}:&nbsp;</span>
                            <span className="text--bold">{rule.severity_field[3]}</span>
                          </div>
                        )}
                        <div className="policyDetailSeverity">
                          <span>{rule.severity_field[0]}:&nbsp;</span>
                          <span className="text--bold">{rule.severity_field[1]}</span>
                        </div>
                      </span>
                    )}
                    {rule.type !== RuleTypeEnum.Severity && (
                      <span className=" text--bold">
                        {rule.type === RuleTypeEnum.Blacklist ? (
                          <span className={'inline-block'}>
                            <Tooltip
                              place={'top'}
                              maxWidthClass="max-width--300"
                              content={
                                <span>
                                  Add or remove components from the blocklist by reviewing the
                                  third-party components found during Software Composition Analysis.
                                </span>
                              }
                              id={`blocklist`}
                            >
                              Blocklisted components not allowed{' '}
                              <span className="fa fa-question-circle color--primary"></span>
                            </Tooltip>
                          </span>
                        ) : (
                          <VCReadMore
                            content={rule.requirement_field}
                            limit={50}
                            isEmbedded={true}
                          />
                        )}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RuleList;
