import React, { useState } from 'react';
import Select from 'react-select';
import Tooltip from '~/components/Tooltip';
import { useGetPolicyList } from '~/hooks/policyList';
import { useGetWorkspacePolicyDetails } from '~/hooks/workspacePolicyDetails';
import PolicyDetailModal from '~/containers/PolicyDetailModal';
import { useSaveWorkspacePolicyDetails } from '~/hooks/workspacePolicySave';
import {SCA_RULES_TYPE} from "~/constants/ModelConstants";

interface SelectPolicyProps {
  teamId: string;
}

const SelectUnifiedPolicy: React.FunctionComponent<SelectPolicyProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  // Redux hooks
  const teamId = props.teamId;
  const selectablePolicy = [];

  const [selectionChanged, setSelectionChanged] = useState(false);

  let isDefaultPolicyRequired = false;
  let selectedPolicyOption = null;

  //React-query
  const { status: policyListStatus, data: policyList } = useGetPolicyList();
  const {
    status: workspacePolicyStatus,
    data: workspacePolicyDetail,
  } = useGetWorkspacePolicyDetails(teamId);
  const { mutate: savePolicyMutate, status: savePolicyStatus } = useSaveWorkspacePolicyDetails(
    teamId
  );

  if (policyListStatus === 'success') {
    const unifiedPolicies = policyList._embedded ? policyList._embedded.policy_versions : undefined;

    if (unifiedPolicies && unifiedPolicies.length > 0) {
      //Converting json object to get passed to the Select Object
      unifiedPolicies.map(policy => {
        const { finding_rules } = policy;
        // Check if the Policy has SCA Rules
        if (finding_rules) {
          const scaEnabledRules = finding_rules.filter(rule => SCA_RULES_TYPE.includes(rule.type));
          if (scaEnabledRules.length > 0) {
            selectablePolicy.push({
              label: policy.name,
              value: policy.guid,
            });
          }
        }
      });

      if (workspacePolicyDetail && workspacePolicyStatus === 'success') {
        const { defaultPolicy, selectedPolicy } = workspacePolicyDetail;
        const defaultPolicyGuid = defaultPolicy ? defaultPolicy.policyGuid : undefined;
        isDefaultPolicyRequired = defaultPolicy ? defaultPolicy.required : false;

        // Set the correct Policy to be selected
        selectedPolicyOption =
          isDefaultPolicyRequired || !selectedPolicy
            ? selectablePolicy.find(p => p.value === defaultPolicyGuid)
            : selectablePolicy.find(p => p.value === selectedPolicy);

        // This should not happen but in extreme cases, may the policy was delete
        if (!selectedPolicyOption) {
          isDefaultPolicyRequired = isDefaultPolicyRequired && selectedPolicyOption;
          //Try to see if the selectedPolicy still has value if default is not valid
          selectedPolicyOption = selectablePolicy.find(p => p.value === selectedPolicy);
        }
      }
    }
  }
  const [newSelectedPolicyOption, setNewSelectedPolicyOption] = useState(selectedPolicyOption);

  function onChange(policy) {
    setSelectionChanged(!selectedPolicyOption || policy.value !== selectedPolicyOption.value);
    setNewSelectedPolicyOption(policy);
  }

  function saveChanges() {
    const saveData = { policyGuid: newSelectedPolicyOption.value };
    savePolicyMutate(saveData);
  }

  return (
    <div>
      {policyListStatus === 'loading' && (
        <div className="flex justify-center text-xl pt-4 loading">Loading...</div>
      )}
      {savePolicyStatus === 'loading' && (
        <div className="flex justify-center text-xl pt-4 loading">Saving...</div>
      )}

      {(policyListStatus === 'success' || policyListStatus === 'error') &&
        selectablePolicy.length < 1 && (
          <div className="grid">
            <div
              className="grid__item col-1-1 flex align-items--center"
              data-automation-id="AddSelectedPolicyToWorkspace-policyUnavailable"
            >
              <div className="col-1-1 max-width--500">
                <p className="font--h5">
                  Policies are currently unavailable.
                  <br />
                  Please try again later.
                </p>
              </div>
            </div>
          </div>
        )}
      {policyListStatus === 'success' && selectablePolicy.length > 0 && (
        <div className="grid">
          <div
            className="grid__item col-1-1 flex align-items--center"
            data-automation-id="AddSelectedPolicyToWorkspace-policySelect"
          >
            <div className="col-1-1 max-width--500">
              <Tooltip
                active={isDefaultPolicyRequired}
                place={'top'}
                maxWidthClass="max-width--300"
                content={
                  'This workspace is required to use the default workspace policy.' +
                  'To change this policy, contact a Security Lead to request an exemption.'
                }
              >
                <Select
                  options={selectablePolicy}
                  name="policies"
                  className={'srcclr-react-select-container'}
                  classNamePrefix={'srcclr-react-select'}
                  noOptionsMessage={() => 'No policy found'}
                  isDisabled={isDefaultPolicyRequired}
                  defaultValue={selectedPolicyOption}
                  onChange={policy => onChange(policy)}
                />
              </Tooltip>
            </div>
            <div className="width--200 pl-">
              <button
                className="p- btn--success unifiedPolicySaveBtn"
                onClick={saveChanges}
                data-automation-id="savePolicy-button"
                disabled={isDefaultPolicyRequired || !selectionChanged}
              >
                Save
              </button>
            </div>
          </div>
          <div className="grid__item col-1-1 flex ">
            <div className="col-1-1 max-width--500 pt- text--right">
              <a
                className="ml link--obvious"
                onClick={() => toggleModal()}
                data-automation-id="viewPolicyDetails-Link"
              >
                <strong>View policy details</strong>
              </a>
              {isOpen ? (
                <PolicyDetailModal
                  isOpen={isOpen}
                  toggle={() => toggleModal()}
                  // initial value is not set properly.
                  policyId={
                    newSelectedPolicyOption
                      ? newSelectedPolicyOption.value
                      : selectedPolicyOption.value
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectUnifiedPolicy;
