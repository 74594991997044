import React from 'react';

import { PolicyDetail } from '~/types/UnifiedPolicy';
import {
  CweRow,
  severitiesDisplay,
  SeverityEnum,
} from '~/components/UnifiedPolicyDetailsPage/PolicyDetailsConstants';
import VCBadge, { BadgeType, BadgeWidthType } from '~/views/components/veracode/VCBadge';
import Tooltip from '~/components/Tooltip';

interface CustomSeverityProps {
  policyDetails: PolicyDetail;
  cweSeverity: CweRow[];
}

const CustomSeverity: React.FunctionComponent<CustomSeverityProps> = props => {
  const { cweSeverity, policyDetails } = props;
  const { custom_severities } = policyDetails;

  function findSeverity(id: number): CweRow {
    return cweSeverity.find(o => o.id === id);
  }

  function findSeverityDisplay(id: number): string {
    return severitiesDisplay.find(o => o.value === id).label;
  }

  function getUrl(cweRow: CweRow) {
    if (cweRow.references) {
      const find = cweRow.references.find(ref => ref.url && ref.url.indexOf('http') > -1);
      if (find) {
        return find.url;
      }
    }
  }

  function severityBadgeType(severity: number): BadgeType {
    let result = BadgeType.Informational;

    switch (severity) {
      case SeverityEnum.VERY_HIGH:
        result = BadgeType.VeryHigh;
        break;
      case SeverityEnum.HIGH:
        result = BadgeType.High;
        break;
      case SeverityEnum.MED:
        result = BadgeType.Medium;
        break;
      case SeverityEnum.LOW:
        result = BadgeType.Low;
        break;
      case SeverityEnum.VERY_LOW:
        result = BadgeType.VeryLow;
        break;
      case SeverityEnum.INFORMATIONAL:
        result = BadgeType.Informational;
        break;
    }

    return result;
  }

  return (
    <span>
      {custom_severities && custom_severities.length > 0 && (
        <div id="policyCustomSeverityGroup" className="policyGroup">
          <div className="policyTitleRow">
            <div className="mtitle" data-automation-id="PolicyDetails-customSeveritiesTitle">
              Custom Severities for CWEs
            </div>
          </div>
          <div className="policyGroupContent">
            <table id="customSeverities" className="policy-custom-severity vc-table">
              <tbody>
                {custom_severities.map(cs => {
                  const cwe = findSeverity(cs.cwe);
                  const referenceURL = getUrl(cwe);
                  return (
                    <tr key={cs.cwe} className="policy-grace-period">
                      <td>
                        {referenceURL && (
                          <a
                            type="link"
                            className="link--obvious"
                            href={referenceURL}
                            target="_blank"
                          >
                            <span> CWE {cs.cwe}</span>
                            <i className="fas fa-external-link pl-- " />
                          </a>
                        )}
                        {!cwe.references && <span> CEW {cs.cwe} </span>}
                      </td>
                      <td>
                        <Tooltip
                          place={'top'}
                          content={
                            <div>
                              <div className="mb--">{cwe.name}</div>
                            </div>
                          }
                          id={`${cs.cwe}-tooltip`}
                        >
                          <div className={'policyCustomSeverityText'}>{cwe.name} </div>
                        </Tooltip>
                      </td>
                      <td>
                        <VCBadge
                          message={findSeverityDisplay(cs.severity)}
                          badgeType={severityBadgeType(cs.severity)}
                          badgeWidthType={BadgeWidthType.Wide}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </span>
  );
};

export default CustomSeverity;
