import { useQuery } from 'react-query';
import config from '~/config';
import ApiService from '~/utils/ApiService';
import { veracodePoliciesService } from '~/constants/ModelConstants';

export function useGetPolicyDetails(policyId: string) {
  return useQuery('policyDetails', () =>
    (ApiService as any).get(
      `${config.VERACODE_UIGATEWAY_HOST}${veracodePoliciesService}/${policyId}`
    )
  );
}
