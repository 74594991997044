import React, { Component } from 'react';

import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import Helpers from '~/utils/Helpers';
import Tooltip from '~/components/Tooltip';
import ReportRepoBranchTagDisplay from '~/components/ReportComponents/ReportRepoBranchTagDisplay';
import IssueHelper from '~/utils/IssueHelper';
import PlatformUrlHelper from '~/utils/PlatformUrlHelper';

import { PROJECT_TYPES_CLEAN_NAMES, ISSUE_EVENT_AFFECTED_SOURCE } from '~/constants/ModelConstants';

import { Issue } from '~/components/ReportComponents/ReportIssue.types';

interface IssueProjectDetailsProps extends RouteComponentProps {
  issue: Issue;
  teamId: string;
  isPaidOrTrialing?: boolean;
  isContainerProject?: boolean;
}

class IssueProjectDetails extends Component<IssueProjectDetailsProps> {
  render() {
    const { teamId, issue, isPaidOrTrialing, isContainerProject } = this.props;

    const { id, repoName, repoId, projectType = '', branch, tag, _links } = issue;

    const {
      status,
      scanId,
      scanDate,
      scanTag,
      openedScanId,
      openedScanDate,
      openedScanTag,
      fixedScanId,
      fixedScanDate,
      fixedScanTag,
    } = IssueHelper.getScanData(issue);

    //Scan-related hrefs/id/dates
    const scanHref = `/workspaces/${teamId}/scans/${scanId}`;
    const openedScanHref = `/workspaces/${teamId}/scans/${openedScanId}`;
    const fixedScanHref = `/workspaces/${teamId}/scans/${fixedScanId}`;

    const scanDateText = Helpers.formatTimestamp(scanDate) || '';
    const openedScanDateText = Helpers.formatTimestamp(openedScanDate) || '';
    const fixedScanDateText = Helpers.formatTimestamp(fixedScanDate) || '';
    //End Scan-related

    const scmHref = (_links && _links.scm && _links.scm.href) || '';

    const repoNameText = Helpers.formatProjectName(repoName);
    /* eslint-disable react/no-danger */
    const projNameTooltipContent = Helpers.isProjectNameCut(repoNameText) ? (
      <div dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoName)} />
    ) : (
      ''
    );

    const projectLink = PlatformUrlHelper.createProjectUrl(teamId, repoId);

    const repoNameHtml = (
      <Link
        to={projectLink}
        className="link--obvious"
        dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoNameText)}
      />
    );
    /* eslint-enable react/no-danger */

    // Check if its passive event
    const { createdEvent = { date: '', data: {} } as Issue['createdEvent'], resolvedEvent } = issue;

    const { date: passiveEventCreatedDate = '', data = {} } = createdEvent;

    let passiveEventResolvedDate = '';
    if (resolvedEvent) {
      const { date } = resolvedEvent;
      passiveEventResolvedDate = date;
    }

    const isPassivelyCreated =
      passiveEventCreatedDate !== '' && IssueHelper.isPassivelyCreatedEvent(createdEvent);
    const isPassivelyResolved =
      passiveEventResolvedDate !== '' && IssueHelper.isPassivelyResolvedEvent(resolvedEvent);

    const passiveEventCreatedDateText = Helpers.formatTimestamp(passiveEventCreatedDate) || '';

    const showPassiveCreatedDataAsLastSeen =
      isPassivelyCreated && moment(passiveEventCreatedDate).isAfter(scanDate);

    const { affectedSource: passiveEventAffectedSource = '' } = data;

    const vulnerabilityDiscoveredText = `n/a - vulnerability discovered`;
    const vulnerabilityUpdatedText = 'n/a - vulnerability updated';
    const vulnDiscoveredOrUpdatedText =
      passiveEventAffectedSource === ISSUE_EVENT_AFFECTED_SOURCE.VULN_RELEASED
        ? vulnerabilityDiscoveredText
        : vulnerabilityUpdatedText;

    const isFixed = status === 'FIXED' && fixedScanId && fixedScanDate;

    return (
      <div>
        <div className="grid grid--narrow">
          <div className="grid__item col-1-1">
            <div className="font--18 bo--b-1 border-color--muted-light mb-">PROJECT DETAILS</div>
          </div>
          <div className="grid__item col-1-1 flex flex--flex-direction--row">
            <div className="col-1-2">
              <div className="flex flex--flex-direction--row">
                <span className="text--bold mr--">Type:</span>
                <span>{PROJECT_TYPES_CLEAN_NAMES[projectType.toUpperCase()]}</span>
              </div>
              <div
                className="flex flex--flex-direction--row"
                data-automation-id="IssueProjectDetails-ProjectName"
              >
                <span className="text--bold mr--">Project:</span>
                {projNameTooltipContent ? (
                  <Tooltip content={projNameTooltipContent} id={`${id}-${scanId}-tooltip`}>
                    {repoNameHtml}
                  </Tooltip>
                ) : (
                  repoNameHtml
                )}
              </div>
              {!isContainerProject && (
                <div className="pt---">
                  <span className="text--bold">
                    {!isContainerProject && branch && <span> Branch: </span>}
                    {tag && <span> Tag: </span>}
                    {!branch && !tag && <span> {isContainerProject ? 'Hash' : 'Commit'}: </span>}
                  </span>
                  <ReportRepoBranchTagDisplay
                    branch={branch}
                    tag={tag}
                    scmHref={scmHref}
                    className="inline-block"
                  />
                </div>
              )}
            </div>
            <div>
              <div>
                <span className="text--bold">Scan ID Found: </span>
                {!isPassivelyCreated && isPaidOrTrialing && (
                  <Link to={openedScanHref} className="link--obvious">
                    {openedScanId}
                  </Link>
                )}
                {!isPassivelyCreated && !isPaidOrTrialing && <span>{openedScanId}</span>}
                {isPassivelyCreated && <span>{vulnDiscoveredOrUpdatedText}</span>}
              </div>
              <div className="pt---">
                <span className="text--bold">Date Found:</span>
                {!isPassivelyCreated && <span> {openedScanDateText}</span>}
                {isPassivelyCreated && (
                  <span> {Helpers.formatTimestamp(passiveEventCreatedDate)}</span>
                )}
              </div>
              {isContainerProject && openedScanTag && (
                <div className="pt---">
                  <span className="text--bold">Tag Found:</span>
                  <span> {openedScanTag}</span>
                </div>
              )}
            </div>
          </div>

          <div className="grid__item col-1-1 flex flex--flex-direction--row">
            <div className="col-1-2">
              <div className="pt-">
                <span className="text--bold">Scan ID Last Seen: </span>
                {!showPassiveCreatedDataAsLastSeen && isPaidOrTrialing && (
                  <Link to={scanHref} className="link--obvious">
                    {scanId}
                  </Link>
                )}
                {!showPassiveCreatedDataAsLastSeen && !isPaidOrTrialing && <span>{scanId}</span>}
                {showPassiveCreatedDataAsLastSeen && <span>{vulnDiscoveredOrUpdatedText}</span>}
              </div>
              <div className="pt---">
                <span className="text--bold">Date Last Seen:</span>{' '}
                {showPassiveCreatedDataAsLastSeen ? passiveEventCreatedDateText : scanDateText}
              </div>
              {isContainerProject && scanTag && (
                <div className="pt---">
                  <span className="text--bold">Tag Last Seen:</span>
                  <span> {scanTag}</span>
                </div>
              )}
            </div>
            {isFixed && (
              <div>
                <div className="pt-">
                  <span className="text--bold"> Scan ID Resolved:</span>
                  {!isPassivelyResolved && (
                    <Link to={fixedScanHref} className="link--obvious">
                      {fixedScanId}
                    </Link>
                  )}
                  {isPassivelyResolved && <span> {vulnerabilityUpdatedText}</span>}
                </div>
                <div className="pt---">
                  <span className="text--bold">Date Resolved:</span>
                  {!isPassivelyResolved && (
                    <span> {Helpers.formatTimestamp(fixedScanDateText)}</span>
                  )}
                  {isPassivelyResolved && (
                    <span> {Helpers.formatTimestamp(passiveEventResolvedDate)}</span>
                  )}
                </div>
                {isContainerProject && fixedScanTag && (
                  <div className="pt---">
                    <span className="text--bold">Tag Resolved:</span>
                    <span> {fixedScanTag}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IssueProjectDetails);
