import ApiService from '~/utils/ApiService';
import ApiConstants from '~/constants/ApiConstants';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';

export const UPDATE_LICENSES = 'UPPDATE_LICENSES';
export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_REQUEST_ERROR = 'FETCH_LICENSES_REQUEST_ERROR';

export const UPDATE_LICENSES_BY_RISK = 'UPDATE_LICENSES_BY_RISK';
export const FETCH_LICENSES_BY_RISK = 'FETCH_LICENSES_BY_RISK';
export const FETCH_LICENSES_BY_RISK_ERROR = 'FETCH_LICENSES_BY_RISK_ERROR';

/*
 It seems that this action does not need reducer as we fetch and use localstorage.
 Example:
   licenseAction.getLicense('APACHE20').then((license) => { });
   licenseAction.getLicenses(['APACHE20']).then((licenses) => { });
*/

export const fetchLicensesRequest = () => ({
  type: FETCH_LICENSES_REQUEST,
});

export const fetchLicenseFailure = error => ({
  type: FETCH_LICENSES_REQUEST_ERROR,
  message: error.message,
});

export const updatelicenses = licenses => ({
  type: UPDATE_LICENSES,
  licenses,
});

export const fetchLicenses = () => dispatch => {
  dispatch(fetchLicensesRequest());

  return ApiService.get(ApiConstants.licenseURL)
    .then((res = {}) => {
      const { licenses } = res._embedded != undefined ? res._embedded : [];
      const licenseList = licenses.map(license => {
        return {
          id: license.id,
          name: license.name,
          spdxId: license.spdxId,
        };
      });
      dispatch(updatelicenses(licenseList));
    })
    .catch(error => {
      dispatch(fetchLicenseFailure(error));
      ErrorService.capture('Error fetching licenses', error);
    });
};

export const fetchLicensesByRisk = riskType => dispatch => {
  dispatch(fetchingLicensesByRisk());

  const risk = Helpers.getRiskByKind(riskType);
  return ApiService.get(`${ApiConstants.licenseURL}?risk=${risk}`)
    .then((res = {}) => {
      const { licenses } = res._embedded != undefined ? res._embedded : [];
      const licenseList = licenses.map(license => {
        return {
          id: license.id,
          name: license.name,
          spdxId: license.spdxId,
        };
      });
      dispatch(updateLicensesByRisk(licenseList));
    })
    .catch(error => {
      dispatch(fetchLicenseByRiskFailure(error));
      ErrorService.capture('Error fetching licenses by risk', error);
    });
};

export const fetchingLicensesByRisk = () => ({
  type: FETCH_LICENSES_BY_RISK,
});

export const updateLicensesByRisk = licensesByRisk => ({
  type: UPDATE_LICENSES_BY_RISK,
  licensesByRisk,
});

export const fetchLicenseByRiskFailure = error => ({
  type: FETCH_LICENSES_BY_RISK_ERROR,
  message: error.message,
});

export const getLicenses = licenses => {
  let promises = licenses.map(license => getLicense(license));
  return Promise.all(promises);
};

export const getLicense = license => {
  const key = 'license-' + license;
  const response = {};

  if (localStorage.getItem(key) !== null) {
    response[license] = localStorage.getItem(key);
    return response;
  } else if (!license) {
    response[license] = 'unknown license';
    return response;
  } else {
    return loadLicense(license).then(res => {
      localStorage.setItem(key, res.fullText);
      response[license] = res.fullText;
      return response;
    });
  }
};

export const loadLicense = license => {
  return ApiService.get('/licenses/' + license).then(res => res);
};
