import React from 'react';
import VulnMethodItem from '~/components/ReportPage/QuickviewVulnMethod/VulnMethodItem';

interface ReportDetailsPageVulnMethodViewProps {
  data?: object;
  expandedVulnerableMethodChain: string;
  toggleFullCallChain: (...args: any[]) => any;
}

const ReportDetailsPageVulnMethodView: React.FunctionComponent<
  ReportDetailsPageVulnMethodViewProps
> = props => {
  const { data, expandedVulnerableMethodChain, toggleFullCallChain } = props;
  let content;
  if (data) {
    content = (
      <div className="grid" key={`vuln-method-0`}>
        <div className="grid__item col-1-1">
          <div className="font--16">{data.component}</div>
          <div className="pv--">
            <VulnMethodItem
              data={data}
              expandedVulnerableMethodChain={expandedVulnerableMethodChain}
              toggleFullCallChain={toggleFullCallChain}
            />
          </div>
        </div>
      </div>
    );
  } else {
    content = <div>No vulnerable methods have been detected for this vulnerability.</div>;
  }

  return (
    <div className="grid pl">
      <div className="grid__item col-3-5 bg-color--white-light pv- bo--1 border-color--muted-light flex flex--align-items--center">
        <i className="sci sci__detail-information color--primary pr- font--h6" />
        <div>
          Vulnerable Method detection identifies how your project is impacted by a vulnerability,
          down to the line of code.
        </div>
      </div>
      <div className="mt- mb- grid__item col-1-1">{content}</div>
    </div>
  );
};

export default ReportDetailsPageVulnMethodView;
